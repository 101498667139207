import {INIT, FETCH_PROJECT_DATA, SAVE_BACK_DATA, START_GUIDE, OPEN_SINGLE_GUIDE} from './actionTypes';
import {GET_PROJECT_DATA} from './services';

export const initAction = (applicationId) => {
  return {
    type: INIT,
    payload: { applicationId: applicationId }
  }
}

export const getDataAction = (url) => {
  return {
    type: FETCH_PROJECT_DATA,
    payload: { url: url }
  }
}

export const startGuideAction = (started) => {
  return {
    type: START_GUIDE,
    started: started
  }

}

export function init(applicationId) {
  return function (dispatch) {
      dispatch(initAction(applicationId))
  }
}

export function getData(appid, projectid, language) {
    if(!language || language === undefined)  {
      language = '';
    }
    var url = GET_PROJECT_DATA + '?clientId=' + appid + '&lessonid=' + projectid + '&language=' + language;
    return getDataAction(url);
}

export const saveBackNavigationUrlAction = (backUrl) => {
  return {
      type: SAVE_BACK_DATA,
      payload: { 
        backUrl: backUrl
      }
   }
}

export const openGuideInNewTabAction = (guideId) => {
  return {
    type: OPEN_SINGLE_GUIDE,
    guideId: guideId
  }
}

export function saveBackNavigationUrl(backUrl) {
      return saveBackNavigationUrlAction(backUrl);
}

export function startGuide(started) {
    return startGuideAction(started);
}

export function openGuideInNewTab(guideId) {
  return openGuideInNewTabAction(guideId);
}