import { POST_PREFIX, ACTION_INPROGRESS, ACTION_SUCCESS, ACTION_FAIL } from 'app/middleware/apiRequestMiddleware';

export const UPLOAD_PHOTO_SEND_PIC = 'UPLOAD_PHOTO_SEND_PIC';

export const PROCESSING_IMAGE = 'PROCESSING_IMAGE'
export const CLEAN_TAKE_PHOTO_STATE = 'CLEAN_TAKE_PHOTO_STATE';
export const POST_UPLOAD_PHOTO = POST_PREFIX + 'UPLOAD_PHOTO';
export const POST_UPLOAD_PHOTO_SEND_PIC = POST_PREFIX + 'SEND_PIC';

export const POST_UPLOAD_PHOTO_INPROGRESS = POST_UPLOAD_PHOTO + ACTION_INPROGRESS;
export const POST_UPLOAD_PHOTO_SUCCESS = POST_UPLOAD_PHOTO + ACTION_SUCCESS;
export const POST_UPLOAD_PHOTO_FAIL = POST_UPLOAD_PHOTO + ACTION_FAIL;
