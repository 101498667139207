import React, { Component } from 'react';
import 'assets/styles/home.scss';
import BannerContentTitle from 'app/components/home/components/bannerContentTitle/BannerContentTitle';

class Banner extends Component {

    render() {

        return (
            (this.props.themeContext.webAppTopBanner === undefined || this.props.themeContext.webAppTopBanner !== 'false') ?
                <div 
                    className={(!this.props.underTopbar) ? 'home-banner' : 'home-banner topbar-padding'}
                    style={{ backgroundImage: `url(${this.props.imageUrl})`}}>
                    <div className='home-banner-content'>
                        <BannerContentTitle
                            topSubtitle={this.props.topSubtitle}
                            title={this.props.title}
                            subtitle={this.props.subtitle} />
                    </div>
                </div> : 
                <div style={{padding: "70px 0"}}></div>
        );
    }
}

export default Banner;