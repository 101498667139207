import { CLOSE_MESSAGE } from './actionTypes';

export const closeMessageAction = () => {
    return {
      type: CLOSE_MESSAGE
    }
}

export function closeMessage() {
    return closeMessageAction();
}