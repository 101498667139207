import { appNameFromUrl, getQueryVariable } from 'app/utils/utils';

export const NAVIGATE_TO_SECTION = 'NAVIGATE_TO_SECTION';
export const NAVIGATE_TO_GUIDE = 'NAVIGATE_TO_GUIDE';
export const NAVIGATE_TO_MENU = 'NAVIGATE_TO_MENU';
export const NAVIGATE_TO_URL = 'NAVIGATE_TO_URL';
export const OPEN_SINGLE_GUIDE = 'OPEN_SINGLE_GUIDE';

export const navigationMiddleware = store => next => action => {

    switch (action.type) {
        case NAVIGATE_TO_SECTION:
            navigateTo(
                '/' + appNameFromUrl() + '/guides/section/' + action.sectionId, 
                'ref=' + action.topSectionId);
            break;

        case NAVIGATE_TO_GUIDE:
            navigateTo(
                '/' + appNameFromUrl() + '/guides/guide/' + action.guideId, 
                'ref=' + action.ref + '&reftype=' + action.refType + '&sectionref=' + action.sectionRef);
            break;

        case NAVIGATE_TO_MENU:
            navigateTo(
                '/' + appNameFromUrl(), 
                'section=' + action.sectionId);
            break;

        case NAVIGATE_TO_URL:
            window.location = action.url;
            break;

        case OPEN_SINGLE_GUIDE:
            openNewTab(
                '/' + appNameFromUrl() + '/guide/' + action.guideId);
            break;
            
        default:
            break;
    }

    next(action);
};

function navigateTo(url, params) {

    var query = getQuery(params);
    if(query !== '') {
        window.location = url + '?' + query;
    } else {
        window.location = url;
    }
}

function openNewTab(url, params) {

    var query = getQuery(params);
    if(query !== '') {
        window.open(url + '?' + query, '_blank');
    } else {
        window.open(url, '_blank');
    }
}

export function getQuery(params) {

    let finalParams = new URLSearchParams((params) ? params : '');

    // Add language mandatory params
    var languageParam = getQueryVariable('language');
    var languagesParam = getQueryVariable('languages');
    if(languageParam !== '') {
        finalParams.append('language', languageParam);
    }
    if(languagesParam !== '') {
        finalParams.append('languages', languagesParam);
    }

    // Add other supported parameters
    var sourceParam = getQueryVariable('source');
    if(sourceParam !== '') {
        finalParams.append('source', sourceParam);
    }
    let refurl = finalParams.get('refurl');
    if(refurl){
        finalParams.delete('refurl');
    }
    // Remove split guide navigation params
    finalParams.delete('store');
    finalParams.delete('storeid');

    // Return result
    return finalParams.toString();
}