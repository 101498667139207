import React, { Component } from 'react';
import 'assets/styles/guide-topbar.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import { CloseIcon } from 'app/components/svg/CloseIcon';
import { LeftArrowIcon } from 'app/components/svg/LeftArrowIcon';
import { isInIframe } from 'app/utils/utils';

class GuideTopbar extends Component {

    state = {
        isInIframe: isInIframe()
    }

    render() {

        return (
            <div
                className='guide-topbar-container'
                style={{ background: this.props.themeContext.webappHeadaer ? this.props.themeContext.webappHeadaer : this.props.themeContext.defaultBackgroundColor }}>
                {
                    (this.props.goBack) ?
                        <div className='guide-topbar-icon guide-topbar-left-icon' onClick={this.props.goBack}>
                            <LeftArrowIcon color={this.props.themeContext.defaultTextColor} />
                        </div> : null
                }
                {
                    (this.props.close) ?
                        <div className='guide-topbar-icon guide-topbar-right-icon'
                            onClick={this.props.close}>
                            <CloseIcon color={this.props.themeContext.defaultTextColor} />
                        </div> : null
                }
                {
                    ((this.props.themeContext.webAppLogoOnTop === undefined || this.props.themeContext.webAppLogoOnTop !== 'false' && !this.state.isInIframe)) ?
                        <a href={this.props.themeContext.logoUrl} target="_blank" rel="noopener noreferrer">
                            <img className='guide-topbar-logo' src={this.props.themeContext.logo} alt="" />
                        </a> : null
                }
            </div>
        );
    }
}

export default withThemeContext(GuideTopbar);