import { 
  FETCH_APP_DATA_SUCCESS, FETCH_APP_DATA_FAIL, 
  FETCH_APP_DATA_IN_PROGRESS, FETCH_APP_DATA_GUIDE_SUCCESS, FETCH_APP_DATA_GUIDE_FAIL, 
  FETCH_APP_DATA_GUIDE_IN_PROGRESS, SHOW_SECTIONS, GO_BACK 
} from './actionTypes';

export default function (state = {
    app: {}, 
    navigationItems: [], 
    allNavigationItems: [],
    currentNavigationItem: null,
    error: false, 
    loading: true,
    loadingGuides: true,
    topSectionId: '',
    guides: []
  }, action) {

  switch (action.type) {
    
    case FETCH_APP_DATA_IN_PROGRESS:
    case FETCH_APP_DATA_GUIDE_IN_PROGRESS:
      return { ...state, error: false, loading: true, loadingGuides: true };

    case FETCH_APP_DATA_SUCCESS:
      return {
        ...state,
        app: action.payload.application,
        allNavigationItems: action.payload.navigation.navItems,
        navigationItems: action.payload.navigation.navItems.filter(
          (item) => item.parent === action.payload.navigation.topSectionId),
        loading: false,
        error: false,
        topSectionId: action.payload.navigation.topSectionId
      };

    case FETCH_APP_DATA_GUIDE_SUCCESS:
      return {
        ...state,
        guides: action.payload,
        loading: false,
        loadingGuides: false,
        error: false
      };

    case SHOW_SECTIONS:
      return {
        ...state,
        navigationItems: state.allNavigationItems.filter((item) => item.parent === action.topSectionId),
        topSectionId: action.topSectionId,
        currentNavigationItem: state.allNavigationItems.find((item) => item.id === action.topSectionId)
      };

    case FETCH_APP_DATA_FAIL:
    case FETCH_APP_DATA_GUIDE_FAIL:
      return { ...state, error: true, loading: false, loadingGuides: false };

    case GO_BACK:
      let navigationItem = state.allNavigationItems.find((item) => item.id === state.topSectionId);
      let newTopSectionId = '';
      if(navigationItem) {
        newTopSectionId = navigationItem.parent;
      }
      return {
        ...state,
        topSectionId: newTopSectionId,
        navigationItems: state.allNavigationItems.filter((item) => item.parent === newTopSectionId),
        currentNavigationItem: state.allNavigationItems.find((item) => item.id === newTopSectionId)
      };

    default:
      return state;
  }
}