import { Component } from 'react';

class NothingErrorBoundary extends Component {

    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        // Do nothing
    }
  
    render() {
      if (this.state.hasError) {
        // Render nothing
        return null;
      }
      return this.props.children; 
    }
  }
  
  export default NothingErrorBoundary