import config from "configuration";

const hideTopbarLogoByURL = "giftbooster.io";

export function appNameFromUrl() {
  var path = window.location.pathname.split("/");
  if (path.length > 1) {
    return path[1].toLowerCase();
  }
  return "";
}

export function getGalleryItemsSizeQueryVariable() {
  var size = getQueryVariable("size");
  if (size !== "") {
    return size;
  }

  // Return default
  return 300;
}

export function getLanguageQueryVariable() {
  var language = getQueryVariable("language");
  if (language !== "") {
    return language;
  }

  // Return default
  return "eng";
}

export function isFullscreenEnabled() {
  return (
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled
  );
}

export function toggleFullscreen() {
  var isInFullScreen =
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement &&
      document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

  var docElm = document.body;
  if (!isInFullScreen) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

export function strToRealTime(
  time,
  shortHoursKeyword,
  hourKeyword,
  hoursKeyword,
  minutesKeyword
) {
  if (time === undefined || time === "") {
    return "";
  }

  let useHoursKeywords = hourKeyword;
  let minutes = time.split(":")[1];
  let hours = time.split(":")[0];
  let timeToMake = "";
  if (hours > 0) {
    if (hours >= 1) {
      if (minutes > 0) {
        useHoursKeywords = shortHoursKeyword;
      }
      timeToMake = hours + " " + useHoursKeywords + " ";
    }
    if (timeToMake.startsWith("0")) {
      timeToMake = timeToMake.substr(1);
    }
  }
  if (minutes > 0) {
    timeToMake += minutes + " " + minutesKeyword + " ";
  }
  return timeToMake;
}

export function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    return (images[item.replace("./", "")] = r(item));
  });
  return images;
}

export function secondsToTimeStamp(seconds) {
  if (seconds === undefined || seconds === 0) {
    return "00:00";
  }
  var date = new Date(null);
  date.setSeconds(seconds);
  var result = date.toISOString().substr(14, 5);
  return result;
}

export function isInIframe() {
  try {
    let url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;

    return (
      window.self !== window.top &&
      url !== config.hideTopBarLogoByURL &&
      url.indexOf(hideTopbarLogoByURL) !== -1
    );
  } catch (e) {
    return true;
  }
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return "";
}

export function queryVariableExists(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return true;
    }
  }
  return false;
}

export function isLanguageRtl(language) {
  if (language === "he" || language === "heb") {
    return true;
  }
  return false;
}

export function isValidEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function openUrl(url) {
  window.open(url, "_blank");
}

export function isImageFile(fileName) {
  if (!fileName || fileName === "") {
    return false;
  }
  var parts = fileName.toLowerCase().split(".");
  if (parts.length <= 1) {
    return false;
  }
  var extension = parts[parts.length - 1];
  switch (extension) {
    case "tiff":
    case "jpg":
    case "jpeg":
    case "bmp":
    case "gif":
    case "png":
      return true;
    default:
      return false;
  }
}

export function getUnitLabel(item) {
  if (item.unit && item.unit !== 0) {
    let unit = "";
    switch (item.unit) {
      case 1:
        unit = "Item";
        break;
      case 2:
        unit = "m";
        break;
      case 3:
        unit = "cm";
        break;
      case 4:
        unit = "mm";
        break;
      case 5:
        unit = "inch";
        break;
      case 6:
        unit = "kg";
        break;
      case 7:
        unit = "g";
        break;
      case 8:
        unit = "l";
        break;
      default:
        unit = "";
    }
    //return this.props.translate(unit)
    return unit;
  }
  return "";
}

export function getDevice(width) {
  if (width < 481) {
    return "Mobile";
  } else if (width < 768 && width > 481) {
    return "Tablet";
  } else {
    return "";
  }
}
