import React from 'react';

export const NewTabIcon = (props) =>(
<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0H23V23H0z"/>
        <path stroke={props.color} strokeOpacity=".4" strokeWidth="2" d="M8.753 8L5 8 5 18 15 18 15 13"/>
        <path stroke={props.color} strokeLinecap="round" strokeOpacity=".4" strokeWidth="2" d="M12.146 6.082h4.242v4.294m-.144-4.009L10 11.887"/>
    </g>
</svg>
);