import storage from 'local-storage-fallback'
//import logerror from 'app/utils/logger'

export function getFromStorage(key) {
    try {
        var value = storage.getItem(key);
        if(!value) {
            return '';
        }
        return value;
    } catch (ex) {
        //logerror(ex);
        return '';
    }
}

export function saveToStorage(key, value) {
    try {
        storage.setItem(key, value);
    } catch(ex) {
        //logerror(ex);
    }
}

export function removeFromStorage(key) {
    saveToStorage(key, '');
}