import React, { Component } from 'react';
import 'assets/styles/videoPlayer.scss';

class BasicVideoPlayer extends Component {

    componentWillUnmount() {
        if(this.player) {
          this.player.src = "";
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.play !== prevProps.play && this.player) {
          if (this.props.play) {
            this.player.play();
          } else {
            this.player.pause();
          }
        }
      }
      
    render() {

        return (
            <video
                ref={(player) => this.player = player}
                src={this.props.videoUrl}
                style={{ width: "100%", height: '100%' }}
                autoPlay muted loop playsInline controls
            >
            </video>
        );
    }
}

export default BasicVideoPlayer;
