import React, { Component } from 'react';
import 'assets/styles/home.scss';

class BannerContentTitle extends Component {

    render() {

        return (
            <>
                {
                    (this.props.topSubtitle && this.props.topSubtitle !== '') ?
                        <div className='banner-top-subtitle direction-only'>{this.props.topSubtitle}</div> : null
                }
                <div className='banner-title large-title title-font direction-only'>{this.props.title}</div>
                {
                    (this.props.subtitle && this.props.subtitle !== 'banner-subtitle') ?
                        <div>{this.props.subtitle}</div> : null
                }
            </>
        );
    }
}

export default BannerContentTitle;