import { FETCH_GALLERY_DATA_INPROGRESS, FETCH_GALLERY_DATA_SUCCESS, FETCH_GALLERY_DATA_FAIL, POST_GALLERY_EMODJI_DATA_INPROGRESS, POST_GALLERY_EMODJI_DATA_SUCCESS, POST_GALLERY_EMODJI_DATA_FAIL } from './actionTypes';

export default function(state = { loading: true, error: false, items: [] }, action) {

    switch(action.type)
    {
      case POST_GALLERY_EMODJI_DATA_INPROGRESS:
        return { ...state, loading: true, error: false }
      case POST_GALLERY_EMODJI_DATA_SUCCESS:
        let updatedItems = [];
        state.items.forEach(item => {
          if(action.payload.resources.length > 0){
            action.payload.resources.forEach(res => {
              if(item.resourceId === res.id){
                item.likes = res.likes;
                item.hearts = res.hearts;
              }
            })
          }
          updatedItems.push(item)
        })
        return { ...state, 
          loading: false, 
          error: false,
          items: updatedItems 
        }
      case POST_GALLERY_EMODJI_DATA_FAIL:
        return { ...state, loading: false, error: true }

      case FETCH_GALLERY_DATA_INPROGRESS:
        return { ...state, loading: true, error: false }
      case FETCH_GALLERY_DATA_SUCCESS:
        return { ...state, 
          items: (action.payload.projects) ? action.payload.projects.slice(0, 50) : [], 
          loading: false, 
          error: false
        }
      case FETCH_GALLERY_DATA_FAIL:
        return { ...state, loading: false, error: true }
        default:
          return { ...state };
    }
}
