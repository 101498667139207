import { SLIDE, END_SLIDE, STEP_UPDATE_DATA } from 'app/components/main/instructions/actionTypes'
import { OPEN_TEXT_TIP } from 'app/components/instructions/popups/texttip/actionTypes'
import { OPEN_GALLERY_TIP } from 'app/components/instructions/popups/gallerytip/actionTypes'
import { OPEN_FORM_TIP } from 'app/components/instructions/popups/formtip/actionTypes'
import config from "configuration";
import { reportEvent } from 'app/utils/googleAnalytics';
export const POST_SLIDER_VIEW = 'POST_SLIDER_VIEW';

let reportedView = false;
let reportedComplete = false;
var timer = 0;

export const slideChangeMiddleware = store => next => action => {

  if (!reportedView && action.type && action.type === SLIDE) {
    reportedView = true;

    // Report to google analytics
    reportEvent('guideBehaviour', 'session', 'started');

    // Report to platform's analytics
    let url = config.apiBaseUrl + 'webproject/Report'
    next({
      type: POST_SLIDER_VIEW,
      payload: {
        url: url,
        postType: 'json',
        data: {
          clientId: action.appId,
          projectId: action.projectId,
          action: 1 // Started
        }
      }
    }
    )
  }

  if (!reportedComplete && action.type && action.type === END_SLIDE) {
    reportedComplete = true;
    let url = config.apiBaseUrl + 'webproject/Report'
    next({
      type: POST_SLIDER_VIEW,
      payload: {
        url: url,
        postType: 'json',
        data: {
          clientId: action.appId,
          projectId: action.projectId,
          action: 2 // Completed
        }
      }
    }
    )
  }

  // Check if we have step tips (text or gallery) that needs to be opened automatically 
  if (action.type === STEP_UPDATE_DATA) {
    clearInterval(timer);
    let projectStore = store.getState().guide;
    var filtered = projectStore.data.lessonSteps.filter(p => { return p.stepId === action.stepId });
    if (filtered.length > 0) {
      var currentStep = filtered[0];
      if (currentStep.tips && currentStep.tips.length > 0) {
        var filteredTips = currentStep.tips.filter(tip => { return tip.isOpened === true });
        var filteredTipsAutoSec = currentStep.tips.filter(tip => { return tip.openAutoSec > 0 });
        var openForm = currentStep.extraData ? currentStep.extraData.extraDataTipForm : null;
        var filteredTipsToOpen = [];
        if(filteredTips.length > 0){
          filteredTipsToOpen = filteredTips
        }else if(filteredTipsAutoSec.length > 0){
          filteredTipsToOpen = filteredTipsAutoSec
        }
        if (filteredTipsToOpen.length > 0) {
          var firstOpenedTip = filteredTipsToOpen[0];
          // Check the type and open the text / gallery tip
          if (firstOpenedTip.type === 0 && firstOpenedTip.data) {
            timer = setTimeout(() =>
              store.dispatch({
                type: OPEN_TEXT_TIP,
                text: firstOpenedTip.data.text,
                tipId: 0
              }), firstOpenedTip.openAutoSec ? firstOpenedTip.openAutoSec * 1000 : 1000);
          }
          else if (firstOpenedTip.type === 1 || firstOpenedTip.type === 5) {
            timer = setTimeout(() =>
              store.dispatch({
                type: OPEN_GALLERY_TIP,
                payload: firstOpenedTip,
                tipId: 0
              }), firstOpenedTip.openAutoSec ? firstOpenedTip.openAutoSec * 1000 : 1000);
          }
          else if (firstOpenedTip.type === 7 && openForm) {
            timer = setTimeout(() =>
              store.dispatch({
                type: OPEN_FORM_TIP,
                item: openForm,
                tipId: 0
              }), firstOpenedTip.openAutoSec ? firstOpenedTip.openAutoSec * 1000 : 1000);
          }
        }
      }
      if (currentStep.contentTips && currentStep.contentTips.length > 0) {
        var contentFilteredTipsToOpen = currentStep.contentTips.filter(p => { return p.isOpened === true });
        if (contentFilteredTipsToOpen.length > 0) {
          var contentFirstOpenedTip = contentFilteredTipsToOpen[0];

          // Check the type and open the text / gallery tip
          if (contentFirstOpenedTip.type === 0) {
            setTimeout(() =>
              store.dispatch({
                type: OPEN_TEXT_TIP,
                text: contentFirstOpenedTip.data.text,
                tipId: 0
              }), 1000);
          }
          else if (contentFirstOpenedTip.type === 1 || contentFirstOpenedTip.type === 5) {
            setTimeout(() =>
              store.dispatch({
                type: OPEN_GALLERY_TIP,
                payload: contentFirstOpenedTip,
                tipId: 0
              }), 1000);
          }
        }
      }
    }
  }

  return next(action)
}
