import React from 'react';

export const LargeContactIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="36" viewBox="0 0 27 36">
        <g fill="none" fillRule="evenodd">
            <path stroke={props.color} strokeWidth="3" d="M4.145 11.905L0 11.905 0 27.557 14.847 27.557 21.749 32.459 21.749 11.905 17.95 11.905" transform="translate(2.079)" />
            <text fill={props.color} fontFamily="Manrope" fontSize="21" fontWeight="bold" transform="translate(2.079)">
                <tspan x="4.921" y="22">?</tspan>
            </text>
        </g>
    </svg>
);