import config from "configuration";

export const GET_GALLERY_DATA =
  config.apiBaseUrl + "web/GetProjectsGallery?clientId=";
export const GET_GALLERY_DATA_BY_SECTION =
  config.apiBaseUrl + "web/GetSectionGallery?sectionId=";
export const GET_GALLERY_DATA_BY_GUIDE =
  config.apiBaseUrl + "web/GetLessonGallery?lessonDefinitionId=";
export const POST_EMODJI_DATA =
  config.apiBaseUrl + "webproject/WebPostEmodjiRequest";
