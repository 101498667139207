import { getFromStorage, saveToStorage } from 'app/utils/storage';
const accessTokenKey = 'at'

export const saveToken = (applicationId, token) => {
  saveToStorage(accessTokenKey + applicationId, token);
}

export const getToken = (applicationId) => {
  return getFromStorage(accessTokenKey + applicationId);
}

export const tokenExists = (applicationId) => {
  return getFromStorage(accessTokenKey + applicationId);
}
