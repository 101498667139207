export function flattenTheme(theme) {
  if(!theme) {
    return {};
  }
  const initialValue = {};
  var result = theme.reduce((obj, item) => {
    return {
      ...obj,
      [item['property']]: item['value'],
    };
  }, initialValue);
  return result;
}