import React, { Component } from "react";
const { Provider, Consumer } = React.createContext();

class GeneralContextProvider extends Component {
  
  render() {
    return (
      <Provider value={this.props.value}>
        {this.props.children}
      </Provider>
    );
  }
}

export { GeneralContextProvider, Consumer as GeneralContextConsumer };



