import {CHANGE_LANGUAGE} from './actionTypes';

export const changeLanguageAction = (language) => {
  return {
    type: CHANGE_LANGUAGE,
    language: language
  }
}

export function changeLanguage(language) {
      return changeLanguageAction(language);
}