import { POST_PREFIX, ACTION_INPROGRESS, ACTION_SUCCESS, ACTION_FAIL } from 'app/middleware/apiRequestMiddleware';
export { STEP_UPDATE_DATA } from 'app/components/main/instructions/actionTypes';

export const OPEN_SEND_PICTURE_FORM = 'OPEN_SEND_PICTURE_FORM';
export const CLOSE_SEND_PICTURE_FORM = 'CLOSE_SEND_PICTURE_FORM';
export const SEND_PICTURE_FORM = 'SEND_PICTURE_FORM';

export const POST_SEND_PICTURE = POST_PREFIX + 'SEND_PICTURE';

export const POST_SEND_PICTURE_INPROGRESS = POST_SEND_PICTURE + ACTION_INPROGRESS;
export const POST_SEND_PICTURE_SUCCESS = POST_SEND_PICTURE + ACTION_SUCCESS;
export const POST_SEND_PICTURE_FAIL = POST_SEND_PICTURE + ACTION_FAIL;
