import axios, { put } from 'axios';
import { SEND_CONTACT_US, POST_CONTACT_US, POST_CONTACT_US_INPROGRESS, POST_CONTACT_US_FAIL } from 'app/components/controls/contactForm/actionTypes';
import { SEND_PICTURE_FORM, POST_SEND_PICTURE, POST_SEND_PICTURE_INPROGRESS, POST_SEND_PICTURE_FAIL } from 'app/components/controls/sendPicture/actionTypes';
import { UPLOAD_PHOTO_SEND_PIC, POST_UPLOAD_PHOTO, POST_UPLOAD_PHOTO_INPROGRESS, POST_UPLOAD_PHOTO_FAIL } from 'app/components/instructions/takephoto/actionTypes';
import { GET_UPLOAD_URL, POST_QUESTION_URL } from 'app/components/controls/contactForm/services';
import logerror from 'app/utils/logger';

export const communicationMiddleware = store => next => action => {

  switch (action.type) {

    case SEND_CONTACT_US:

      // If there is no attachment, dispatch default contact us
      if (!action.data.attachment || action.data.attachment === [] || action.data.attachmentName === '') {
        store.dispatch({
          type: POST_CONTACT_US,
          payload: {
            data: action.data,
            url: POST_QUESTION_URL
          }
        });
        break;
      }

      try {

        // 1. Notify that contact us submission is in progress
        store.dispatch({
          type: POST_CONTACT_US_INPROGRESS
        });

        // 2. Get a signed url for uploading of the image
        const getUploadUrl = GET_UPLOAD_URL
          .replace('{0}', action.data.applicationId)
          .replace('{1}', action.data.attachmentName);
        axios.get(getUploadUrl).then(response => {
          if (response.status >= 400) {
            logerror(response);
            store.dispatch({
              type: POST_CONTACT_US_FAIL
            });
          }

          // 3. Upload file 
          const url = response.data.uploadUrl;
          const uploadFileUrl = response.data.uploadFileUrl;
          put(url, action.data.attachment, {
            headers: {
              'x-amz-acl': 'public-read'
            }
          }).then(response => {
            if (response.status === 200) {

              // 4. Provide attachment uploaded url and post contact us
              var data = Object.assign(action.data, { attachmentUrl: uploadFileUrl });
              store.dispatch({
                type: POST_CONTACT_US,
                payload: {
                  data,
                  url: POST_QUESTION_URL
                }
              });
            } else {
              logerror(response);
              store.dispatch({
                type: POST_CONTACT_US_FAIL
              });
            }
          });
        });
      }
      catch (ex) {
        console.log(ex);
        logerror(ex);
        store.dispatch({
          type: POST_CONTACT_US_FAIL
        });
        return;
      }
      break;

      case SEND_PICTURE_FORM:
        // If there is no attachment, dispatch default contact us
        if (!action.data.attachment || action.data.attachment === [] || action.data.attachmentName === '') {
          store.dispatch({
            type: POST_SEND_PICTURE,
            payload: {
              data: action.data,
              url: POST_QUESTION_URL
            }
          });
          break;
        }
  
        try {
  
          // 1. Notify that contact us submission is in progress
          store.dispatch({
            type: POST_SEND_PICTURE_INPROGRESS
          });
  
          // 2. Get a signed url for uploading of the image
          const getUploadUrl = GET_UPLOAD_URL
            .replace('{0}', action.data.applicationId)
            .replace('{1}', action.data.attachmentName);
          axios.get(getUploadUrl).then(response => {
            if (response.status >= 400) {
              logerror(response);
              store.dispatch({
                type: POST_SEND_PICTURE_FAIL
              });
            }
  
            // 3. Upload file 
            const url = response.data.uploadUrl;
            const uploadFileUrl = response.data.uploadFileUrl;
            put(url, action.data.attachment, {
              headers: {
                'x-amz-acl': 'public-read'
              }
            }).then(response => {
              if (response.status === 200) {
  
                // 4. Provide attachment uploaded url and post contact us
                var data = Object.assign(action.data, { attachmentUrl: uploadFileUrl });
                store.dispatch({
                  type: POST_SEND_PICTURE,
                  payload: {
                    data,
                    url: POST_QUESTION_URL
                  }
                });
              } else {
                logerror(response);
                store.dispatch({
                  type: POST_SEND_PICTURE_FAIL
                });
              }
            });
          });
        }
        catch (ex) {
          console.log(ex);
          logerror(ex);
          store.dispatch({
            type: POST_SEND_PICTURE_FAIL
          });
          return;
        }
        break;

        case UPLOAD_PHOTO_SEND_PIC:
          // If there is no attachment, dispatch default contact us
          if (!action.data.attachment || action.data.attachment === [] || action.data.attachmentName === '') {
            
            store.dispatch({
              type: POST_UPLOAD_PHOTO,
              payload: {
                data: action.data,
                url: POST_QUESTION_URL
              }
            });
            break;
          }
    
          try {
    
            // 1. Notify that contact us submission is in progress
            store.dispatch({
              type: POST_UPLOAD_PHOTO_INPROGRESS
            });
    
            // 2. Get a signed url for uploading of the image
            const getUploadUrl = GET_UPLOAD_URL
              .replace('{0}', action.data.applicationId)
              .replace('{1}', action.data.attachmentName);
            axios.get(getUploadUrl).then(response => {
              if (response.status >= 400) {
                logerror(response);
                store.dispatch({
                  type: POST_UPLOAD_PHOTO_FAIL
                });
              }
    
              // 3. Upload file 
              const url = response.data.uploadUrl;
              const uploadFileUrl = response.data.uploadFileUrl;
              put(url, action.data.attachment, {
                headers: {
                  'x-amz-acl': 'public-read'
                }
              }).then(response => {
                if (response.status === 200) {
                  // 4. Provide attachment uploaded url and post contact us
                  var data = Object.assign(action.data, { attachmentUrl: uploadFileUrl });
                  store.dispatch({
                    type: POST_UPLOAD_PHOTO,
                    payload: {
                      data,
                      url: POST_QUESTION_URL
                    }
                  });
                } else {
                  logerror(response);
                  store.dispatch({
                    type: POST_UPLOAD_PHOTO_FAIL
                  });
                }
              });
            });
          }
          catch (ex) {
            console.log(ex);
            logerror(ex);
            store.dispatch({
              type: POST_UPLOAD_PHOTO_FAIL
            });
            return;
          }
          break;
          
    default:
      break;
  }
  return next(action);
}

