import TimeMe from 'timeme.js';
import config from "configuration";
import axios from 'axios';
import logerror from 'app/utils/logger';
import { getFromStorage, saveToStorage, removeFromStorage } from 'app/utils/storage';

class ProjectAnalyticsService {

  trackingConsent = false;

    VideoActionType = {
       Leave: 0,
       Play: 1,
       Pause: 2,
       MoveForwardCommand: 3,
       MoveBackwardsCommand: 4,
       Finish: 5
    };

    consentToTracking = () => {
      this.trackingConsent = true;
    }
    
    init = (clientId, projectId) => {

      this.reportUrl = 'statistics/ReportWebLessonStatistics';
      this.apiPlainTextUrl = 'statistics/ReportWebLessonStatisticsPlainText';
      this.defaultPageName = "default";
      this.videoStatistics = [];
      this.currentStepId = -1;
      this.currentStepActivity = 'none';
      this.isFinishedProject = false;
      this.clientId = clientId;
      this.projectId = projectId;
      
      TimeMe.initialize({
         currentPageName: this.defaultPageName,
         idleTimeoutInSeconds: 600
      });
      TimeMe.stopTimer();
      TimeMe.resetAllRecordedPageTimes();

      // Try to report the pevious session
      this.report();
    }

    startRecordingActivity = (itemType, itemId, subItemId) => {

      TimeMe.stopTimer(this.currentStepActivity);
      this.currentStepActivity = this.currentStepId + '_' + itemType + '_' + itemId + '_' + subItemId;
      TimeMe.startTimer(this.currentStepActivity);
    }

    stopRecordingActivity = () => {
      TimeMe.stopTimer(this.currentStepActivity);
      this.save();
    }

    startRecording = (stepId) => {

      this.currentStepId = stepId;
      TimeMe.stopTimer();
      TimeMe.setCurrentPageName(stepId);
      TimeMe.startTimer();
      this.save();
    }

    videoAction = (time, action) => {
        if(!(this.currentStepId in this.videoStatistics)) {
          this.videoStatistics[this.currentStepId] = { actions: [] };
        }
        this.videoStatistics[this.currentStepId].actions.push({ second: parseInt(time, 10), action: action });
        this.save();
    }

    finishProject = () => {
      this.isFinishedProject = true;
      this.save();
    }

    stopRecording = () => {

      this.currentStepId = -1;
      TimeMe.stopTimer();
      TimeMe.setCurrentPageName(this.defaultPageName);
      this.save();
    }
    
    saveAndReport = () => {
      if(!this.trackingConsent) {
        return;
      }
      try {
        this.save();
        this.report(this.projectId);
      }
      catch(err) {
        logerror(err);
        console.log(err);
      }
    }

    save = () => {

      if(!this.trackingConsent) {
        return;
      }

      let timeSpentReport = TimeMe.getTimeOnAllPagesInSeconds();

      // Set quit step
      let quitStep = this.currentStepId;
      if(this.isFinishedProject) {
        quitStep = '';
      }

      // Create data object
      var data =
      {
          clientId: this.clientId,
          projectId: this.projectId,
          quitStep: quitStep,
          stepsLength:{},
          stepsActivities: {},
          videoStepsStatistics: {}
      };

      // Update video statistics
      for(let key in this.videoStatistics) {
        data.videoStepsStatistics[key] = this.videoStatistics[key];
      }
              
      // Update step statistics
      for(let index in timeSpentReport) {
          let page = timeSpentReport[index].pageName;
          if(page !== this.defaultPageName) {
              if(!page.includes('_')) {
                data.stepsLength[parseInt(page, 10)] = parseInt(timeSpentReport[index].timeOnPage, 10);
              }
              else {
                 let activityKey = page.split('_');
                 let stepId = parseInt(activityKey[0], 10);
                 let activityType = activityKey[1];
                 let activityId = activityKey[2];
                 let activitySubId = activityKey[3];
                 if(!(stepId in data.stepsActivities)) {
                   data.stepsActivities[stepId] = [];
                 }
                 data.stepsActivities[stepId].push(
                 {
                    type: activityType,
                    id: activityId,
                    subId: activitySubId,
                    time: timeSpentReport[index].timeOnPage
                 });
              }
          }
      }

      // Save to local storage      
      let dataString = JSON.stringify(data);
      saveToStorage(this.getStorageProjectKey(this.projectId), dataString);

      // Save the project to projects list in local storage
      let projectsList = []; 
      let projectsListJson = getFromStorage(this.getStorageProjectsListKey()); 
      if(projectsListJson && projectsListJson !== '') {
        projectsList = JSON.parse(projectsListJson);
      }
      if(!projectsList.includes(this.projectId)) {
        projectsList.push(this.projectId);
        saveToStorage(this.getStorageProjectsListKey(), JSON.stringify(projectsList));
      }
    }

    report = (singleProjectId) => {
      
      try
      {
        var apiurl = config.apiBaseUrl + this.reportUrl;
        var apiPlainTextUrl = config.apiBaseUrl + this.apiPlainTextUrl;
        
        // Get statistics projects list
        let projectsList = []; 
        let projectsListJson = getFromStorage(this.getStorageProjectsListKey()); 
        if(projectsListJson && projectsListJson !== '') {
          projectsList = JSON.parse(projectsListJson);
        }

        // Clear projects list in from storage
        if(!singleProjectId) {
          removeFromStorage(this.getStorageProjectsListKey()); 
        }

        // Go through the projects
        for (var index in projectsList) {
          var projectId = projectsList[index];
          if(!singleProjectId || singleProjectId === projectId) {
            try
            {
              let projectDataJson = getFromStorage(this.getStorageProjectKey(projectId));
              if(projectDataJson && projectDataJson !== '') {

                // Get data and remove the project id from local storage
                let projectData = JSON.parse(projectDataJson);

                // Report statistics
                if(singleProjectId && 'sendBeacon' in navigator) {
                  let beaconAdded = navigator.sendBeacon(apiPlainTextUrl, JSON.stringify(projectData));
                  if(beaconAdded) {
                    removeFromStorage(this.getStorageProjectKey(projectId))
                  }
                }
                else {
                  if(Object.keys(projectData.stepsLength).length > 0) {
                    axios.post(apiurl, {
                      projectData,
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type':'application/json'
                      }
                    }).then(removeFromStorage(this.getStorageProjectKey(projectId)));
                  }
                }
              }
            }
            catch(err) {
              logerror(err);
              console.log(err);
              removeFromStorage(this.getStorageProjectKey(projectId));
            }
          }
        }
      }
      catch(err) {
        logerror(err);
        console.log(err);
      }
    }

    getStorageProjectKey = (projectId) => {
      return this.clientId + "-project-" + projectId;
    }

    getStorageProjectsListKey = () => {
      return this.clientId + "-projects";
    }
  }

export default new ProjectAnalyticsService();
