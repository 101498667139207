import React, { Component } from 'react';
import Home from 'app/components/home/home/Home';

class HomePage extends Component {

  componentDidMount() {

  }

  render() {

    return (
        <Home />
    );
  }
}

export default HomePage;