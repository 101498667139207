import { getQueryVariable } from 'app/utils/utils';
import { initialize, addTranslationForLanguage, setActiveLanguage } from 'react-localize-redux';
import { languages } from 'assets/local/languages';
import { isLanguageRtl } from 'app/utils/utils';

export const INIT_LANGUAGES = 'INIT_LANGUAGES';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

const en = require('assets/local/eng.json');
const he = require('assets/local/heb.json');
const fra = require('assets/local/fra.json');
const deu = require('assets/local/deu.json');
const fin = require('assets/local/fin.json');
const ita = require('assets/local/ita.json');
const nor = require('assets/local/nor.json');
const swe = require('assets/local/swe.json');
const zho = require('assets/local/zho.json');
const dan = require('assets/local/dan.json');
const dut = require('assets/local/dut.json');
const jpn = require('assets/local/jpn.json');
const pol = require('assets/local/pol.json');
const spa = require('assets/local/spa.json');
const cat = require('assets/local/cat.json');

export const languagesMiddleware = store => next => action => {

  switch (action.type) {
    case INIT_LANGUAGES:

      if (action.languages && action.languages.length > 0) {

        var languageParam = action.currentLanguage;
        var lanuageParamAdded = false;
        var projectLanguages = action.languages.map(a => a.languageCode);
        var clientLanguageCodes = action.languages.map(a => a.clientLanguageCode);
        var finalLanguages = [];

        var languagesListParam = getQueryVariable('languages');
        if (languagesListParam !== '') {
          var languagesFromParam = languagesListParam.split(',');
          languagesFromParam.forEach(language => {
            if (projectLanguages.includes(language) || clientLanguageCodes.includes(language)) {
              finalLanguages.push(language);
            }
          });
        } else {
          finalLanguages = projectLanguages;
        }

        if (!finalLanguages.includes(languageParam)) {
          finalLanguages.push(languageParam);
          lanuageParamAdded = true;
        }

        // Configure languages
        store.dispatch(initialize(languages.concat(finalLanguages), { defaultLanguage: 'eng' }));
        store.dispatch(addTranslationForLanguage(en, 'eng'));
        store.dispatch(addTranslationForLanguage(he, 'heb'));
        store.dispatch(addTranslationForLanguage(fra, 'fra'));
        store.dispatch(addTranslationForLanguage(deu, 'deu'));
        store.dispatch(addTranslationForLanguage(fin, 'fin'));
        store.dispatch(addTranslationForLanguage(ita, 'ita'));
        store.dispatch(addTranslationForLanguage(nor, 'nor'));
        store.dispatch(addTranslationForLanguage(swe, 'swe'));
        store.dispatch(addTranslationForLanguage(zho, 'zho'));
        store.dispatch(addTranslationForLanguage(dan, 'dan'));
        store.dispatch(addTranslationForLanguage(dut, 'dut'));
        store.dispatch(addTranslationForLanguage(jpn, 'jpn'));
        store.dispatch(addTranslationForLanguage(pol, 'pol'));
        store.dispatch(addTranslationForLanguage(spa, 'spa'));
        store.dispatch(addTranslationForLanguage(cat, 'cat'));

        // Configure languages without support
        finalLanguages.forEach(language => {
          if (!languages.includes(language)) {
            store.dispatch(addTranslationForLanguage(en, language));
          }
        });

        // Set current language
        var currentLanguage;
        if (finalLanguages.includes(action.currentLanguage)) {
          currentLanguage = action.languages.find(p => p.languageCode === action.currentLanguage);
          if (currentLanguage && currentLanguage.clientLanguageCode && currentLanguage.clientLanguageCode !== '') {
            store.dispatch(setActiveLanguage(currentLanguage.clientLanguageCode));
          } else {
            store.dispatch(setActiveLanguage(action.currentLanguage));
          }
        }
        if (lanuageParamAdded) {
          currentLanguage = action.languages.find(p => p.languageCode === languageParam);
          if (currentLanguage && currentLanguage.clientLanguageCode && currentLanguage.clientLanguageCode !== '') {
            store.dispatch(setActiveLanguage(currentLanguage.clientLanguageCode));
          } else {
            store.dispatch(setActiveLanguage(languageParam));
          }
        }

        document.body.className = currentLanguage.languageCode;

        // Remove unneeded languages
        action.languages = action.languages.filter(function (language) {
          return finalLanguages.includes(language.languageCode);
        });

        // Add direction for RTL languages
        if (isLanguageRtl(action.currentLanguage)) {
          var sheetRtl = document.createElement('style')
          sheetRtl.innerHTML = ".direction {direction: rtl; text-align: right;} .form-container{float:right;} .direction-only{direction: rtl;}";
          document.body.appendChild(sheetRtl);
        } else {
          var sheetLtr = document.createElement('style')
          sheetLtr.innerHTML = ".direction {direction: ltr; text-align: left;} .direction-only{direction: ltr;}";
          document.body.appendChild(sheetLtr);
        }
      } else {
        store.dispatch(initialize(['eng'], { defaultLanguage: 'eng' }));
        store.dispatch(addTranslationForLanguage(en, 'eng'));
        store.dispatch(setActiveLanguage('eng'));
      }
      break;

    case CHANGE_LANGUAGE:
      if (action.language && action.language !== '') {
        reloadWithLanguage(action.language);
      }
      break;
    default:
      break;
  }
  return next(action);

  function reloadWithLanguage(language) {
    var url = new URL(window.location);
    url.searchParams.set('language', language);
    url.searchParams.delete('store');
    url.searchParams.delete('storeid');
    window.location = url.toString();
  }
}

