import { OPEN_GALLERY_TIP, CLOSE_GALLERY_TIP, OPEN_TEXT_TIP, OPEN_FORM_TIP, OPEN_CONTACT_FORM, OPEN_SEND_PICTURE_FORM } from './actionTypes';

export const openGalleryTipAction = (item, tipId) => {
  return {
      type: OPEN_GALLERY_TIP,
      payload: item,
      tipId: tipId
  }
}

export const openTextTipAction = (item, tipId) => {
  return {
      type: OPEN_TEXT_TIP,
      text: item.data.text,
      tipId: tipId
  }
}

export const openFormTipAction = (item, tipId) => {
  return {
      type: OPEN_FORM_TIP,
      item: item,
      tipId: tipId
  }
}

export const closeGalleryTipAction = () => {
  return {
      type: CLOSE_GALLERY_TIP
  }
}

export const openContactUsAction = () => {
  return {
      type: OPEN_CONTACT_FORM
  }
}

export const openSendPictureAction = () => {
  return {
      type: OPEN_SEND_PICTURE_FORM
  }
}

export function openGalleryTip(item, id) {
  return openGalleryTipAction(item, id);
}

export function openTextTip(item, id) {
  return openTextTipAction(item, id);
}

export function openFormTip(item, id) {
  return openFormTipAction(item, id);
}

export function openContactUs() {
  return openContactUsAction();
}

export function openSendPicture() {
  return openSendPictureAction();
}

export function closeGalleryTip() {
  return closeGalleryTipAction();
}