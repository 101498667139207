import React from 'react';
import { ThemeContextConsumer } from './ThemeContext';

export function withThemeContext(Component) {
    return function WrapperComponent(props) {
        return (
            <ThemeContextConsumer>
                {state => <Component {...props} themeContext={state} />}
            </ThemeContextConsumer>
        );
    };
}