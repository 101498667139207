import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/navigation.scss';
import { getTranslate } from 'react-localize-redux';
import { CSSTransition } from 'react-transition-group';
import { motion } from "framer-motion"

class NavigationItem extends Component {

    state = {
        loaded: false
    }

    componentDidMount() {
        this.setState({ loaded: true });
    }

    render() {

        return (
            <CSSTransition in={this.state.loaded} timeout={300} classNames="fade-guide">  
                <motion.div 
                    layout
                    animate={{opacity: 1}}
                    initial={{opacity: 0}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.3, layout: {duration: 0.3}}}
                    className='navigation-item'
                    onClick={() => this.props.onClick(this.props.id, this.props.type, this.props.data)}
                >
                    <div
                        className='navigation-item-image'
                        style={{ backgroundImage: `url(${this.props.imageUrl})` }} 
                    />
                    <div className='navigation-item-title small-bold-title direction'>{this.props.title}</div>
                    {
                        (this.props.itemsNumber > 0) ?
                            <span className='navigation-item-subtitle small-text title-font direction'>
                                {
                                    (this.props.type === 0) ?
                                        this.props.itemsNumber + " " + this.props.translate('items') :
                                        (this.props.type === 1) ?
                                            this.props.itemsNumber + " " + this.props.translate('guides') :
                                            null
                                }
                            </span> : null
                    }
                </motion.div>
             </CSSTransition>
        );
    }
}


function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

export default connect(mapStateToProps)(NavigationItem);