import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import 'assets/styles/step.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import StepContentLogo from './StepContentLogo';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from 'react-scrollbars-custom';
import ContentTip from './overlays/contentTip/ContentTip';
import Stepper from './stepper/Stepper';
import Form from './form/Form';

class StepText extends Component {

  render() {
    return (
      <div
        className='step'
        style={(this.props.imageUrl && this.props.imageUrl !== '') ?
          { backgroundImage: "url(" + this.props.imageUrl + ")" } :
          { background: this.props.themeContext.textStepBackgroundColor }}>
        <div style={{ height: 'calc(100% - 70px)' }}>
          <Scrollbar noScrollX>
            <StepContentLogo />
            <div style={{ width: '100%', height: '100%' }}>
              <div className='text-step-wrapper direction'>
                <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-medium">
                  <span
                    className='text-step-title'
                    style={{ color: this.props.themeContext.textStepTextColor }}>
                    <Stepper />
                  </span>
                </CSSTransition>
                <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-quick">
                  <div className='direction'>
                    <span
                      className='text-step-text medium-title title-font'
                      style={{ color: this.props.themeContext.textStepTextColor }}>
                      {this.props.text}
                    </span>
                    {
                      (this.props.contentTips) ?
                        this.props.contentTips.map((item, index) => (
                          <ContentTip
                            key={index}
                            tip={item}
                            color={this.props.themeContext.textStepTextColor}
                            backgroundColor={'transparent'}
                            openAction={this.props.openAction}
                          />
                        )) : null
                    }
                    {
                      (this.props.extraData && this.props.extraData.extraDataForm) ?
                        <Form
                          addPadding={this.props.text !== ''}
                          title={this.props.extraData.extraDataForm.title}
                          titleColor={this.props.themeContext.textStepTextColor}
                          fields={this.props.extraData.extraDataForm.fields} /> : null
                    }
                  </div>
                </CSSTransition>
              </div>
            </div>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)
  };
}

export default withThemeContext(connect(mapStateToProps)(StepText));