import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { withThemeContext } from 'app/context/withThemeContext';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from 'react-scrollbars-custom';
import ContentTip from './overlays/contentTip/ContentTip';
import Form from './form/Form';
import Stepper from './stepper/Stepper';

class StepContentText extends Component {

  render() {

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{
          height: '100%'
        }}>
          <Scrollbar noScrollX>
            <div 
              className={(this.props.displayModeContext.portrait) ? "step-content-wrapper-portrait" : "step-content-wrapper"}
              style={ (this.props.addToBackground) ?
                      {
                        display: 'none',
                        top: '15%',
                        position: 'relative',
                        // display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontSize: '25px',
                        lineHeight: '28px',
                        fontWeight: 'bold',
                        width: '50%',
                        margin: '0 auto'
                      } : 
                      {}
              }
            >
              <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-medium">
                <span className='step-title default-title title-font direction'>
                  <Stepper />
                </span>
              </CSSTransition>
              <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-quick">
                <div>
                  <span className='step-text direction'>{this.props.text}</span>
                  {
                    (this.props.contentTips && this.props.contentTips.length > 0) ?
                      <div className='content-tips-wrapper direction'>
                        {
                          this.props.contentTips.map((item, index) => (
                            <ContentTip
                              key={index}
                              tip={item}
                              openAction={this.props.openAction}
                            />
                          ))
                        }
                      </div> : null
                  }
                  {
                    (this.props.extraData && this.props.extraData.extraDataForm && !this.props.addToBackground) ?
                      <Form
                        addPadding={this.props.text !== ''}
                        title={this.props.extraData.extraDataForm.title}
                        titleColor={this.props.themeContext.stepTextColor}
                        fields={this.props.extraData.extraDataForm.fields}
                        button={this.props.extraData.extraDataForm.button} /> : null
                  }
                </div>
              </CSSTransition>
            </div>
          </Scrollbar>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)
  };
}

export default
  withDisplayModeContext(
    withThemeContext(
      connect(mapStateToProps)(StepContentText)));