import React, { Component } from 'react';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { withThemeContext } from 'app/context/withThemeContext';
import 'assets/styles/webStep.scss';
import StepContentText from './StepContentText';
import StepContentLogo from './StepContentLogo';
import Box from '@material-ui/core/Box';
import { getQueryVariable } from 'app/utils/utils';

class StepWeb extends Component {

  state = {
    wasloaded: false,
    rtl: (getQueryVariable('language') === 'heb') ? true : false,
    langParam: getQueryVariable('language'),
    webUrl: window.location.pathname
  }

  webLoaded = () => {
    this.setState({ wasloaded: false})
  }

  render() {

    let webclass = 'webStep';
    let iframeSrc = this.state.webUrl.includes('pixbrix') ? `${this.props.webUrl}&language=${this.state.langParam ? this.state.langParam : 'eng'}` : this.props.webUrl
    if(this.state.wasloaded) {
      webclass = 'webStepHidden';
    }

    return (
      <div className='step' style={{ background: '#ffffff'}}>
        <Box 
          display="flex"
          flexDirection={(this.props.displayModeContext.portrait) ? "column" : "row"}
          style={{height: 'calc(100% - 70px)'}}>        
          <Box 
            flexGrow={1} 
            style={{
              overflow: 'hidden', 
              height: (this.props.displayModeContext.portrait) ? '50%' : '100%', 
              width:  (this.props.displayModeContext.portrait) ? '100%' : '50%', 
            }}>
            <div className='webStepWrapper'>
                <iframe
                  className={webclass}
                  onLoad={this.webLoaded}
                  style={{border: 0}}
                  src={this.props.webUrl}
                  title={this.props.stepId} />
              </div>
            </Box>
            {
              (this.props.text !== '') ?
              <>
                <StepContentLogo />
                <Box 
                  flexGrow={1} 
                  style={{
                    backgroundColor: this.props.themeContext.stepTextBackgroundColor,
                    color: this.props.themeContext.stepTextColor,
                    height: (this.props.displayModeContext.portrait) ? '50%' : '100%', 
                    width:  (this.props.displayModeContext.portrait) ? '100%' : '34%',
                    }}>
                    <div style={{ height: '100%'}}>
                      <StepContentText 
                        isCurrentStep={this.props.isCurrentStep}
                        stepId={this.props.stepId}
                        text={this.props.text}
                        contentTips={this.props.contentTips}
                        openAction={this.props.openAction} />
                    </div>
                  </Box>
                </> : null
              }
          </Box>
        </div>
    );
  }
}

export default withDisplayModeContext(withThemeContext(StepWeb));
