import React, { Component } from "react";
import { connect } from "react-redux";
import { withThemeContext } from "app/context/withThemeContext";
import { withGeneralContext } from "app/context/withGeneralContext";
import GalleryGrid from "app/components/home/components/gallery/GalleryGrid";
import { getData } from "../components/home/home/actions";


class GalleryPage extends Component {
  componentDidMount() {
    const { appName, language } = this.props.generalContext;
    this.props.getData(appName, language);
  }

  render() {
    return <GalleryGrid sectionId={this.props.match.params.sectionId ? this.props.match.params.sectionId : null} guideId={this.props.match.params.guideId ? this.props.match.params.guideId : null} />;
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    app: state.app,
    contactForm: state.contactForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getData: (appName, language) => dispatch(getData(appName, language)),
  };
}

export default withGeneralContext(
  connect(mapStateToProps, mapDispatchToProps)(withThemeContext(GalleryPage))
);
