import { FETCH_PROJECT_DATA_SUCCESS, FETCH_PROJECT_DATA_FAIL } from 'app/components/main/guide/actionTypes';
import { FETCH_APP_DATA_SUCCESS, FETCH_APP_DATA_FAIL } from 'app/components/home/home/actionTypes';
import { FETCH_SECTION_DATA_SUCCESS, FETCH_SECTION_DATA_FAIL } from 'app/components/home/section/actionTypes';
import { INIT_THEME } from 'app/app/actionTypes';
import { INIT_LANGUAGES } from 'app/middleware/languagesMiddleware';
import { START_GUIDE } from 'app/components/main/guide/actionTypes';
import { saveBackNavigationUrl } from 'app/components/main/guide/actions';
import { getQueryVariable, queryVariableExists, isInIframe } from 'app/utils/utils';
import { flattenTheme } from 'app/utils/appUtils';
import { reportEvent } from 'app/utils/googleAnalytics';
import NavigationTypes from 'app/components/home/components/navigationItem/types';

export const initMiddleware = store => next => action => {

  switch (action.type) {
    case FETCH_APP_DATA_FAIL:
    case FETCH_SECTION_DATA_FAIL:
    case FETCH_PROJECT_DATA_FAIL:

      store.dispatch({ type: INIT_LANGUAGES });
      break;

    case FETCH_SECTION_DATA_SUCCESS:

      setPageTitle(action.payload.application.companyName, action.payload.sectionName);
      setMetaTags(action.payload.sectionName, action.payload.sectionImageUrl);

      store.dispatch({
        type: INIT_LANGUAGES,
        languages: action.payload.application.languages,
        currentLanguage: action.payload.application.currentLanguage
      });

      store.dispatch({
        type: INIT_THEME,
        theme: flattenTheme(action.payload.application.themeSettings)
      });

      break;

    case FETCH_APP_DATA_SUCCESS:

      setPageTitle(action.payload.application.companyName, '');
      setMetaTags(action.payload.sectionName, action.payload.application.mainImage)

      store.dispatch({
        type: INIT_LANGUAGES,
        languages: action.payload.application.languages,
        currentLanguage: action.payload.application.currentLanguage
      });

      store.dispatch({
        type: INIT_THEME,
        theme: flattenTheme(action.payload.application.themeSettings)
      });

      action.payload.navigation.topSectionId = getQueryVariable('section');
      if (action.payload &&
        action.payload.navigation.mainMenu &&
        action.payload.navigation.mainMenu.menuItems &&
        action.payload.navigation.mainMenu.menuItems.length > 0) {
        var newNavItems = [];
        var navItemId = 1;
        action.payload.navigation.mainMenu.menuItems.forEach(menuItem => {
          var id = (menuItem.type === NavigationTypes.sections) ?
            (menuItem.data === '') ?
              "top" : menuItem.data
            : navItemId++;
          newNavItems.push({
            id: id,
            parent: '',
            title: menuItem.title,
            imageUrl: menuItem.imageUrl,
            data: menuItem.data,
            type: menuItem.type,
            itemsNumber: menuItem.itemsNumber
          });
        })
        action.payload.navigation.navItems.forEach(navItem => {
          navItem.type = (navItem.data !== '') ? NavigationTypes.section : NavigationTypes.sections;
          if (navItem.parent === '') {
            navItem.parent = 'top';
          }
          newNavItems.push(navItem);
        });
        action.payload.navigation.navItems = newNavItems;
      }
      else {
        if (action.payload.navigation.navItems) {
          action.payload.navigation.navItems.forEach(navItem => {
            if (navItem.data !== '') {
              navItem.type = NavigationTypes.section;
            } else {
              navItem.type = NavigationTypes.sections;
            }
          });
        }
      }
      break;

    case FETCH_PROJECT_DATA_SUCCESS:

      setPageTitle(action.payload.companyName, action.payload.name);
      setMetaTags(action.payload.name, action.payload.infoImageUrl)

      store.dispatch({
        type: INIT_LANGUAGES,
        languages: action.payload.languages,
        currentLanguage: action.payload.currentLanguage
      });

      store.dispatch({
        type: INIT_THEME,
        theme: flattenTheme(action.payload.themeSettings)
      });

      // Report basic events
      reportEvent('guideDetails', 'appName', action.payload.appName);
      reportEvent('guideDetails', 'guideName', action.payload.name);
      reportEvent('guideDetails', 'guideId', action.payload.projectId);
      var source = getQueryVariable('source');
      if (source === '') {
        source = 'direct';
      }
      reportEvent('marketing', 'source', source);

      // Check if we received back to previous guide url or a navigation data and save or load it
      var backUrl = getQueryVariable("refurl");
      if (backUrl !== "") {
        store.dispatch(saveBackNavigationUrl(backUrl));
      }

      // Set if to show progress bar values in percentages or in a default step numbers
      action.payload.showProgressbarInPercentages = action.payload.lessonSteps.some((item) =>
        item.extraData &&
        item.extraData.extraDataQuestion &&
        item.extraData.extraDataQuestion.data &&
        item.extraData.extraDataQuestion.data.some((answer) => answer.sectionId !== '')
      );

      // Fire a start event if we don't have an opening intro
      if(action.payload.infoImageUrl === '') {
        store.dispatch({
          type: START_GUIDE,
          started: true
        });
      }
      break;

    case INIT_THEME:

      // Add custom font to default texts
      if (action.theme.customTextFont && action.theme.customTextFont !== '') {
        var link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', action.theme.customTextFontUrl);
        document.head.appendChild(link);
        document.body.style.fontFamily = action.theme.customTextFont;
      }

      // Add custom font to title texts
      if (action.theme.customTitleFont && action.theme.customTitleFont !== '') {
        var titleLink = document.createElement('link');
        titleLink.setAttribute('rel', 'stylesheet');
        titleLink.setAttribute('type', 'text/css');
        titleLink.setAttribute('href', action.theme.customTitleFontUrl);
        document.head.appendChild(titleLink);
        var titleFont = document.createElement('style')
        titleFont.innerHTML = ".title-font { font-family:" +  action.theme.customTitleFont +  " !important }";
        document.body.appendChild(titleFont);
      }

      // Add custom css
      if (action.theme.customCss && action.theme.customCss !== '') {
        var customCss = document.createElement('style')
        customCss.innerHTML = action.theme.customCss;
        document.body.appendChild(customCss);
      }

      if(queryVariableExists('portrait_view')){
        document.body.classList.add('portrait-view');

        if(action.theme && action.theme.termsUrl){
          action.theme.termsUrl = "https://giftbooster.io/terms"
        }
      }

      if(isInIframe()){
        document.body.classList.add('embedded');
      }

      break;

    default:
      break;
  }
  return next(action);

  function setPageTitle(companyName, title) {
    if (companyName && companyName !== '') {
      if (title && title !== '') {
        document.title = companyName + ' - ' + title;
      } else {
        document.title = companyName;
      }
    } else {
      document.title = title ? title : 'Makeree digital guides';
    }
  }

  function setMetaTags(title, image){

    let getLocalUrl = document.location.href;
    let setTitle = title ? title : 'Makeree digital guides';
    let setImage = image ? image : 'https://www.makeree.com/wp-content/uploads/2020/05/main.png';
    let setDescription = title ? `${title}, Makeree digital guides` : 'Makeree digital guides'

    document.querySelector('meta[name="description"]').content = setDescription;
    document.querySelector('meta[property="og:title"]').content = setTitle;
    document.querySelector('meta[property="og:description"]').content = setDescription;
    document.querySelector('meta[property="og:url"]').content = getLocalUrl;
    document.querySelector('link[rel="canonical"]').href = getLocalUrl;
    document.querySelector('meta[property="og:image"]').content = setImage;
  }

}
