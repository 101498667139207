import { createStore, applyMiddleware, compose } from 'redux'
import {combineReducers} from 'redux';
import { localeReducer } from 'react-localize-redux';
import thunkMiddleware from 'redux-thunk';
import AppReducer from 'app/app/reducer';
import HomeReducer from 'app/components/home/home/reducer';
import SectionReducer from 'app/components/home/section/reducer';
import GuideReducer from 'app/components/main/guide/reducer';
import InstructionsReducer from 'app/components/main/instructions/reducer';
import ControlsReducer from 'app/components/controls/guideNavigation/reducer';
import GalleryReducer from 'app/components/home/components/gallery/reducer';
import ContactFormReducer from 'app/components/controls/contactForm/reducer';
import SendPictureReducer from 'app/components/controls/sendPicture/reducer';
import StepFormReducer from 'app/components/instructions/step/components/form/reducer';
import FinishReducer from 'app/components/instructions/finish/reducer';
import StepReducer from 'app/components/instructions/step/reducer';
import GalleryTipReducer from 'app/components/instructions/popups/gallerytip/reducer';
import TextTipReducer from 'app/components/instructions/popups/texttip/reducer';
import FormTipReducer from 'app/components/instructions/popups/formtip/reducer';
import MessageReducer from 'app/components/general/message/reducer';
import SendFormMessageReducer from 'app/components/general/sendFormMessage/reducer';
import TakePhotoReducer from 'app/components/instructions/takephoto/reducer';
import {apiRequestMiddleware} from 'app/middleware/apiRequestMiddleware';
import {slideChangeMiddleware} from 'app/middleware/slideChangeMiddleware';
import {initMiddleware} from 'app/middleware/initMiddleware';
import {trackingMiddleware} from 'app/middleware/trackingMiddleware';
import {communicationMiddleware} from 'app/middleware/communicationMiddleware';
import {navigationMiddleware} from 'app/middleware/navigationMiddleware';
import {guideNavigationMiddleware} from 'app/middleware/guideNavigationMiddleware';
import {languagesMiddleware} from 'app/middleware/languagesMiddleware';
import {getQueryVariable} from 'app/utils/utils';
import {getFromStorage} from 'app/utils/storage';

const allReducers = combineReducers({
  app: AppReducer,
  home: HomeReducer,
  section: SectionReducer,
  guide: GuideReducer,
  instructions: InstructionsReducer,
  controls: ControlsReducer,
  contactForm: ContactFormReducer,
  sendPicture: SendPictureReducer,
  stepForm: StepFormReducer,
  locale: localeReducer,
  gallerytip: GalleryTipReducer,
  texttip: TextTipReducer,
  formtip: FormTipReducer,
  step: StepReducer,
  message: MessageReducer,
  sendFormMessage : SendFormMessageReducer,
  finish: FinishReducer,
  takephoto: TakePhotoReducer,
  gallery: GalleryReducer
})

export function configureStore() {
    let jsonState;
    const savedState = getSavedState();
    const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
    if(savedState && savedState !== '') {
      jsonState = JSON.parse(savedState);
      jsonState.guide.loadedFromStorage = true;
      jsonState.app.loadedFromStorage = true;
    }
    return createStore(allReducers, jsonState, composeEnhancers(applyMiddleware(
      thunkMiddleware, 
      communicationMiddleware,
      slideChangeMiddleware, 
      apiRequestMiddleware, 
      initMiddleware,
      navigationMiddleware,
      guideNavigationMiddleware,
      languagesMiddleware,
      trackingMiddleware)
    ));
}

function getSavedState() {
  try {
    var loadFromStorage = getQueryVariable("store");
    if(loadFromStorage === "true") {
      var storeId = getQueryVariable("storeid");
      if(storeId !== '') {
        var encodedState = getFromStorage('state-' + storeId);
        return decodeURIComponent(escape(atob(encodedState)));
      }
    }
  } catch(ex) {console.log(ex);}
}
