import {UPDATE_CURRENT_STEP} from './actionTypes';

export const updateStepAction = (stepId) => {
  return {
    type: UPDATE_CURRENT_STEP,
    stepId: stepId
  };
}

export function updateStep(stepId) {
  return function (dispatch) {
      dispatch(updateStepAction(stepId));
  }
}