import { START_TRACKING } from './actionTypes';

export const startTrackingAction = () => {
    return {
      type: START_TRACKING
    }
}

export function startTracking() {
    return startTrackingAction();
}