import React from 'react';

export const ProgressBubble = (props) => (
    (props.value && props.value !== '') ? 
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="71" viewBox="0 0 69 71">
        <defs>
            <filter id="prefix__a" width="220.5%" height="210.6%" x="-60.3%" y="-50.6%" filterUnits="objectBoundingBox">
                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="7.5" />
                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            </filter>
            <path id="prefix__b" d="M20 0c10.493 0 19 8.507 19 19 0 9.013-6.276 16.56-14.697 18.51l-4.05 4.63c-.03.033-.061.065-.094.094-.416.364-1.048.322-1.412-.094l-4.05-4.63C6.278 35.562 0 28.015 0 19 0 8.507 8.507 0 19 0h1z" />
        </defs>
        <g fill="none" fillRule="evenodd" opacity=".9" transform="translate(15 13)">
            <use fill="#000" filter="url(#prefix__a)" href="#prefix__b"/>
            <use fill="#12191F" href="#prefix__b"/>
        <text fill='#ffffff' fontFamily="Manrope" fontSize={(props.value.length > 5) ? "10" : "12"} fontWeight="600">
            <tspan x="28%" y="27.5%" alignmentBaseline="middle" textAnchor="middle">{props.value}</tspan>
        </text>
    </g>
    </svg> : null
);