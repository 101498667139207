import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/materials.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import { getTranslate } from 'react-localize-redux';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from 'react-scrollbars-custom';
import MaterialsImagesList from './MaterialsImagesList';
import MaterialsItem from './MaterialsItem';

class MaterialsList extends Component {

  state = {
    imageMaterials: false
  }

  componentDidMount() {
    var imageMaterials = true;
    this.props.materials.forEach(item => {
      if (!item.imageUrl || item.imageUrl === '') {
        imageMaterials = false;
      }
    });
    this.setState({ imageMaterials: imageMaterials });
  }

  render() {

    if (this.state.imageMaterials) {
      return (
        <MaterialsImagesList
          isCurrentStep={this.props.isCurrentStep}
          materialsText={this.props.materialsText}
          materials={this.props.materials} />
      );
    }

    return (
      <>
        {
          (this.props.materials && this.props.materials.length > 0) ?
            <div className='materials-list'>
              <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                <Scrollbar noScrollX>
                  <div className='materials-list-wrapper'>
                    <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-medium">
                      <div className='materials-header default-title title-font direction'
                        style={{ color: this.props.themeContext.stepTextColor }}>
                        {(this.props.materialsText && this.props.materialsText !== '') ?
                            this.props.materialsText:  this.props.translate('materials')}
                      </div>
                    </CSSTransition>
                    {
                      (this.props.materials && this.props.materials.length > 0) ?
                        <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-quick">
                          <ul className='materials-list'
                            style={{ color: this.props.themeContext.stepTextColor }}>
                            {
                              this.props.materials.map((item, index) => (
                                <MaterialsItem index={index} item={item} key={index}/>
                              ))
                            }
                          </ul>
                        </CSSTransition> : null
                    }
                  </div>
                </Scrollbar>
              </div>
            </div> : null
        }
      </>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)

  };
}

export default withThemeContext(connect(mapStateToProps, matchDispatchToProps)(MaterialsList));
