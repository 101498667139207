import React, { Component } from 'react';
import { withThemeContext } from 'app/context/withThemeContext';
import { InfoIcon } from 'app/components/svg/InfoIcon';
import 'assets/styles/tip.scss';

class ContentTextTip extends Component {

  render() {
    return (
      <div className='content-text-tip'>
        <div className='content-text-tip-icon'>
          <InfoIcon color={this.props.color ? this.props.color : this.props.themeContext.stepOpenTipColor} />
        </div>
        <div
          className='content-text-tip-text'
          style={{ color: this.props.color ? this.props.color : this.props.themeContext.stepOpenTipColor }}>
          <span>{this.props.text}</span>
        </div>
      </div>
    );
  }
}

export default withThemeContext(ContentTextTip);
