import {
  FETCH_PROJECT_DATA_SUCCESS,
  INIT_LANGUAGES,
  INIT_THEME,
} from "./actionTypes";
import config from "configuration";
import {
  getGalleryItemsSizeQueryVariable,
  getLanguageQueryVariable,
} from "app/utils/utils";

export default function (
  state = {
    theme: config.theme,
    languages: [],
    guideId: "",
    isThemeLoaded: false,
    language: getLanguageQueryVariable(),
    sizeItem: getGalleryItemsSizeQueryVariable(),
  },
  action
) {
  switch (action.type) {
    case INIT_THEME:
      return {
        ...state,
        theme: Object.assign(state.theme, action.theme),
        isThemeLoaded: true,
      };
    case FETCH_PROJECT_DATA_SUCCESS:
      return {
        ...state,
        language: action.payload.currentLanguage,
        guideId: action.payload.projectId,
      };
    case INIT_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
        language: action.currentLanguage,
      };
    default:
      return { ...state };
  }
}
