import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { getData, getDataGuide, showSections, navigateToSection, navigateToGuide, goBack } from './actions';
import Topbar from 'app/components/home/components/topbar/Topbar';
import Banner from 'app/components/home/components/banner/Banner';
import SectionsNavigation from 'app/components/home/components/sectionsNavigation/SectionsNavigation';
import Footer from 'app/components/home/components/footer/Footer';
import NavigationTypes from 'app/components/home/components/navigationItem/types';
import { openUrl } from 'app/utils/utils';
import ContactButton from 'app/components/home/components/contactButton/ContactButton';
import ContactForm from 'app/components/controls/contactForm/ContactForm';
import Gallery from 'app/components/home/components/gallery/Gallery';
import PoweredBy from "../components/poweredBy/PoweredBy";
import SearchNavigation from '../components/searchNavigation/SearchNavigation';

class Home extends Component {

  state = {
    search: [],
    searchValue: false,
    noData: false,
  }

  componentDidMount() {
    this.props.dispatch(getDataGuide(this.props.generalContext.appName, this.props.generalContext.language));
    this.props.dispatch(getData(this.props.generalContext.appName, this.props.generalContext.language));
  }

  onItemClick = (id, type, data) => {
    switch (type) {
      case NavigationTypes.sections:
        this.props.dispatch(showSections(id));
        break;
      case NavigationTypes.section:
        this.props.dispatch(navigateToSection(data, this.props.home.topSectionId));
        break;
      case NavigationTypes.guide:
        this.props.dispatch(navigateToGuide(data, this.props.home.topSectionId));
        break;
      case NavigationTypes.url:
        openUrl(data);
        break;
      default:
        break;
    }
  }

  back = () => {
    this.props.dispatch(goBack());
  }

  getMainImage = () => {
    if(this.props.home.currentNavigationItem && this.props.home.currentNavigationItem.imageUrl !== '') {
      return this.props.home.currentNavigationItem.imageUrl;
    }
    if(this.props.home.app.mainImage && this.props.home.app.mainImage !== '') {
      return this.props.home.app.mainImage;
    }
    if(this.props.home.navigationItems !== null && this.props.home.navigationItems.length > 0) {
      return this.props.home.navigationItems[0].imageUrl;
    }
    return '';
  }

  handleChange = e => {
    const currentValue = e.target.value.toLowerCase();
    const arrayGuides = [];
    const arrayFilterGuides = [];

    currentValue ? this.setState({searchValue: true}) : this.setState({searchValue: false}); 
    this.props.home.guides.map(course => {
      if(course.alternativeName || course.alternativeSubtitle){
        arrayGuides.push({ courses: course });
      }
      course.lessons.map(lesson => {
        if(lesson.name){
          arrayGuides.push({
            id: lesson.id,
            imageUrl: lesson.image,
            data: lesson.id,
            title: lesson.name,
            courseId: lesson.courseId,
            type: 2
          })
        }
      })
    })

    arrayGuides.filter(item => item.title ? 
                                  item.title.toLowerCase().includes(currentValue) : 
                                  item.courses.alternativeName ? item.courses.alternativeName.toLowerCase().includes(currentValue) : item.courses.alternativeSubtitle.toLowerCase().includes(currentValue))
             .map(item => {
                item.courses ? 
                  item.courses.lessons.map(item => {
                    arrayFilterGuides.push({
                      id: item.id,
                      imageUrl: item.image,
                      data: item.id,
                      title: item.name,
                      courseId: item.courseId,
                      type: 2
                    })
                  }) :
                  arrayFilterGuides.push(item)
             });

    arrayFilterGuides.length === 0 ? this.setState({noData: true}) : this.setState({noData: false}); 
    this.setState({
      search: [...new Set(arrayFilterGuides.map(JSON.stringify))].map(JSON.parse),
    })
  };

  render() {

    if (this.props.home.loading || this.props.home.error) {
      return (
        <div id="mpreload">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )
    }

    if(this.props.themeContext.hideContent !== undefined && this.props.themeContext.hideContent === 'true' ){
      return (
        <div></div>
      );
    }

    return (
        <div className={'home ' + this.props.generalContext.appName} style={{ position: (this.props.contactForm.visible) ? 'fixed' : '', width: '100%', height: '100%' }}>
          <Topbar
            backEnabled={this.props.home.topSectionId !== ''}
            customBackTitle={(this.props.themeContext.mainCustomBackTitle !== undefined) ? this.props.themeContext.mainCustomBackTitle : this.props.home.app.customBackTitle}
            customBackUrl={(this.props.themeContext.mainCustomBackUrl !== undefined) ? this.props.themeContext.mainCustomBackUrl : this.props.home.app.customBackUrl}
            back={this.back}
            sticky={true} />
          <Banner
            imageUrl={this.getMainImage()}
            title={(this.props.home.currentNavigationItem) ? this.props.home.currentNavigationItem.title : 
              this.props.home.app.mainTitle ?? this.props.generalContext.appName}
            subtitle={(this.props.home.currentNavigationItem) ? '' : this.props.home.app.mainSubtitle}
            underTopbar={true}
            themeContext={this.props.themeContext} />
          {
            ((this.props.themeContext.showSearch !== undefined) ? this.props.themeContext.showSearch === 'true' : this.props.home.app.showSearch) ? 
            <SearchNavigation 
              handleChange={this.handleChange} 
              noData={this.state.noData}
              placeholder={this.props.home.app.placeholderSearch}
              loadingGuides={this.props.home.loadingGuides}
            /> : ''
          }
          <PoweredBy isVisible={(this.props.themeContext.poweredMakeree !== undefined) ? this.props.themeContext.poweredMakeree : this.props.home.app.isPoweredByVisible}/>
          <SectionsNavigation
              items={this.state.searchValue ? this.state.search : this.props.home.navigationItems}
              onItemClick={this.onItemClick} />
          <ContactButton />
          <ContactForm guideId={''} />
          <Gallery />
          <Footer
            companyName={this.props.home.app.companyName} />
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    app: state.app,
    contactForm: state.contactForm
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default
  withGeneralContext(
    connect(mapStateToProps, matchDispatchToProps)(
      withThemeContext(Home)));