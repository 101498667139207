import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/guideNavigation.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { openContactForm, openGuideInNewTab } from './actions';
import ProgressBar from 'app/components/controls/progressbar/ProgressBar';
import GuideMenu from 'app/components/controls/guideMenu/GuideMenu';
import { ContactIcon } from 'app/components/svg/ContactIcon';
import { LargeContactIcon } from 'app/components/svg/LargeContactIcon';
import { MenuIcon } from 'app/components/svg/MenuIcon';
import { NewTabIcon } from 'app/components/svg/NewTabIcon';
import NextButton from 'app/components/controls/buttons/NextButton';
import PrevButton from 'app/components/controls/buttons/PrevButton';
import { getTranslate } from 'react-localize-redux';

class GuideNavigation extends Component {

    state = {
        isFullscreen: false,
        isMenuOpened: false,
        menuAnchor: null
    }

    setFullScreen = () => {
        this.setState({ isFullscreen: document.fullscreenElement });
    }

    openContactFormDialog = () => {
        this.props.dispatch(openContactForm());
    }

    openMenu = (open, event) => {
        this.setState({ isMenuOpened: open })
        if (event) {
            this.setState({ menuAnchor: event.target })
        }
    }

    openNewTab = () => {
        this.props.dispatch(openGuideInNewTab(this.props.guideId));
    }

    render() {
        return (
            <div className='guide-navigation-wrapper'>
                <ProgressBar showPercentage={this.props.showProgressbarPercentage} />
                <div className='guide-navigation'
                    style={{
                        backgroundColor: this.props.themeContext.controllerBackgroundColor,
                        color: this.props.themeContext.controllerTextColor
                    }}>
                    <GuideMenu
                        openMenu={this.openMenu}
                        isOpened={this.state.isMenuOpened}
                        anchor={this.state.menuAnchor} />
                    <PrevButton click={this.props.prevSlide} visible={true} />
                    <div style={{ width: '100%'}}></div>
                    <div className='guide-navigation-controls'>
                        {
                            (this.props.themeContext.hideContactForm === "false" && this.props.hideContactUs === false) ?
                                <div
                                    className='guide-navigation-controls-button guide-contact-us'
                                    onClick={this.openContactFormDialog}>
                                    {this.props.appName === "keter" ? 
                                    <div style={{width: 22, display: 'flex', height: 27, paddingBottom: 17}}><LargeContactIcon color={this.props.themeContext.controllerTextColor} /></div> :
                                    <ContactIcon color={this.props.themeContext.controllerTextColor} />}
                                </div> : null
                        }
                        <div
                            className='guide-navigation-controls-button'
                            onClick={this.openNewTab}>
                            <NewTabIcon color={this.props.themeContext.controllerTextColor} />
                        </div>
                        <div className='guide-navigation-controls-button'
                            onClick={(event) => this.openMenu(!this.state.isMenuOpened, event)}>
                            <MenuIcon color={this.props.themeContext.controllerTextColor} />
                        </div>
                    </div>
                    <NextButton click={this.props.nextSlide} visible={this.props.controls.showRightArrow} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        controls: state.controls,
        instructions: state.instructions,
        translate: getTranslate(state.locale)
    };
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, matchDispatchToProps)(
    withDisplayModeContext(withThemeContext(GuideNavigation)));
