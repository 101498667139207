import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/message.scss';
import { getTranslate } from 'react-localize-redux';
import CookieConsent from "react-cookie-consent";
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { startTracking } from './actions';
import { isInIframe } from 'app/utils/utils';
import { getFromStorage, saveToStorage} from 'app/utils/storage';

class Consent extends Component {

    state = {
        storageKeyName: '',
        handled: false
    }

    componentDidMount() {
        var storageKeyName = this.props.generalContext.appName + '-privacy';
        this.setState({ storageKeyName: storageKeyName});
        var consentValue = getFromStorage(storageKeyName);
        if(this.props.themeContext.askForTrackingConsent === 'false' || isInIframe() || (consentValue && consentValue === '1')) {
            this.startUserTracking();
        }
    }

    accept = () => {
        saveToStorage(this.props.generalContext.appName + '-privacy', '1');
        this.startUserTracking();
    }

    startUserTracking = () => {
        this.props.dispatch(startTracking());
        this.setState({ handled: true });
    }

    render() {
        if(this.state.handled || this.state.storageKeyName === '') {
            return null;
        }
        var randomCookieName = this.state.storageKeyName + '-' + (new Date()).getTime();
        return (
            (!isInIframe() && this.props.generalContext.isThemeLoaded) ?
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    onAccept={this.accept}
                    cookieName={randomCookieName}
                    style={{ 
                        background: this.props.themeContext.defaultBackgroundColor, 
                        color: this.props.themeContext.defaultTextColor, 
                        boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.2)",
                        zIndex: "1300",
                        minHeight: 70,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    buttonClasses="default-button"
                    buttonStyle={{
                        background: this.props.themeContext.buttonBackgroundColor,
                        color: this.props.themeContext.buttonTextColor, 
                        fontSize: "16px",
                        width: 100,
                        borderRadius: 4,
                        marginTop: 10,
                        marginBottom: 10,
                        boxShadow: "0 4px 25px 0 rgba(0, 0, 0, 0.15)"
                    }}
                >
                    We use cookies and other tracking technologies to improve your browsing
                    experience and to analyze our website traffic.
                    For more information please visit our {" "}
                    <span>
                        <a style={{ color: this.props.themeContext.defaultTextColor, textDecoration: "underline" }}
                            href={'https://www.makeree.com/privacy/'}
                            target="_blank"
                            rel="noopener noreferrer">
                            Privacy Policy
                        </a>
                    </span>
                </CookieConsent> : null
        )
    }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default
    connect(mapStateToProps, matchDispatchToProps)(
        withGeneralContext(
            withThemeContext(Consent)));
