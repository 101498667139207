import { SEND_PICTURE_FORM, CLOSE_SEND_PICTURE_FORM } from './actionTypes';

export const closeSendPictureAction = () => {
  return {
    type: CLOSE_SEND_PICTURE_FORM
  }
}

export const submitSendPictureAction = (data) => {
  return {
    type: SEND_PICTURE_FORM,
    data: data
  }
}

export function closeSendPicture() {
  return closeSendPictureAction();
}

export function submitSendPicture(appid, language, projectId, stepid, email, question, attachment, attachmentName) {

  var data = {
    email: email,
    text: question,
    applicationId: appid,
    language: language,
    projectId: projectId,
    stepId: stepid,
    attachment: attachment,
    attachmentName: attachmentName
  };
  
  return submitSendPictureAction(data);

  /*var params = new FormData();
  params.append('json', JSON.stringify(jsonRequest));
  params.append('image', image);*/
}
