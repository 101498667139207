import {CLOSE_TEXT_TIP, ONLY_CLOSE_TEXT_TIP} from './actionTypes'


export const closeTipAction = () => {
    return {
        type: CLOSE_TEXT_TIP
    }
}

export function closeTip() {
    return closeTipAction();
}

export const OnlyCloseTipAction = () => {
    return {
        type: ONLY_CLOSE_TEXT_TIP
    }
}

export function OnlyCloseTip() {
    return OnlyCloseTipAction();
}
