import React from 'react';

export const InfoIcon = (props) => (

    <div style={{
        width: 45,
        height: 45,
        borderRadius: 50,
        border: '2px solid ' + props.color,
        display: 'flex',
        alignItems: 'center',
        opacity: 0.25
    }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" style={{ width: '100%'}}>
            <g fill="none" fillRule="evenodd">
                <circle cx="2" cy="2" r="2" fill={props.color} />
                <path stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M2 8L2 18" />
            </g>
        </svg>
    </div>
);