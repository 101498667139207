import React, { Component } from 'react';
import { importAll } from 'app/utils/utils'
import 'assets/styles/tip.scss';
import config from "configuration";
import ProjectAnalyticsHelper from 'app/helpers/projectAnalyticsHelper'
import VideoTipLayer from '../VideoTipLayer';

const images = importAll(require.context('assets/images/tips', false, /\.(png|jpe?g|svg)$/));

class TipIcon extends Component {

    getImageTip = (tip) => {
      if(tip.imageUrl && tip.imageUrl !== '') {
        return tip.imageUrl;
      }
      let image = 'tip_{0}.png'.replace('{0}', tip.iconType);
      if(this.props.hasCustomResources) {
        return config.appResourcesBaseUrl + this.props.appName + '/web/tips/' + image;
      }
      return images[image];
    }

    openGalleryTip = () => {
      this.props.openAction(this.props.item, 0, 2);
    }

    openTip = () => {
      if(this.props.item.type === 4 ) {
        this.props.openAction(this.props.item, 0, 3);
      }else if(this.props.item.type === 8 ) {
        this.props.openAction(this.props.item, 0, 8);
      }
      else if(this.props.item.type === 7) {
        this.props.openAction(this.props.item, 0, 7);
      }
      else if(this.props.item.type === 6) {
        this.props.openAction(this.props.item, 0, 6);
      }
      else if(!this.props.item.data) {
        return;
      }
      else if(this.props.item.data.url) {
        window.open(this.props.item.data.url, '_blank');
      }
      else if(this.props.item.data && this.props.item.data.text !== '') {
        this.props.openAction(this.props.item, 0, 1);
      }
    }

    afterShowTextTip = () => {
      ProjectAnalyticsHelper.startRecordingActivity(1, this.props.tipId, 0);
    }

    afterHideTextTip = () => {
      ProjectAnalyticsHelper.stopRecordingActivity();
    }

    onTipImageLoad = () => {
      this.tipIcon.style.width = 100 / (1024 / this.tipIcon.width) + '%';
      this.tipIcon.style.visibility = 'visible';
    }

    render() {

        let itemStyle = {
            left: this.props.item.x / 1024 * 100 + '%',
            top: this.props.item.y / 768 * 100 + '%',
            position: "absolute"
        };
        if(this.props.item.data && this.props.item.data.width && this.props.item.data.width > 0) {
          var width = 100 / (1024 / this.props.item.data.width) + '%';
          itemStyle = { ...itemStyle, width: width }
        }
        let tipIconClassName = "tips-icon";
        if(!this.props.item.imageUrl || this.props.item.imageUrl === '') {
          tipIconClassName = tipIconClassName + " tips-icon-default";
        }
        if(this.props.item.imageUrl !== '') {
          itemStyle = { ...itemStyle, visibility: 'hidden' }
        }
        if(!this.props.item) {
          return null;
        }
        return (
          <div style={{width: '100%', height: '100%'}}>
              {
                (this.props.item.type === 3) ? 
                  <div style={itemStyle}>
                    <VideoTipLayer
                      url={this.props.item.data.url}
                      width={this.props.item.data.width}
                      height={this.props.item.data.height}>
                    </VideoTipLayer>
                  </div>:
                  (this.props.item.type === 1 || this.props.item.type === 5 || this.props.isCustomClick) ?
                  <img
                    ref={(tipIcon) => {this.tipIcon = tipIcon }}
                    className={tipIconClassName}
                    style={itemStyle}
                    src={this.getImageTip(this.props.item)}
                    onClick={this.openGalleryTip}
                    onLoad={this.onTipImageLoad}
                    alt='' />
                  :
                  <div onClick={this.openTip}>
                      <img
                        ref={(tipIcon) => {this.tipIcon = tipIcon }}
                        className={tipIconClassName}
                        style={itemStyle}
                        src={this.getImageTip(this.props.item)}
                        onLoad={this.onTipImageLoad}
                        alt=''/>
                    </div>
              }
          </div>
      )
    }
}

export default TipIcon;
