export { OPEN_GALLERY_TIP, CLOSE_GALLERY_TIP } from 'app/components/instructions/popups/gallerytip/actionTypes';
export { OPEN_TEXT_TIP} from 'app/components/instructions/popups/texttip/actionTypes';
export { OPEN_FORM_TIP} from 'app/components/instructions/popups/formtip/actionTypes';
export { OPEN_CONTACT_FORM } from 'app/components/controls/contactForm/actionTypes';
export { OPEN_SEND_PICTURE_FORM } from 'app/components/controls/sendPicture/actionTypes'
export { START_GUIDE } from 'app/components/main/guide/actionTypes';

export const STEP_UPDATE_DATA = 'STEP_UPDATE_DATA';
export const BEGINNING_SLIDE = 'BEGINNING_SLIDE';
export const END_SLIDE = 'END_SLIDE';
export const SLIDE = 'SLIDE';
export const NEXT_SLIDE = 'NEXT_SLIDE'
export const PREV_SLIDE = 'PREV_SLIDE'