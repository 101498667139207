import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/intro.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import IntroIcons from './IntroIcons';
import { strToRealTime } from 'app/utils/utils';
import { getTranslate } from 'react-localize-redux';
import { RightArrowIcon } from 'app/components/svg/RightArrowIcon';

class SmallIntro extends Component {

  state = {
    loaded: false
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  open = () => {
    window.open(window.location.href, '_blank');
  }

  render() {

    let timeToMake = strToRealTime(
      this.props.timetomake,
      this.props.translate('shortHours'),
      this.props.translate('hour'),
      this.props.translate('hours'),
      this.props.translate('minutes'));

    return (
      <div
        style={{
          width: '100%', height: '100%', position: 'absolute', zIndex: '999999', overflow: 'hidden',
          backgroundColor: this.props.themeContext.openingScreenBg ? this.props.themeContext.openingScreenBg : this.props.themeContext.defaultBackgroundColor,
          color: this.props.themeContext.defaultTextColor
        }}>
        <div className="intro-landscape-half-wrapper">
          <div className="intro-landscape-details-wrapper">
            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <a href={this.props.themeContext.logoUrl} target="_blank" rel="noopener noreferrer">
              <img className='small-intro-logo' src={this.props.themeContext.logo} alt="" />
            </a>
              <div className='medium-title direction title-font' style={{ paddingBottom: 13 }}>
                {this.props.name}
              </div>
              <IntroIcons
                color={this.props.themeContext.defaultIconsColor}
                timetomake={timeToMake}
                hideTotalStepsNr={this.props.hideTotalStepsNr}
                stepsNumber={this.props.stepsNumber}
                customStyle={{ textAlign: 'left' }} />
            </div>
          </div>
        </div>
        <div className="intro-landscape-half-wrapper"
          style={{
            backgroundImage: "url(" + this.props.imageurl + ")",
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}>
        </div>
        <button
            onClick={this.open}
            className='small-intro-button'
            style={{
              backgroundColor: this.props.themeContext.buttonBackgroundColor,
              color: this.props.themeContext.buttonTextColor
            }}>
            <RightArrowIcon color={this.props.themeContext.buttonTextColor} />
          </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)
  };
}

export default connect(mapStateToProps)(withThemeContext(SmallIntro));