import { CLOSE_FINISH_FORM } from './actionTypes';

export const closeFinishAction = () => {
  return {
    type: CLOSE_FINISH_FORM
  }
}

export function closeFinishForm() {
  return closeFinishAction();
}