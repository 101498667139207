import React, { Component, Suspense } from 'react';
import { PopupButton    } from "react-calendly";
import { connect } from 'react-redux';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { withThemeContext } from 'app/context/withThemeContext';
import { getTranslate } from 'react-localize-redux';
import NextGuide from './NextGuide';
import { LeftArrowIcon } from 'app/components/svg/LeftArrowIcon';
import { closeFinish } from './actions';
import NothingErrorBoundary from 'app/helpers/errorBoundaries/NothingErrorBoundary';
import { Scrollbar } from 'react-scrollbars-custom';
import 'assets/styles/finish.scss';

const Lottie = React.lazy(() => import('react-lottie'));

class Finish extends Component {

  componentWillUnmount = () => {
    if (this.lottie) {
      this.lottie.destroy();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.lottie && this.props.finish.visible && !prevProps.finish.visible) {
      this.lottie.stop();
      this.lottie.play();
    }
  }

  openNextGuide = () => {

    // TODO - use a dispatch action instead

    var query = window.location.search.substring(1);
    var guidePath = (this.props.fullMode) ? '/guide/' : '/guides/guide/';
    var url = '/' + this.props.generalContext.appName + guidePath + this.props.nextGuideId + '?' + query;
    window.location = url;
  }

  close = () => {
    this.props.dispatch(closeFinish());
  }

  render() {

    return (
      <div
        className={(this.props.finish.visible) ? 'finish-wrapper popup-open' : 'finish-wrapper popup-close'}>
        <Scrollbar noScrollX>
          <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            <div className="finish-back small-title title-font" onClick={this.close}>
              <span className='finish-back-arrow'>
                <LeftArrowIcon color="black" />
              </span>
              {this.props.translate('goBack')}
            </div>
            {
              (this.props.themeContext.demo === "true") ?
                <div className='finish-demo-block'>
                  <div className='finish-demo-content'>
                    <div className='default-text'>Thanks for checking out this</div>
                    <div className='default-title title-font' style={{ paddingTop: 4, paddingBottom: 8 }}>{this.props.guide.data.name}</div>
                    <div className='default-text' style={{paddingBottom: '20px'}}> interactive guide demo</div>
                    <div className='default-text'>Our guides offer brands the opportunity to:</div>
                    <ul className='default-text' style={{ fontSize: 14, color: this.props.themeContext.buttonBackgroundColor }}>
                      <li><span style={{ color: 'black' }}>Turn How to's & manuals into a conversation</span></li>
                      <li><span style={{ color: 'black' }}>Directly interact with users for sales & marketing</span></li>
                      <li><span style={{ color: 'black' }}>Transform manual customer service to digital self-service</span></li>
                      <li><span style={{ color: 'black' }}>Gather data analytics on users and usage</span></li>
                    </ul>
                    <div className='default-text'>Please let one of our guiding experts get back to you:</div>
                    <PopupButton   
                      url="https://calendly.com/makeree"
                      text="Set a free walkthrough"
                      className={"default-main-button"}
                      rootElement={document.getElementById("root")}
                      styles={{
                        width: 214,
                        marginTop: 14,
                        marginBottom: 20,
                        backgroundColor: this.props.themeContext.buttonBackgroundColor,
                        color: this.props.themeContext.buttonTextColor
                      }}
                    />
                    <div className='default-text'>or email us at <a href="mailto:info@makeree.com">info@makeree.com</a> to get more information</div>
                  </div>
                </div> :
                <div className='finish-content'>
                  <div style={{ height: 150, width: 150, marginBottom: -40 }}>
                    {
                      <Suspense fallback={<div />}>
                        {
                          <NothingErrorBoundary>
                            <Lottie
                              ref={(lottie) => { this.lottie = lottie }}
                              options={{
                                loop: false,
                                autoplay: false,
                                path: '/r/finish.json',
                              }}
                              height={150}
                              width={150}
                            />
                          </NothingErrorBoundary>
                        }
                      </Suspense>
                    }
                  </div>
                  <div className='finish-text default-title title-font direction'>
                    {
                      (this.props.finishTitle && this.props.finishTitle !== "") ?
                        this.props.finishTitle : this.props.translate('defaultFinishText')
                    }
                  </div>
                  <div className='finish-sub-text default-text direction'>
                    {
                      (this.props.finishtext && this.props.finishtext !== "") ?
                        this.props.finishtext :
                        <span>
                          {
                            this.props.translate('youHaveCompleted') + " " + this.props.guideName +
                            ((this.props.nextGuideId !== '') ? ", " + this.props.translate('readyToContinue') : '')
                          }
                        </span>
                    }
                  </div>
                  <div>
                    {
                      (this.props.nextGuideId !== '') ?
                        <NextGuide
                          nextGuideId={this.props.nextGuideId}
                          nextGuideImageUrl={this.props.nextGuideImageUrl}
                          nextGuideName={this.props.nextGuideName}
                          openNextGuide={this.openNextGuide} /> : null
                    }
                  </div>
                </div>
            }
          </div>
        </Scrollbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    guide: state.guide,
    finish: state.finish
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, matchDispatchToProps)(withThemeContext(withGeneralContext(Finish)));
