import {
  INIT, FETCH_PROJECT_DATA_INPROGRESS, FETCH_PROJECT_DATA_SUCCESS,
  FETCH_PROJECT_DATA_FAIL, UPDATE_STEPS_RANGE, STEP_UPDATE_DATA, 
  NAVIGATE_BACK, SAVE_BACK_DATA, START_GUIDE}
from './actionTypes';

export default function(state =
  {
    applicationId: '',
    loadedFromStorage: false,
    data:
      { materials: [] },
      isfullscreen: false,
      settings: false,
      loading: true,
      error: false,
      localImage: '',
      finishImage: '',
      hiddenStepsNumber: 0,
      navigationStack: [],
      backUrl: '',
      currentStepId: -1
  }, action) {

    switch(action.type)
    {
        case INIT:
          return { ...state, applicationId: action.payload.applicationId }
        case START_GUIDE:
          return { ...state, started: action.started }
        case FETCH_PROJECT_DATA_INPROGRESS:
          return { ...state, loading: true, error: false }
        case FETCH_PROJECT_DATA_SUCCESS:
          return { ...state, data: action.payload, loading: false, error: false, finishImage: action.payload.finishImageUrl }
        case FETCH_PROJECT_DATA_FAIL:
          return { ...state, loading: false, error: true }
        case SAVE_BACK_DATA:
          return { ...state, backUrl: action.payload.backUrl }
        case STEP_UPDATE_DATA:
          return { ...state, currentStepId: action.stepId }
        case UPDATE_STEPS_RANGE:

          // Save navigation details for back purposes
          let navigationStack = state.navigationStack;
          navigationStack.push({
            startStepId: state.data.lessonSteps[0].stepId,
            stepsNumber: state.data.lessonSteps.length,
            hiddenStepsNumber: action.hiddenStepsNumber,
            endStepId: state.currentStepId
          });

          // Return new lesson steps and related details
          return { ...state, data: { ...state.data, lessonSteps: action.steps }, 
                             hiddenStepsNumber: action.hiddenStepsNumber,
                             navigationStack: navigationStack,
                             currentStepId: action.steps[0].stepId }

        case NAVIGATE_BACK:        

          // Check if we have back navigation available in the stack
          let backNavigationStack = state.navigationStack;
          if(backNavigationStack.length > 0) {

            // Take the naviagtion item our of the stacl
            let navigationData = backNavigationStack.pop();
            
            // Set new steps range
            var startIndex = state.data.allSteps.findIndex(p => p.stepId === navigationData.startStepId);
            let steps = state.data.allSteps.slice(startIndex, startIndex + navigationData.stepsNumber);
            
            // Return new lesson steps and related details
            return { ...state, 
              data: { ...state.data, lessonSteps: steps }, 
              hiddenStepsNumber: navigationData.hiddenStepsNumber,
              navigationStack: backNavigationStack,
              currentStepId: navigationData.endStepId }
          } else {
            return { ...state };
          }
        default:
          return { ...state };
    }
}
