import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { closeDialog } from './actions';
import { onlyCloseDialog } from './actions';
import BasicVideoPlayer from 'app/components/instructions/helpers/videoplayer/basicVideoPlayer';
import { CSSTransition } from 'react-transition-group';
import { getTranslate } from 'react-localize-redux';
import { LeftArrowIcon } from 'app/components/svg/LeftArrowIcon';
import { RightArrowIcon } from 'app/components/svg/RightArrowIcon';
import { CloseIcon } from 'app/components/svg/CloseIcon';
import 'assets/styles/galleryTip.scss';

class GalleryImagesTip extends Component {

    state = {
        sliderIndex: 0,
        animate: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.data.isvisible && !prevProps.data.isvisible) {
            this.setState({
                animate: true,
                sliderIndex: 0
            });
        }
    }

    containerClick = (event) => {
        event.stopPropagation();
    }

    onlyClose = () => {
        this.props.dispatch(onlyCloseDialog());
    }

    close = () => {
        this.props.dispatch(closeDialog());
    }

    prev = (event) => {
        event.stopPropagation();
        if (this.state.sliderIndex > 0) {
            this.setState({ sliderIndex: this.state.sliderIndex - 1, animate: true });
        }
    }

    next = (event) => {
        event.stopPropagation();
        if (this.props.data.tip.data.items.length > this.state.sliderIndex + 1) {
            this.setState({ sliderIndex: this.state.sliderIndex + 1, animate: true });
        }
    }

    finishedAnimation = () => {
        this.setState({ animate: false });
    }

    render() {

        let items =
            (this.props.data.tip &&
                this.props.data.tip.data &&
                this.props.data.tip.data.items) ? this.props.data.tip.data.items : [];
        return (
            <div onClick={this.onlyClose}
                className={(this.props.data.isvisible) ? 'gallery-tip-wrapper popup-open' : 'gallery-tip-wrapper popup-close'}>
                <div
                    style={{ boxShadow: 'unset' }}
                    className='gallery-tip-images-container'>
                    {
                        (items && items.length > this.state.sliderIndex) ?
                            <>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                    <CSSTransition
                                        in={this.state.animate}
                                        onEntered={this.finishedAnimation}
                                        timeout={300} classNames="quick-fade-in">
                                        <div className='tip-image-step' onClick={this.containerClick}>
                                            {
                                                items.map((item, index) => (
                                                    <div key={index}
                                                        style={{
                                                            width: (index === this.state.sliderIndex) ? '100%' : 0,
                                                            height: (index === this.state.sliderIndex) ? '100%' : 0
                                                        }}>
                                                        {
                                                            (item.type === 3 || item.type === 4) ?
                                                                <BasicVideoPlayer videoUrl={item.url} play={
                                                                    index === this.state.sliderIndex &&
                                                                    this.props.data.isvisible
                                                                } /> :
                                                                <div
                                                                    className='tip-gallery-image'
                                                                    style={{
                                                                        backgroundImage: 'url(\'' + item.url + '\')',
                                                                        backgroundSize: 'contain'
                                                                    }
                                                                    }
                                                                >
                                                                </div>
                                                        }
                                                    </div>
                                                ))
                                            }
                                            <>
                                                {
                                                    (this.state.sliderIndex > 0) ?
                                                        <div
                                                            style={{ opacity: '1 !important' }}
                                                            onClick={this.prev}
                                                            className='tip-gallery-image-arrow-wrapper tip-gallery-image-prev'>
                                                            <LeftArrowIcon opacity={1} color='white' />
                                                        </div> : null
                                                }
                                                {
                                                    (this.state.sliderIndex + 1 < items.length) ?
                                                        <div
                                                            style={{ opacity: '1 !important' }}
                                                            onClick={this.next}
                                                            className='tip-gallery-image-arrow-wrapper tip-gallery-image-next'>
                                                            <RightArrowIcon opacity={1} color='white' />
                                                        </div> : null
                                                }
                                            </>
                                        </div>
                                    </CSSTransition>
                                </div>
                            </> : null
                    }
                </div>
                <span
                    className='gallery-tip-close'
                    onClick={this.onlyClose}>
                    <CloseIcon opacity={1} color='white' />
                </span>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: state.gallerytip,
        translate: getTranslate(state.locale)
    };
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default
    withDisplayModeContext(
        withThemeContext(
            connect(mapStateToProps, matchDispatchToProps)(GalleryImagesTip)));
