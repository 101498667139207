import { OPEN_FINISH, CLOSE_FINISH_FORM, POST_STEP_FORM_SUCCESS, POST_SEND_PICTURE_SUCCESS } from './actionTypes';

export default function(state = { visible: false}, action) {

  switch(action.type)
  {
      case OPEN_FINISH:
        return { ...state, visible: true }
      case CLOSE_FINISH_FORM:
        return { ...state, visible: false }
      case POST_STEP_FORM_SUCCESS:
        return { ...state, visible: true}
      case POST_SEND_PICTURE_SUCCESS:
        return { ...state, visible: true}
      default:
        return { ...state }
  }
}
