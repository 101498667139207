import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/stepForm.scss';
import { getTranslate } from 'react-localize-redux';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { withThemeContext } from 'app/context/withThemeContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { submitForm } from './actions';

class Form extends Component {

  state = {
    fieldValues: {},
    invalidFields: [],
    value: '',
    terms: false,
    termsValid: true
  }

  componentDidMount() {
    if (!this.props.fields) {
      return;
    }
    var fieldValues = {};
    this.props.fields.forEach((field) => {
      fieldValues[field.name] = '';
    });
    this.setState({ fieldValues: fieldValues, terms: false, termsValid: true });
  }

  handleChange = (name, value) => {
    var fieldValues = this.state.fieldValues;
    var invalidFields = this.state.invalidFields;
    if(invalidFields.includes(name)) {
      invalidFields = invalidFields.filter(item => item !== name);
    }
    fieldValues[name] = value;
    this.setState({ fieldValues: fieldValues, invalidFields: invalidFields });
  }

  toggleTerms = () => {
    this.setState({ terms: !this.state.terms, termsValid: true });
  }

  submit = (e) => {
    e.preventDefault();
    var invalidFields = [];
    Object.entries(this.state.fieldValues).forEach(([fieldName, fieldValue]) => {
      if (fieldValue === '') {
        invalidFields.push(fieldName);
      }
    });
    if (this.props.themeContext.termsEnabled === "true" && !this.state.terms) {
      this.setState({ termsValid: false });
      invalidFields.push('termsValid');
    }
    if (invalidFields.length > 0) {
      this.setState({ invalidFields: invalidFields });
    } else {
      this.props.dispatch(submitForm(
        this.props.generalContext.appName,
        this.props.generalContext.guideId,
        this.props.step.stepId,
        this.props.generalContext.language,
        this.props.fields,
        this.state.fieldValues
      ));
      this.setState({ 
        fieldValues: {},
        terms: false,
        termsValid: true
      });
    }
  }

  render() {

    return (
      (this.props.fields && this.props.fields.length > 0) ?
        <div className={(this.props.addPadding) ? 'form-container form-container-padding direction' : 'form-container direction'}>
          <span
            className='form-title medium-title title-font'
            style={{ color: this.props.titleColor }}>
            {this.props.title}
          </span>
          <form onSubmit={this.submit}>
            {
              this.props.fields.map((item) => (
                <div className='form-field' key={item.name}>
                  <span
                    className='form-field-title'
                    style={{ color: this.props.titleColor }}>
                    {item.name}
                  </span>
                  <input
                    maxLength={500}
                    type={item.type}
                    className={(this.state.invalidFields.includes(item.name)) ?
                       "form-field-input form-field-input-invalid default-text" : "form-field-input default-text"}
                    onChange={(e) => this.handleChange(item.name, e.target.value)}
                    value={this.state.fieldValues[item.name] || ''} />
                </div>
              ))
            }
            {
              (this.props.themeContext.termsEnabled === "true") ?
                <div
                  className={(this.state.termsValid) ? "contact-terms-wrapper direction" : "contact-terms-wrapper contact-field-invalid direction"}
                  onClick={this.toggleTerms}>
                  <div className='contact-terms-checkbox'>
                    <input type="checkbox" id="terms" name="terms" onChange={this.toggleTerms} checked={this.state.terms} />
                  </div>
                  <span className='terms-checkbox__container'>
                    <span className='terms-checkbox__text'>
                    {
                      (this.props.termsCustomText && this.props.termsCustomText !== '') ?
                        this.props.termsCustomText : 
                        (this.props.generalContext && this.props.generalContext.language && this.props.generalContext.language === "jpn" ? '' : /*this.props.translate('termsText')*/ 'I agree to')
                    }
                    </span>
                    <a href={this.props.themeContext.termsUrl} target="_blank" rel="noopener noreferrer">
                      {this.props.generalContext && this.props.generalContext.language && this.props.generalContext.language === "jpn" ? 
                        this.props.translate('termsTextTermsAndConditions') : 
                        /*this.props.translate('termsAndConditions')*/ 'Terms of use'}
                    </a>
                  </span>
                </div> : null
            }
            <button
              type="submit"
              className="form-submit-button"
              style={{
                backgroundColor: this.props.themeContext.buttonBackgroundColor,
                color: this.props.themeContext.buttonTextColor
              }}>
              <span style={{
                textTransform: 'uppercase',
                color: this.props.themeContext.buttonTextColor
              }}>
                {
                  (this.props.stepForm.inProgress && this.props.stepForm.stepId === this.props.step.stepId) ?
                    <CircularProgress
                      variant="indeterminate"
                      disableShrink
                      color='inherit'
                      size={24}
                      thickness={4}
                    /> :
                    ( this.props.button || this.props.translate('send') )
                }
              </span>
            </button>
          </form>
          </div> : null
    );
  }
}

function mapStateToProps(state) {
  return {
    stepForm: state.stepForm,
    step: state.step,
    translate: getTranslate(state.locale)
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default
  withGeneralContext(
    withThemeContext(
      connect(mapStateToProps, matchDispatchToProps)(Form)));
