import axios from 'axios';
import { getToken } from 'app/helpers/authStorage';
import logerror from 'app/utils/logger';

export const POST_PREFIX = 'POST_';
export const FETCH_PREFIX = 'FETCH_';
export const ACTION_INPROGRESS = '_INPROGRESS';
export const ACTION_SUCCESS = '_SUCCESS';
export const ACTION_FAIL = '_FAIL';

export const apiRequestMiddleware = store => next => action => {
    if (action.type && action.type.startsWith(FETCH_PREFIX) && 
        action.payload && action.payload.url) {

        let projectStore = store.getState().project;
        
        let headers = {} 
        if(!action.noAuthorization) {
            let applicationId = (projectStore) ? projectStore.applicationId: '';
            headers = {'Authorization':'Bearer ' + getToken(applicationId)}
        }

        next({ type: action.type + ACTION_INPROGRESS })
        axios.get(action.payload.url, {headers: headers})
            .then(
                response => {
                if(response.status >= 400) {
                  throw new Error("bad response");
                }
                return response.data;
              }
            )
            .then(json => {
                next({ type: action.type + ACTION_SUCCESS, payload: json })
            })
            .catch(err => {
                if(shouldThrowException(action.payload.url)) {
                  console.log(err);
                  logerror(err);
                }
                next({ type: action.type + ACTION_FAIL, payload: err })
            })
    }

    if (action.type && action.type.startsWith(POST_PREFIX) && 
        action.payload && action.payload.url) {

        let projectStore = store.getState().project;
        let applicationId = (projectStore) ? projectStore.applicationId: '';

        let data = action.payload.data;
        let headers = {};
        if(action.payload.postType === 'json') {
          headers = {
            'Accept': 'application/json',
            'Content-Type': (action.multipart) ? 'multipart/form-data' : 'application/json',
            'Authorization':'Bearer ' + getToken(applicationId)
          }
        }
        else {
          headers = {'Authorization':'Bearer ' + getToken(applicationId)}
        }
        next({ type: action.type + ACTION_INPROGRESS })
        axios.post(action.payload.url, data, { headers: headers })
            .then(
                response => {
                if(response.status >= 400) {
                  throw new Error("bad response");
                }
                return response.data;
              }
            )
            .then(json => {
                next({ type: action.type + ACTION_SUCCESS, payload: json })
            })
            .catch(err => {
                if(shouldThrowException(action.payload.url)) {
                  console.log(err);
                  logerror(err);
                }
                next({ type: action.type + ACTION_FAIL, payload: err })
            })
    }

    return next(action)
}

function shouldThrowException(url) {
  return true;
}