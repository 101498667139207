import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { closeTipAction } from './actions';
import { OnlyCloseTipAction } from './actions'
import ProjectAnalyticsHelper from 'app/helpers/projectAnalyticsHelper';
import 'assets/styles/tip.scss';

class TextTip extends Component {

    state = {
        opened: false
    }

    componentDidUpdate(prevProps) {

        if (prevProps.texttip.isvisible === false &&
            this.props.texttip.isvisible === true) {
            ProjectAnalyticsHelper.startRecordingActivity(0, this.props.texttip.tipId, 0);
            this.setState({ opened: true });
        }
        if (prevProps.texttip.isvisible === true &&
            this.props.texttip.isvisible === false) {
            this.setState({ opened: false });
        }
    }

    OnlyClose = () => {
        ProjectAnalyticsHelper.stopRecordingActivity();
        this.props.dispatch(OnlyCloseTipAction());
    }

    close = () => {
        ProjectAnalyticsHelper.stopRecordingActivity();
        this.props.dispatch(closeTipAction());
    }

    render() {
        return (
            <div
                className={(this.state.opened) ? 'text-tip-container popup-open': 'text-tip-container popup-close'}
                onClick={this.OnlyClose}>
                <div
                    style={{
                        backgroundColor: this.props.themeContext.popupBackgroundColor,
                        color: this.props.themeContext.popupTextColor
                    }}
                    className={'text-tip-content direction'}>
                    {this.props.texttip.text}
                </div>
            </div>
    )
    }
}

function mapStateToProps(state) {
    return {
        texttip: state.texttip
    };
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default withThemeContext(connect(mapStateToProps, matchDispatchToProps)(TextTip));
