import { CLOSE_GALLERY_TIP, OPEN_GALLERY_TIP, UPDATE_CURRENT_STEP, NAVIGATE_BACK, ONLY_CLOSE_GALLERY_TIP } from './actionTypes';

  export default function(state = { isvisible: false }, action) {

    switch(action.type)
    {
        case OPEN_GALLERY_TIP:
          return { ...state, isvisible: true, tip: action.payload, tipId: action.tipId }
        case UPDATE_CURRENT_STEP:
        case NAVIGATE_BACK:
        case CLOSE_GALLERY_TIP:
          return { ...state, isvisible: false }
        case ONLY_CLOSE_GALLERY_TIP:
          return { ...state, isvisible: false }
        default:
          return { ...state }
    }
  }
