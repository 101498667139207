import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/gallery.scss';
import ImageZoom from 'react-medium-image-zoom';

class GalleryItem extends Component {

  videoClick = (event) => {
    let video = event.target.parentElement.children[0];
    let img = event.target.parentElement.children[1];
    let allImg = document.querySelectorAll('.playImg');
    let allVideo = document.querySelectorAll('.video-gallery');
    allImg.forEach(function(image){
      image.style.visibility = "visible";
    });

    if (video.paused === false) {
      video.pause();
      img.style.visibility = "visible";
    } else {
      img.style.visibility = "hidden";
      allVideo.forEach(function(videoGallery){
        videoGallery.pause()
      });
      video.play();
    }
  }

  render() {
    return (
        (this.props.item.type === "Video") ?
        <a onClick={this.videoClick}>
          <div className='video-container gallery-item'>
            <video loop className='video-gallery'>
                <source src={` ${this.props.item.imageUrl}#t=0.001 `} type="video/mp4"/>
            </video>
            <div className='playImg'></div>
          </div>
        </a> : 
        <ImageZoom
        image={{
          src: this.props.item.imageUrl,
          alt: this.props.item.projectName,
          className: 'gallery-item'
        }}
        zoomImage={{
          src: this.props.item.imageUrl.replace('_thumb', ''),
          alt: this.props.item.projectName
        }}
      />
      
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, matchDispatchToProps)(GalleryItem);