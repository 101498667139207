import React, { Component } from 'react';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { withThemeContext } from 'app/context/withThemeContext';

class StepContentLogo extends Component {

  render() {

    return (
      (!this.props.displayModeContext.portrait &&
        this.props.themeContext.stepsLogoEnabled === "true" &&
        this.props.themeContext.logo !== '') ?
        <a href={this.props.themeContext.logoUrl} target="_blank" rel="noopener noreferrer">
          <img className='step-content-logo' src={this.props.themeContext.logo} alt="" />
        </a> : null
    );
  }
}

export default withDisplayModeContext(withThemeContext(StepContentLogo));
