import { FETCH_PREFIX, ACTION_INPROGRESS, ACTION_SUCCESS, ACTION_FAIL, POST_PREFIX } from 'app/middleware/apiRequestMiddleware';

export const FETCH_GALLERY_DATA = FETCH_PREFIX + 'GALLERY_DATA';
export const FETCH_GALLERY_DATA_INPROGRESS = FETCH_GALLERY_DATA + ACTION_INPROGRESS;
export const FETCH_GALLERY_DATA_SUCCESS = FETCH_GALLERY_DATA + ACTION_SUCCESS;
export const FETCH_GALLERY_DATA_FAIL = FETCH_GALLERY_DATA + ACTION_FAIL;

export const POST_GALLERY_EMODJI_DATA = POST_PREFIX + 'GALLERY_EMODJI_DATA';
export const POST_GALLERY_EMODJI_DATA_INPROGRESS = POST_GALLERY_EMODJI_DATA + ACTION_INPROGRESS;
export const POST_GALLERY_EMODJI_DATA_SUCCESS = POST_GALLERY_EMODJI_DATA + ACTION_SUCCESS;
export const POST_GALLERY_EMODJI_DATA_FAIL = POST_GALLERY_EMODJI_DATA + ACTION_FAIL;