import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { LeftArrowIcon } from 'app/components/svg/LeftArrowIcon';
import { LargeContactIcon } from 'app/components/svg/LargeContactIcon';
import { LanguagesIcon } from 'app/components/svg/LanguagesIcon';
import { CSSTransition } from 'react-transition-group';
import LanguagesMenu from 'app/components/controls/languagesMenu/LanguagesMenu';
import { openContactForm } from './actions';
import { isInIframe } from 'app/utils/utils';
import 'assets/styles/topbar.scss';

class Topbar extends Component {

    state = {
        isLanguagesMenuOpened: false,
        languagesMenuAnchor: null,
        isInIframe: isInIframe()
    }

    openLanguagesMenu = (open, event) => {
        if (event) {
            this.setState({ languagesMenuAnchor: event.target });
        }
        this.setState({ isLanguagesMenuOpened: open });
    }

    changeLanguage = () => {
        this.setState({ isLanguagesMenuOpened: false });
    }

    openContactForm = () => {
        this.props.dispatch(openContactForm());
    }

    openCustomBackUrl = () => {
        window.open(this.props.customBackUrl, '_blank');
    }

    render() {

        return (
            <>
                <div
                    className={(this.props.sticky) ? 'topbar-container-sticky' : 'topbar-container'}
                    style={{ background: this.props.themeContext.webappHeadaer ? this.props.themeContext.webappHeadaer : this.props.themeContext.defaultBackgroundColor}}>
                    <div className={this.state.rtl ? 'topbar-left-icons dir__rtl' : 'topbar-left-icons'}>
                        <CSSTransition in={this.props.backEnabled} timeout={500} classNames="fade-in-out">
                            <div className='topbar-icon topbar-icon-padding' onClick={this.props.back}
                                style={(this.props.backEnabled) ? {} : { width: 0, height: 0, padding: 0, display: 'none' }}>
                                <LeftArrowIcon color={this.props.themeContext.defaultTextColor} opacity={1} />
                            </div>
                        </CSSTransition>
                        {
                            (!this.props.backEnabled && this.props.customBackTitle) ?
                                <div className='topbar-icon topbar-icon-padding' onClick={this.openCustomBackUrl}
                                    style={(!this.props.backEnabled && this.props.customBackTitle) ? {} : { width: 0, height: 0, padding: 0 }}>
                                    <LeftArrowIcon color={this.props.themeContext.defaultTextColor} opacity={1} />
                                    {
                                        (!this.props.displayModeContext.portrait) ?
                                            <span className='small-title title-font' style={{ paddingLeft: 10 }}>{this.props.customBackTitle}</span> : null
                                    }
                                </div> : null
                        }
                    </div>
                    {
                        ((this.props.themeContext.webAppLogoOnTop === undefined || this.props.themeContext.webAppLogoOnTop !== 'false' && !this.state.isInIframe)) ?
                            <a href={this.props.themeContext.logoUrl} target="_blank" rel="noopener noreferrer">
                                <img className='topbar-logo' src={this.props.themeContext.logo} alt="" />
                            </a> : null
                    }
                    <div className='topbar-right-icons'>
                        {(this.props.themeContext.hideContactForm === "false") ?
                        <div className='topbar-icon topbar-contact-icon topbar-icon-padding'
                            style={{ width: 22, paddingBottom: 5 }}
                            onClick={this.openContactForm}>
                            <LargeContactIcon color={this.props.themeContext.defaultTextColor} />
                        </div> : null
                        }
                        {
                            (this.props.themeContext.hideLanguages === "false" || this.props.generalContext.customLanguages !== '') ?
                                <div className='topbar-icon' onClick={(event) => this.openLanguagesMenu(true, event)}
                                    style={(this.props.generalContext.languages && this.props.generalContext.languages.length > 0) ?
                                        {} : { width: 0, height: 0 }}>
                                    <LanguagesIcon color={this.props.themeContext.defaultTextColor} />
                                </div> : null
                        }
                    </div>
                </div>
                <LanguagesMenu
                    isOpened={this.state.isLanguagesMenuOpened}
                    openLanguagesMenu={this.openLanguagesMenu}
                    anchor={this.state.languagesMenuAnchor} />
            </>
        );
    }
}


function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default
    withDisplayModeContext(
        withGeneralContext(
            withThemeContext(
                connect(null, matchDispatchToProps)(Topbar))));
