import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { LargeContactIcon } from 'app/components/svg/LargeContactIcon';
import { openContactForm } from './actions';
import 'assets/styles/home.scss';

class ContactButton extends Component {

    openContactForm = () => {
        this.props.dispatch(openContactForm());
    }

    render() {
        return (
            (this.props.themeContext.hideContactForm === "false") ?
            <div
                onClick={this.openContactForm}
                className='home-contact-button'
                style={{ backgroundColor: this.props.themeContext.buttonBackgroundColor }}>
                <div className='home-contact-button-icon-wrapper'>
                    <LargeContactIcon color={this.props.themeContext.buttonTextColor} />
                </div>
                
            </div> : null
        );
    }
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default withThemeContext(connect(null, matchDispatchToProps)(ContactButton));