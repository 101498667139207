import React from 'react';

export const SmallLanguagesIcon = (props) =>(
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g fill="none" fillRule="evenodd" stroke={props.color} strokeWidth="2" opacity=".4" transform="translate(1 1)">
        <circle cx="7.5" cy="7.5" r="7.5"/>
        <path d="M7 15c2.21 0 4-3.358 4-7.5C11 3.358 9.21 0 7 0"/>
        <path d="M8 15c-2.21 0-4-3.358-4-7.5C4 3.358 5.79 0 8 0"/>
        <path strokeLinecap="square" strokeLinejoin="round" d="M.5 6.5c3.073-1 5.406-1.5 7-1.5 1.594 0 3.927.5 7 1.5M.5 9c3.073 1 5.406 1.5 7 1.5 1.594 0 3.927-.5 7-1.5"/>
    </g>
</svg>

);