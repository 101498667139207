import { BEGINNING_SLIDE, END_SLIDE, SLIDE, STEP_UPDATE_DATA, UPDATE_CURRENT_STEP } from './actionTypes';
import { SLIDES_INTERACTION_ENABLED, SLIDES_INTERACTION_DISABLED, NAVIGATE_BACK } from './actionTypes';
import { OPEN_FINISH } from './actionTypes';
import { CLOSE_FINISH_FORM } from './actionTypes';

export const beginningSlideAction = () => {
  return {
    type: BEGINNING_SLIDE
  }
}

export const endSlideAction = (appId, projectId) => {
  return {
    type: END_SLIDE,
    appId: appId,
    projectId: projectId
  }
}

export const slideAction = (appId, projectId) => {
  return {
    type: SLIDE,
    appId: appId,
    projectId: projectId
  }
}

export const backAction = (splitStepId) => {
  return {
    type: NAVIGATE_BACK,
    splitStepId: splitStepId
  }
}

export const updateStepDataAction = (
    stepId, stepIndex, stepType, stepGroup, 
    total, totalStepsInGuide, hideEnd, stepTitle, stepText, hideStepContent, isStepTextDominant) => {
  return {
    type: STEP_UPDATE_DATA,
    stepId: stepId,
    stepIndex: stepIndex,
    stepType: stepType,
    stepGroup: stepGroup,
    total: total,
    totalStepsInGuide: totalStepsInGuide,
    hideEnd: hideEnd,
    stepTitle: stepTitle,
    stepText: stepText,
    hideStepContent: hideStepContent,
    isStepTextDominant: isStepTextDominant
  }
}

export const enableInteractionAction = () => {
  return {
      type: SLIDES_INTERACTION_ENABLED
  }
}

export const disableInteractionAction = () => {
  return {
      type: SLIDES_INTERACTION_DISABLED
  }
}

export const updateStepAction = (stepId) => {
  return {
    type: UPDATE_CURRENT_STEP,
    stepId: stepId
  };
}

export const openFinishAction = () => {
  return {
    type: OPEN_FINISH
  }
}

export const closeFinishForm = () => {
  return {
    type: CLOSE_FINISH_FORM
  }
}

export function beginningSlide() {
    return beginningSlideAction();
}

export function endSlide(appId, projectId) {
    return endSlideAction(appId, projectId);
}

export function slide(appId, projectId) {
    return slideAction(appId, projectId);
}

export function updateStepData(
    stepId, stepIndex, stepType, stepGroup, total,
    totalStepsInGuide, hideEnd, stepTitle, stepText, extraData, isStepTextDominant) {
  let hideStepContent = extraData && 
                       (extraData.extraDataMaterials || 
                       (extraData.extraDataQuestion && extraData.extraDataQuestion.text !== ''));
  return updateStepDataAction(
    stepId, stepIndex, stepType, stepGroup, total, totalStepsInGuide, hideEnd, stepTitle, stepText, hideStepContent, isStepTextDominant);
}

export function enableInteraction() {
  return enableInteractionAction()
}

export function disableInteraction() {
  return disableInteractionAction()
}

export function updateStep(stepId) {
  return function (dispatch) {
      dispatch(updateStepAction(stepId));
  }
}

export function navigateBack(splitStepId) {
  return backAction(splitStepId);
}

export function openFinish() {
    return openFinishAction();
}