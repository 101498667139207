import React, { Component } from 'react';
import { SearchIcon } from 'app/components/svg/SearchIcon';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CircularProgress from '@material-ui/core/CircularProgress';

class SearchNavigation extends Component {

    render() {

        return (
            <TransitionGroup>
                <div className='nav-search'>
                    <div className='nav-search__container'>
                        <input 
                            className="form-field-input default-text nav-search__input"
                            type = "search" 
                            placeholder = {this.props.placeholder || "Search for product/guide"}
                            onChange = {this.props.handleChange}
                            disabled = {(this.props.loadingGuides) ? true : false}
                            style ={{cursor: (this.props.loadingGuides) ? 'progress' : 'auto'}}
                        />
                        {
                            (!this.props.loadingGuides) ?
                                <SearchIcon className='nav-search__icon'/> :
                                <CircularProgress
                                    className='nav-search__icon'
                                    variant="indeterminate"
                                    color='inherit'
                                    size={16}
                                    thickness={4}
                                />
                        }
                    </div>
                    {
                        this.props.noData ? 
                        <CSSTransition in={this.props.noData} timeout={300} classNames="fade-in-0">
                            <div className='nav-search__no-result'>
                                <div className='no-result__image'></div>
                                <div className='no-result__text'>No result found</div>
                            </div> 
                        </CSSTransition>
                        : '' 
                    }
                </div>
            </TransitionGroup>
        );
    }
}

export default SearchNavigation;