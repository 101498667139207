import { FETCH_PREFIX, ACTION_INPROGRESS, ACTION_SUCCESS, ACTION_FAIL } from 'app/middleware/apiRequestMiddleware';
export { STEP_UPDATE_DATA } from 'app/components/main/instructions/actionTypes';
export { NAVIGATE_BACK } from 'app/middleware/guideNavigationMiddleware';
export { OPEN_SINGLE_GUIDE } from 'app/middleware/navigationMiddleware';

export const INIT = 'INIT';
export const START_GUIDE = 'START_GUIDE';
export const FETCH_PROJECT_DATA = FETCH_PREFIX + 'PROJECT_DATA';
export const SAVE_BACK_DATA = 'SAVE_BACK_DATA';

export const FETCH_PROJECT_DATA_INPROGRESS = FETCH_PROJECT_DATA + ACTION_INPROGRESS;
export const FETCH_PROJECT_DATA_SUCCESS = FETCH_PROJECT_DATA + ACTION_SUCCESS;
export const FETCH_PROJECT_DATA_FAIL = FETCH_PROJECT_DATA + ACTION_FAIL;

export const UPDATE_STEPS_RANGE = "UPDATE_STEPS_RANGE";
