import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { RightArrowIcon } from 'app/components/svg/RightArrowIcon';
import { getTranslate } from 'react-localize-redux';
import 'assets/styles/guideNavigation.scss';

class NextButton extends Component {

    render() {

        return (
            <button
                onClick={this.props.click}
                className={(!this.props.displayModeContext.portrait) ?
                    "navigation-next-button" : "navigation-next-button navigation-next-button-small"}
                style={{
                    backgroundColor: this.props.themeContext.buttonBackgroundColor,
                    color: this.props.themeContext.buttonTextColor,
                    visibility: (this.props.visible) ? '' : 'hidden'
                }}>
                <div className='navigation-next-button-content'>
                    {
                        (!this.props.displayModeContext.portrait) ?
                            <span style={{ paddingRight: 9, textTransform: 'uppercase' }}>
                                {this.props.translate('next')}
                            </span> : null
                    }
                    <RightArrowIcon color={this.props.themeContext.buttonTextColor} />
                </div>
            </button>
        );
    }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

export default connect(mapStateToProps)(
    withDisplayModeContext(withGeneralContext(withThemeContext(NextButton))));
