import {POST_UPLOAD_PHOTO, POST_UPLOAD_PHOTO_SEND_PIC, CLEAN_TAKE_PHOTO_STATE, PROCESSING_IMAGE, UPLOAD_PHOTO_SEND_PIC} from './actionTypes';
import {UPLOAD_PHOTO_URL, UPLOAD_PHOTO_VIDEO_URL} from './services';

export const uploadPhotoAction = (url, data, localimage) => {
  return {
    type: POST_UPLOAD_PHOTO,
    payload: { url: url, data: data },
    localimage: localimage,
    multipart: true
  }
}

export const uploadPhotoVideoAction = (url, data, localimage) => {
  return {
    type: POST_UPLOAD_PHOTO,
    payload: { url: url, data: data },
    localimage: localimage,
    multipart: true
  }
}

export const uploadSendPicAction = (url, data, localimage) => {
  return {
    type: POST_UPLOAD_PHOTO_SEND_PIC,
    payload: { url: url, data: data },
    localimage: localimage,
    multipart: true
  }
}

export const cleanAction = () => {
  return {
    type: CLEAN_TAKE_PHOTO_STATE
  }
}

export const processingAction = () => {
  return {
    type: PROCESSING_IMAGE
  }
}

export const submitTakePhotoAction = (data) => {
  return {
    type: UPLOAD_PHOTO_SEND_PIC,
    data: data
  }
}

export function submitTakePhoto(appid, language, projectId, stepid, email, question, attachment, attachmentName) {

  var data = {
    email: email,
    text: question,
    applicationId: appid,
    language: language,
    projectId: projectId,
    stepId: stepid,
    attachment: attachment,
    attachmentName: attachmentName
  };
  
  return submitTakePhotoAction(data);
}

export function uploadPhoto(applicationId, projectId, data, localimage) {

    var jsonRequest = {
      applicationId: applicationId,
      projectId: projectId
    };

    var params = new FormData();
    params.append('json', JSON.stringify(jsonRequest));
    params.append('image', data);

    return uploadPhotoAction(UPLOAD_PHOTO_URL, params, localimage);
}

export function uploadPhotoVideo(applicationId, projectId, data, localimage) {

    var jsonRequest = {
      applicationId: applicationId,
      projectId: projectId
    };

    var params = new FormData();
    params.append('json', JSON.stringify(jsonRequest));
    params.append('image', data);
    params.append('thumbData', 'https://d1xhvexl2i3ere.cloudfront.net/vayyar/res/2aaa08bb-151e-458d-a6fe-5a862899dfc0.jpg');

  return uploadPhotoVideoAction(UPLOAD_PHOTO_VIDEO_URL, params, localimage);
}

export function uploadSendPic(applicationId, projectId, data, localimage) {

  var jsonRequest = {
    applicationId: applicationId,
    projectId: projectId
  };

  var params = new FormData();
  params.append('json', JSON.stringify(jsonRequest));
  params.append('image', data);

  return uploadSendPicAction(UPLOAD_PHOTO_URL, params, localimage);
}

export function processing() {
    return processingAction();
}

export function clean() {
    return cleanAction();
}
