import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateStep } from './actions';
import 'assets/styles/progressbar.scss';

const withProgressBar = (WrappedProgressbarComponent) => {

    class ProgressBarBase extends Component {

        padding = 20;

        state = {
            value: 0,
            tempStepId: null,
            inProgress: false
        }

        componentDidMount() {
            let initialValue = 100 * (this.props.stepInfo.stepIndex) / this.props.stepInfo.total;
            this.setState({
                value: initialValue
            });
        }

        componentDidUpdate(prevProps) {

            if (prevProps.stepInfo.stepIndex !== this.props.stepInfo.stepIndex ||
                prevProps.stepInfo.total !== this.props.stepInfo.total) {
                if (this.props.stepInfo.stepIndex <= 0) {
                    this.setState({ value: 0 });
                }
                else if (!this.state.inProgress) {
                    let newValue = 100 * (this.props.stepInfo.stepIndex) / this.props.stepInfo.total;
                    this.setState({
                        value: newValue
                    });
                }
            }
        }

        onMouseMove = (event) => {
            if (event && this.state.inProgress) {
                let percentage = (event.pageX - event.target.getBoundingClientRect().left - this.padding) / this.getProgressbarWidth() * 100;
                let newStepId = parseInt((this.props.stepInfo.total / 100) * percentage, 10);
                if (newStepId < 1) {
                    newStepId = 1;
                }
                if (newStepId > this.props.stepInfo.total) {
                    newStepId = this.props.stepInfo.total;
                }
                this.setState({
                    value: percentage,
                    tempStepId: newStepId
                });
            }
        }

        onMouseMoveDown = (event) => {
            let percentage = (event.pageX - event.target.getBoundingClientRect().left - this.padding) / this.getProgressbarWidth() * 100;
            let newStepId = parseInt((this.props.stepInfo.total / 100) * percentage, 10);
            if (newStepId < 1) {
                newStepId = 1;
            }
            if (newStepId > this.props.stepInfo.total) {
                newStepId = this.props.stepInfo.total;
            }
            this.setState({ inProgress: true, tempStepId: newStepId });
        }

        onMouseMoveEnd = (event) => {
            if (event) {
                if (this.state.tempStepId !== this.props.stepInfo.stepId) {
                    this.props.dispatch(updateStep(this.state.tempStepId - 1));
                }
            }
            this.setState({ inProgress: false, tempStepId: null });
        }

        onTouchMove = (event) => {
            if (event.touches[0]) {
                var percentage = (event.touches[0].clientX - this.padding - this.progressBar.getBoundingClientRect().left) / this.getProgressbarWidth() * 100;
                if (percentage > 100) {
                    percentage = 100;
                }
                if (percentage < 0) {
                    percentage = 0;
                }
                let newStepId = parseInt((this.props.stepInfo.total / 100) * percentage, 10);
                this.setState({
                    value: percentage,
                    tempStepId: newStepId
                });
            }
        }

        onTouchStart = (event) => {
            let newStepId = null;
            if (event.touches[0]) {
                var percentage = (event.touches[0].clientX - this.padding - this.progressBar.getBoundingClientRect().left) / this.getProgressbarWidth() * 100;
                if (percentage > 100) {
                    percentage = 100;
                }
                if (percentage < 0) {
                    percentage = 0;
                }
                newStepId = parseInt((this.props.stepInfo.total / 100) * percentage, 10);
            }
            this.setState({ inProgress: true, tempStepId: newStepId });
        }

        getProgressbarWidth = () => {
            return this.progressBar.clientWidth - this.padding;
        }

        onTouchEnd = (event) => {
            if (event.changedTouches[0]) {
                if (this.state.tempStepId !== this.props.stepInfo.stepId) {
                    this.props.dispatch(updateStep(this.state.tempStepId - 1));
                }
                this.setState({ inProgress: false, tempStepId: null });
            }
        }

        render() {

            return (
                <div onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onTouchEnd}
                    onTouchMove={this.onTouchMove}
                    onMouseDown={this.onMouseMoveDown}
                    onMouseMove={this.onMouseMove}
                    onMouseUp={this.onMouseMoveEnd}>
                    <div ref={(progressBar) => { this.progressBar = progressBar }}>
                        <WrappedProgressbarComponent
                            progressValue={this.state.value}
                            inProgress={this.state.inProgress}
                            tempStepId={this.state.tempStepId}
                            showPercentage={this.props.showPercentage}>
                        </WrappedProgressbarComponent>
                    </div>
                </div>
            );
        }
    }
    return connect(mapStateToProps, matchDispatchToProps)(ProgressBarBase);
};

function mapStateToProps(state) {
    return {
        stepInfo: state.step
    };
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default withProgressBar;
