import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { getSectionData, navigateToGuide, navigateToReferalSection } from './actions';
import Topbar from 'app/components/home/components/topbar/Topbar';
import Banner from 'app/components/home/components/banner/Banner';
import GuidesNavigation from 'app/components/home/components/guidesNavigation/GuidesNavigation';
import MultipartGuidesNavigation from 'app/components/home/components/multipartGuidesNavigation/MultipartGuidesNavigation';
import Footer from 'app/components/home/components/footer/Footer';
import { getQueryVariable, queryVariableExists } from 'app/utils/utils';
import ContactButton from 'app/components/home/components/contactButton/ContactButton';
import ContactForm from 'app/components/controls/contactForm/ContactForm';
import PoweredBy from "../components/poweredBy/PoweredBy";
import Gallery from 'app/components/home/components/gallery/Gallery';
import SearchNavigation from '../components/searchNavigation/SearchNavigation'

class Section extends Component {

  state = {
    ref: '',
    backEnabled: false,
    search: [],
    searchValue: false,
    noData: false,
  }

  componentDidMount() {
    this.props.dispatch(getSectionData(
      this.props.generalContext.appName,
      this.props.generalContext.language,
      this.props.sectionId));
    this.setState({
      ref: getQueryVariable('ref'),
      backEnabled: queryVariableExists('ref')
    });
  }

  onGuideClick = (id) => {
    this.props.dispatch(navigateToGuide(id, this.props.sectionId, this.state.ref));
  }

  navigateToReferalSection = () => {
    this.props.dispatch(navigateToReferalSection(this.state.ref));
  }

  handleChange = e => {
    const currentValue = e.target.value.toLowerCase();
    currentValue ? this.setState({searchValue: true}) : this.setState({searchValue: false}); 
    const filterGuides = this.props.section.guides.filter(item => item.projectName.toLowerCase().includes(currentValue));
    filterGuides.length === 0 ? this.setState({noData: true}) : this.setState({noData: false}); 
    this.setState({
      search: filterGuides,
    });
  };
  
  render() {
    if (this.props.section.loading || this.props.section.error) {
      return (
        <div id="mpreload">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )
    }

    if(this.props.themeContext.hideContent !== undefined && this.props.themeContext.hideContent === 'true' ){
      return (
        <div></div>
      );
    }
    
    return (
      <div className={'section ' + this.props.generalContext.appName} style={{ position: (this.props.contactForm.visible) ? 'fixed' : '', width: '100%', height: '100%' }}>
        <Topbar
          backEnabled={this.state.backEnabled}
          customBackTitle={this.props.section.app.customBackTitle}
          customBackUrl={this.props.section.app.customBackUrl}
          back={this.navigateToReferalSection}
          sticky={true} />
        <Banner
          imageUrl={this.props.section.sectionImageUrl}
          title={this.props.section.sectionName}
          subtitle={""}
          underTopbar={true}
          themeContext={this.props.themeContext} />
        {
          ((this.props.themeContext.showSearch !== undefined) ? this.props.themeContext.showSearch === 'true' : this.props.section.app.showSearch) ? 
          <SearchNavigation 
            handleChange={this.handleChange} 
            noData={this.state.noData}
            placeholder={this.props.section.app.placeholderSearch}
          /> : ''
        }
        <PoweredBy isVisible={(this.props.themeContext.poweredMakeree !== undefined) ? this.props.themeContext.poweredMakeree : this.props.section.app.isPoweredByVisible}/>
        {
          (!this.props.section.isMultipart) ?
            <GuidesNavigation
            guides={this.state.searchValue ? this.state.search : this.props.section.guides}
            onItemClick={this.onGuideClick} /> :
            <MultipartGuidesNavigation
              guides={this.props.section.guides}
              onItemClick={this.onGuideClick} />
        }
        <ContactButton />
        <ContactForm guideId={''} />
        <Gallery sectionId={this.props.sectionId}/>
        <Footer
          companyName={this.props.section.app.companyName} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    section: state.section,
    app: state.app,
    contactForm: state.contactForm
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default
  withGeneralContext(
    connect(mapStateToProps, matchDispatchToProps)(
      withThemeContext(Section)));