import { POST_STEP_FORM, POST_STEP_FORM_INPROGRESS, POST_STEP_FORM_SUCCESS, POST_STEP_FORM_FAIL} from './actionTypes';

export default function(state = { inProgress: false }, action) {

  switch(action.type)
  {
      case POST_STEP_FORM:
        return { ...state, stepId: action.payload.data.stepId }
      case POST_STEP_FORM_INPROGRESS:
        return { ...state, inProgress: true }
      case POST_STEP_FORM_SUCCESS:
        return { ...state, inProgress: false }
      case POST_STEP_FORM_FAIL:
        return { ...state, inProgress: false }
      default:
        return { ...state }
  }
}
