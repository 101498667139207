import React from "react";

export const EmptyData = (props) => (
  <svg
    width="100%"
    height="100%"
    maxWidth="432"
    maxHeight="402"
    viewBox="0 0 132 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <g filter="url(#filter0_d_267_3762)">
      <path
        d="M99.4884 86L106 30.6532C106 27.3874 103.339 24.7404 100.056 24.7404H57.7454C55.726 24.7404 53.8442 23.7202 52.7498 22.0322L50.5949 18.7082C49.5005 17.0202 47.6187 16 45.5994 16H31.9442C28.661 16 26 18.6469 26 21.9127L32.5116 85.999H99.4884V86Z"
        fill="url(#paint0_linear_267_3762)"
      />
      <path
        d="M115.759 46.6101L108.07 78.9329C107.083 83.0793 103.435 86 99.2423 86H32.758C28.565 86 24.9172 83.0793 23.9304 78.9329L16.241 46.6101C14.9546 41.2043 18.9797 36 24.4456 36H107.555C113.021 36 117.045 41.2054 115.759 46.6101Z"
        fill="url(#paint1_linear_267_3762)"
      />
      <path
        d="M75.3845 72.6L82 79M79.8667 61.9333C79.8667 70.1808 73.1808 76.8667 64.9333 76.8667C56.6859 76.8667 50 70.1808 50 61.9333C50 53.6859 56.6859 47 64.9333 47C73.1808 47 79.8667 53.6859 79.8667 61.9333Z"
        stroke="#7A7272"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M61.0204 68L66.7448 62.5L69.4387 65.25M61.0204 68H67.755C68.8708 68 69.7754 67.0766 69.7754 65.9375V61.125M61.0204 68C59.9046 68 59 67.0766 59 65.9375V59.0625C59 57.9234 59.9046 57 61.0204 57H66.0714M67.5 57.5L68.5 58.5M68.5 58.5L69.5 57.5M68.5 58.5L67.5 59.5M68.5 58.5L69.5 59.5M63.0408 60.0938C63.0408 60.6633 62.5885 61.125 62.0306 61.125C61.4727 61.125 61.0204 60.6633 61.0204 60.0938C61.0204 59.5242 61.4727 59.0625 62.0306 59.0625C62.5885 59.0625 63.0408 59.5242 63.0408 60.0938Z"
        stroke="#E15454"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.0204 68L66.7448 62.5L69.4387 65.25M61.0204 68H67.755C68.8708 68 69.7754 67.0766 69.7754 65.9375V61.125M61.0204 68C59.9046 68 59 67.0766 59 65.9375V59.0625C59 57.9234 59.9046 57 61.0204 57H66.0714M67.5 57.5L68.5 58.5M68.5 58.5L69.5 57.5M68.5 58.5L67.5 59.5M68.5 58.5L69.5 59.5M63.0408 60.0938C63.0408 60.6633 62.5885 61.125 62.0306 61.125C61.4727 61.125 61.0204 60.6633 61.0204 60.0938C61.0204 59.5242 61.4727 59.0625 62.0306 59.0625C62.5885 59.0625 63.0408 59.5242 63.0408 60.0938Z"
        stroke="#E15454"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_267_3762"
        x="0"
        y="0"
        width="132"
        height="102"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.25098 0 0 0 0 0.164706 0 0 0 0 0.529412 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_267_3762"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_267_3762"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_267_3762"
        x1="65.8424"
        y1="16"
        x2="65.8317"
        y2="86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C4C4C4" stop-opacity="0.42" />
        <stop offset="1" stop-color="#C4C4C4" stop-opacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_267_3762"
        x1="65.9193"
        y1="36"
        x2="65.929"
        y2="86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9A9A9A" stop-opacity="0.4" />
        <stop offset="1" stop-color="#E3E3E3" />
      </linearGradient>
    </defs>
  </svg>
);
