import React, { Component } from 'react';
import { connect } from 'react-redux';
import { motion } from "framer-motion";
import { postGalleryData } from '../home/components/gallery/actions';
import { getFromStorage, saveToStorage} from 'app/utils/storage';

class HeartIcon extends Component {

    state={
        hearts: 0,
        clicked: true
    }
    
    componentDidMount() {
        this.setState({hearts: this.props.count})
    }
    

    addHearts = () => {        
        var storageKeyName = this.props.appname + '-emodji';
        var consentValue = getFromStorage(storageKeyName) ? JSON.parse(getFromStorage(storageKeyName)) : [];
        
        consentValue.forEach(resource => {
                    if(resource.resourceId === this.props.resourceid){
                        if(resource.hearts){
                            resource.hearts = 0;
                            this.setState({hearts: this.state.hearts - 1, clicked: true});
                        }else{
                            resource.hearts = 1;
                            this.setState({hearts: this.state.hearts + 1, clicked: false});
                        }
                    }
                })

        if(consentValue.findIndex(item => item.resourceId === this.props.resourceid) === -1){
            consentValue.push({'resourceId': this.props.resourceid, "hearts": 1});
            this.setState({hearts: this.state.hearts + 1, clicked: false});
        }

        saveToStorage(this.props.appname + '-emodji', JSON.stringify(consentValue));
        this.props.dispatch(postGalleryData(this.props.appname, this.props.resourceid, this.props.projectId, this.props.likes, this.state.clicked ? this.state.hearts + 1 : this.state.hearts - 1));
    }


    render() {

        return (
            <motion.div 
                whileHover={{ scale: 1.1 }} 
                whileTap={{ scale: 0.9 }} 
                className={!this.state.hearts ? 'heart-icon empty' : 'heart-icon'}
                onClick={this.addHearts}
            >
                <svg id='heart' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64">
                    <path fill="#F41E42" d="M64,32c0,17.7-14.3,32-32,32C14.3,64,0,49.7,0,32C0,14.3,14.3,0,32,0C49.7,0,64,14.3,64,32"/>
                    <path fill="#FFFFFF" d="M50.3,27c0-1-0.6-9.8-9.1-9.8c-8.5,0-9.2,6.6-9.2,6.6c0,0-0.7-6.6-9.2-6.6c-8.5,0-9.1,8.9-9.1,9.8
                    c0,9.8,12,18.8,18.3,22.3C38.3,45.8,50.3,36.8,50.3,27"/>
                </svg>
                <div className='count'>{this.state.hearts}</div>
            </motion.div>
        )
    }
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
  }
  
export default connect(null, matchDispatchToProps)(HeartIcon);