import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/message.scss';
import { getTranslate } from 'react-localize-redux';
import { closeMessage } from './actions';
import Snackbar from '@material-ui/core/Snackbar';

class Message extends Component {

  state = {
    isOpen: false,
    message: ''
  }

  handleClose = () => {
    this.props.dispatch(closeMessage());
    this.setState({ isOpen: false, message: '' });
  }

  componentDidUpdate() {
    if ((this.props.message.messageKeyword !== '' || 
         this.props.message.actualMessage !== '') && !this.state.isOpen) {
      this.setState({
        isOpen: true,
        message: (this.props.message.messageKeyword !== '') ?
          this.props.translate(this.props.message.messageKeyword) : 
          this.props.message.actualMessage
      });
    }
  }

  render() {
    return (
      <Snackbar
        open={this.state.isOpen && (
            this.props.message.messageKeyword !== '' ||
            this.props.message.actualMessage !== '')}
        ContentProps={{
            style: { 
              background: (!this.props.message.isError) ? '#3ed790' : '#fc0740', 
              minWidth: 'unset' 
            }
          }
        }
        onClick={this.handleClose}
        onClose={this.handleClose}
        autoHideDuration={(this.props.message.long) ? 30000 : 5000}
        severity={(this.props.message.isError) ? "error" : "success"}
        message={this.state.message}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    message: state.message
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, matchDispatchToProps)(Message);
