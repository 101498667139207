import React, { Component } from 'react';
// import Compressor from 'compressorjs';
import logerror from 'app/utils/logger';

class Camera extends Component {

  capture = (e) => {
    this.captureInput.click();
  }

  photoChange = (e) => {
    try {
      var fileInput = e.target.files;
      if(fileInput.length > 0) {
        this.props.processing();
        var reader = new FileReader();
        reader.onload = function(e) {
          this.props.finish(fileInput[0], e.target.result);
        }.bind(this);
        reader.readAsDataURL(fileInput[0]);
        //this.compressImage(fileInput[0]);
      }
      else {
        this.props.finish('', '');
      }
    } catch (e) {
      console.log(e);
      logerror(e);
      this.props.finish('', '');
    }
    return true;
  }

  /*compressImage = (file, localimage) => {

    new Compressor(file, {
      quality: 0.6,
      success: this.compressSuccess,
      error(err) {
        console.log(err);
        logerror(err);
        this.props.finish('', '');
      },
    });
  }

  compressSuccess = (result) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      this.props.finish(result, e.target.result);
    }.bind(this);
    reader.readAsDataURL(result);
  }*/

  render() {

    return (
        <div>
          <input
            ref={(captureInput) => {this.captureInput = captureInput }}
            type="file"
            onChange={this.photoChange}
            style={{display:'none'}}/>
        </div>
    );
  }
}

export default Camera;
