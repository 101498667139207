import React, { Component } from 'react';
import GuidePart from 'app/components/home/components/guidePart/GuidePart';
import 'assets/styles/navigation.scss';

class MultipartGuidesNavigation extends Component {

    render() {

        return (
            <div className='multipart-navigation'>
                {
                    (this.props.guides && this.props.guides.length > 0) ?
                        this.props.guides.map((item, index) => (
                            <GuidePart
                                key={item.projectId}
                                id={item.projectId}
                                part={index + 1}
                                title={item.projectName}
                                imageUrl={item.imageUrl}
                                time={item.time}
                                data={item.projectId}
                                onClick={this.props.onItemClick} />
                        )) : null
                }
            </div>
        );
    }
}


export default MultipartGuidesNavigation;