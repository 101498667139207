import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import { withThemeContext } from 'app/context/withThemeContext';
import { CSSTransition } from 'react-transition-group';
import { isValidEmail } from 'app/utils/utils';

class GalleryLeaveEmailContent extends Component {

    state = {
        animate: false,
        email: '',
        emailValid: true
    }

    

    componentDidUpdate(prevProps) {
        if (this.props.animationIndex !== prevProps.animationIndex) {
            this.setState({ animate: true, email: '', emailValid: true });
        }
    }

    validate = (event) => {
        event.preventDefault();

        var valid = true;
        if (this.state.email === '' || !isValidEmail(this.state.email)) {
          this.setState({ emailValid: false });
          valid = false;
        }
        if (!valid) {
          return;
        }
        this.props.close()
        
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value, emailValid: true });
    }

    finishedAnimation = () => {
        this.setState({ animate: false });
    }

    

    render() {

        return (
            <>
                <CSSTransition
                    in={this.state.animate}
                    onEntered={this.finishedAnimation}
                    timeout={300} classNames="fade-in">
                    <div className='gallery-tip-text-wrapper'>
                        <Scrollbar noScrollX>
                            <div className='gallery-tip-text'>
                                {this.props.text}
                                <span className='contact-field-title'>
                                    {this.props.translate('enterYourEmail')}
                                </span>
                                <input
                                    maxLength={150}
                                    style={{ width: '60%' }}
                                    type="email"
                                    className={this.state.emailValid ?
                                        "contact-field default-text" : "contact-field default-text contact-field-invalid"}
                                    onChange={this.handleEmailChange}
                                    value={this.state.email} />
                            </div>
                        </Scrollbar>
                    </div>
                </CSSTransition>
                {
                    <button
                        onClick={this.validate}
                        className={"default-main-button gallery-tip-single-button"}
                        style={{
                            backgroundColor: this.props.themeContext.buttonBackgroundColor,
                            color: this.props.themeContext.buttonTextColor
                        }}>
                        <span style={{
                            textTransform: 'uppercase',
                            color: this.props.themeContext.buttonTextColor
                        }}>
                            {this.props.translate('send')}
                        </span>
                    </button>
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

export default withThemeContext(connect(mapStateToProps)(GalleryLeaveEmailContent));