import { OPEN_CONTACT_FORM } from './actionTypes';

export const openContactFormAction = () => {
    return {
      type: OPEN_CONTACT_FORM
    }
}

export function openContactForm() {
    return openContactFormAction();
}