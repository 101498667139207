import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { withThemeContext } from 'app/context/withThemeContext';
import StepOverlays from './overlays/StepOverlays';
import StepContentText from './StepContentText';
import StepContentLogo from './StepContentLogo';
import Box from '@material-ui/core/Box';
import ReactResizeDetector from 'react-resize-detector';
import 'assets/styles/step.scss';
import { CSSTransition } from 'react-transition-group';

class StepImage extends Component {

  state = {
    contentWidth: 0,
    contentHeight: 0
  }

  onResize = (width, height) => {
    let contentWidth = (width > height / 3 * 4) ? height / 3 * 4 : width;
    let contentHeight = (height > width / 4 * 3) ? width / 4 * 3 : height;
    this.setState({ contentWidth, contentHeight });
  }

  contentTextExists = () => {
    return (this.props.text !== '' || (this.props.step.title !== undefined && this.props.step.title !== '' && !this.props.hideStepTitles) || 
      (this.props.extraData && this.props.extraData.extraDataForm));
  }

  render() {
    
    return (
      <div 
      className='step'  
      style={ (this.props.addToBackground) ? 
              {backgroundImage: "url(" + this.props.imageUrl + ")", backgroundPosition: 'center', backgroundSize: 'cover'} : 
              {background: '#ffffff'}
            }
      >
        <Box
          display="flex"
          flexDirection={(this.props.displayModeContext.portrait) ? "column" : "row"}
          style={{ height: 'calc(100% - 70px)', width: '100%' }}>
          <Box
            display="flex"
            flexGrow={1}
            style={{
              overflow: 'hidden',
              height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
              width: (this.props.displayModeContext.portrait) ? '' : (100 - parseFloat(this.props.themeContext.stepTextContentPercentage)) + '%',
              backgroundColor: (!this.props.addToBackground) ? this.props.themeContext.stepBackgroundColor : 'none',
              position: (this.props.displayModeContext.portrait) ? "" : "relative",
              zIndex: 2
            }}>
            {
                (!this.contentTextExists() && !this.props.addToBackground) ? <StepContentLogo /> : null
            }
             
            <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
            
            <CSSTransition in={this.props.isCurrentStep} timeout={300} classNames="quick-fade-in">
              
              <div
                className={(this.props.displayModeContext.portrait) ?
                  "step-image" : "step-image step-landscape"}
                style={(!this.props.addToBackground) ? 
                      {
                        backgroundImage: 'url(' + this.props.imageUrl + ')',
                        width: this.state.contentWidth,
                        height: this.state.contentHeight,
                        right: (this.props.displayModeContext.portrait || this.contentTextExists()) ? '' : 0,
                        left: (this.contentTextExists()) ? '' : 0,
                      } : 
                      {
                        backgroundImage: 'none',
                        width: '100%',
                        height: '100%',
                        right: (this.props.displayModeContext.portrait || this.contentTextExists()) ? '' : 0,
                        left: (this.contentTextExists()) ? '' : 0
                      }
                    }
              >
                  
                <StepOverlays
                  tips={this.props.tips}
                  stepId={this.props.stepId}
                  openAction={this.props.openAction} />
              </div>
              </CSSTransition>
            </ReactResizeDetector>
          </Box>
          {
            (this.contentTextExists() && this.props.text !== '-' || this.props.step.title !== '-') ?
              <>
                {(!this.props.addToBackground) ? <StepContentLogo /> : null }
                <Box
                  style={(!this.props.addToBackground) ? 
                          {
                            overflow: 'hidden',
                            backgroundColor: this.props.themeContext.stepTextBackgroundColor,
                            color: this.props.themeContext.stepTextColor,
                            height: (this.props.displayModeContext.portrait) ? '50%' : '100%',
                            width: (this.props.displayModeContext.portrait) ? '' : this.props.themeContext.stepTextContentPercentage + '%',
                            position: (this.props.displayModeContext.portrait) ? "" : "relative"
                          } : 
                          {
                            overflow: 'hidden',
                            backgroundColor: 'none',
                            color: this.props.themeContext.stepTextColor,
                            height: '100%',
                            width: '100%',
                            position: "absolute"
                          }
                        }
                >
                  <div
                    className={(this.props.displayModeContext.portrait) ? "" : "step-landscape"}
                    style={{
                      height: '100%',
                      width: '',
                      left: 0,
                      zIndex: 1
                    }}>
                    <StepContentText
                      addToBackground={this.props.addToBackground}
                      isCurrentStep={this.props.isCurrentStep}
                      stepId={this.props.stepId}
                      text={this.props.text !== '-' ? this.props.text : ''}
                      extraData={this.props.extraData}
                      contentTips={this.props.contentTips}
                      openAction={this.props.openAction} />
                  </div>
                </Box>
              </> : null
          }
        </Box>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    step: state.step,
    hideStepTitles: state.guide.data.hideStepTitles
  };
}

export default withDisplayModeContext(withThemeContext(connect(mapStateToProps)(StepImage)));
