import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { withDisplayModeContext } from 'app/context/withDisplayModeContext';
import { navigateToSection, navigateToMenu, navigateToGuide, navigateToUrl } from './actions';
import Topbar from 'app/components/home/components/topbar/Topbar';
import Banner from 'app/components/home/components/banner/Banner';
import Footer from 'app/components/home/components/footer/Footer';
import PrevGuide from 'app/components/home/components/prevGuide/PrevGuide';
import NextGuide from 'app/components/home/components/nextGuide/NextGuide';
import { getQueryVariable, queryVariableExists } from 'app/utils/utils';
import Guide from 'app/components/main/guide/Guide';
import ContactForm from 'app/components/controls/contactForm/ContactForm';
import SendPicture from 'app/components/controls/sendPicture/SendPicture';
import { getTranslate } from 'react-localize-redux';

class GuideContainer extends Component {

  state = {
    ref: '',
    refUrl: '',
    refType: '',
    backEnabled: false
  }

  componentDidMount() {
    this.setState({
      ref: getQueryVariable('ref'),
      refType: getQueryVariable('reftype'),
      refUrl: getQueryVariable('refurl'),
      sectionRef: getQueryVariable('sectionref'),
      backEnabled: queryVariableExists('ref') || queryVariableExists('refurl')
    });
  }

  addPreloader = () => {
    var getRoot = document.getElementById('root');
    getRoot.innerHTML = '<div id="mpreload"><span></span><span></span><span></span><span></span></div>';
  }

  navigateBack = () => {
    if (this.state.refType === 'section') {
      this.addPreloader();
      this.props.dispatch(navigateToSection(this.state.ref, this.state.sectionRef));
    } else if (this.state.refType === 'menu') {
      this.props.dispatch(navigateToMenu(this.state.ref));
    }else if(!this.state.refType){
      this.props.dispatch(navigateToUrl(this.state.refUrl));
    }
  }

  guideNavigateBack = () => {
    if (this.state.refUrl !== '') {
      this.addPreloader();
      this.props.dispatch(navigateToUrl(this.state.refUrl));
    }else if(this.state.refType === 'section'){
      this.addPreloader();
      this.props.dispatch(navigateToSection(this.state.ref, this.state.sectionRef));
    }
  }

  navigateToGuide = (guideId) => {
    this.props.dispatch(navigateToGuide(guideId, this.state.ref, this.state.sectionRef));
  }

  render() {

    if (this.props.guide.error) {
      return (
        <div></div>
      );
    }

    if(this.props.themeContext.hideContent !== undefined && this.props.themeContext.hideContent === 'true' ){
      return (
        <div></div>
      );
    }

    return (
      <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
        {
          (!this.props.guide.loading && this.props.displayModeContext.containGuide) ?
            <>
              <Topbar
                backEnabled={this.state.backEnabled}
                back={this.navigateBack}
                sticky={false} />
              <Banner
                imageUrl={(this.props.guide.data.infoImageUrl !== '') ?
                  this.props.guide.data.infoImageUrl : this.props.guide.data.sectionImageUrl}
                title={this.props.guide.data.name}
                subtitle={""}
                topSubtitle={this.props.guide.data.partNumber > 0 ? 
                  this.props.translate('part') + ' ' + this.props.guide.data.partNumber : ''}
                underTopbar={false}
                themeContext={this.props.themeContext} />
            </> : null
        }
        <div className={
          (this.props.displayModeContext.containGuide) ?
            (!this.props.guide.loading) ? 'guide-container-wrapper' : '' : 'guide-container-wrapper-full'}>
          <div className={
            (this.props.displayModeContext.containGuide) ?
              (!this.props.guide.loading) ? 'guide-container' : '' : 'guide-container-full'}>
            <Guide
              fullMode={false}
              stepid={this.props.stepId}
              goBack={(this.state.refUrl !== '') ? this.guideNavigateBack : null}
              close={(this.state.ref !== '') ? this.navigateBack : null}
              projectid={this.props.guideId} />
          </div>
          {
            (this.props.displayModeContext.containGuide && !this.props.guide.loading) ?
              <>
                {
                  (this.props.guide.data.prevProjectId !== '' && this.props.guide.data.partNumber > 0) ?
                    <PrevGuide click={() => this.navigateToGuide(this.props.guide.data.prevProjectId)} /> : null
                }
                {
                  (this.props.guide.data.nextProjectId !== ''  && this.props.guide.data.partNumber > 0) ?
                    <NextGuide click={() => this.navigateToGuide(this.props.guide.data.nextProjectId)} /> : null
                }
              </> : null
          }
        </div>
        {
          (!this.props.guide.loading && this.props.displayModeContext.containGuide) ?
            <Footer companyName={this.props.guide.data.companyName} /> : null
        }
        {
          (!this.props.guide.loading) ?
            <>
              <ContactForm guideId={this.props.guide.data.projectId} />
              <SendPicture guideId={this.props.guide.data.projectId} />
            </>
             : null
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guide: state.guide,
    translate: getTranslate(state.locale)
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default
  withGeneralContext(
    connect(mapStateToProps, matchDispatchToProps)(
      withThemeContext(
        withDisplayModeContext(GuideContainer))));