import { SEND_CONTACT_US, CLOSE_CONTACT_FORM } from './actionTypes';

export const closeContactUsAction = () => {
  return {
    type: CLOSE_CONTACT_FORM
  }
}

export const submitContactUsAction = (data) => {
  return {
    type: SEND_CONTACT_US,
    data: data
  }
}

export function closeContactUs() {
  return closeContactUsAction();
}

export function submitContactUs(appid, language, projectId, stepid, name, email, question, attachment, attachmentName) {

  var data = {
    email: email,
    name: name,
    text: question,
    applicationId: appid,
    language: language,
    projectId: projectId,
    stepId: stepid,
    attachment: attachment,
    attachmentName: attachmentName
  };
  
  return submitContactUsAction(data);

  /*var params = new FormData();
  params.append('json', JSON.stringify(jsonRequest));
  params.append('image', image);*/
}
