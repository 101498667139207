import { OPEN_CONTACT_FORM, OPEN_SINGLE_GUIDE } from './actionTypes';

export const openContactFormAction = () => {
    return {
      type: OPEN_CONTACT_FORM
    }
}

export const openGuideInNewTabAction = (guideId) => {
  return {
    type: OPEN_SINGLE_GUIDE,
    guideId: guideId
  }
}

export function openGuideInNewTab(guideId) {
  return openGuideInNewTabAction(guideId);
}

export function openContactForm() {
    return openContactFormAction();
}