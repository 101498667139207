import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

class Stepper extends Component {

  render() {

    return (
        <>
          {
            this.props.hideStepTitles || this.props.step.title === '-' ? null :
            (this.props.step.title && this.props.step.title !== '') ? 
              <span>{this.props.step.title}</span> : 
              <span>{this.props.translate('step')} {(this.props.step.stepIndex > 0) ? this.props.step.stepIndex : 1}</span>
          }
        </>
    );
  }
}


function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    step: state.step,
    hideStepTitles: state.guide.data.hideStepTitles
  };
}

export default connect(mapStateToProps)(Stepper);