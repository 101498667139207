import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { getGalleryData } from './actions';
import { Scrollbar } from 'react-scrollbars-custom';
import { getTranslate } from 'react-localize-redux';
import GalleryItem from './GalleryItem';
import 'assets/styles/gallery.scss';
import { LeftArrowIcon } from 'app/components/svg/LeftArrowIcon';
import { RightArrowIcon } from 'app/components/svg/RightArrowIcon';
import LikeIcon from 'app/components/svg/LikeIcon';
import HeartIcon from 'app/components/svg/HeartIcon';
import { CSSTransition } from 'react-transition-group';

class Gallery extends Component {

  state = {
    sliderIndex: 0,
    animate: false,
    loading: true
  }

  componentDidMount() {
    this.props.dispatch(getGalleryData(this.props.generalContext.appName, this.props.sectionId));
    this.setState({
      loading: this.props.gallery.loading
    })
  }

  prev = (event) => {
    event.stopPropagation();
    if (this.state.sliderIndex > 0) {
        this.setState({ sliderIndex: this.state.sliderIndex - 1, animate: true });
    }
  }

  next = (event) => {
      event.stopPropagation();
      if (this.props.gallery.items.length > this.state.sliderIndex + 1) {
          this.setState({ sliderIndex: this.state.sliderIndex + 1, animate: true });
      }
  }

  finishedAnimation = () => {
      this.setState({ animate: false });
  }

  arraySplitter = () => {
    let countItems = 0
    const lengthArr = this.props.gallery.items.length
    const resultArr = []
    const splitter = (arr) => {
        if (countItems >= lengthArr) {
          return
          }
        resultArr.push(arr.slice(countItems, countItems + 4))
        countItems = countItems + 4
        splitter(this.props.gallery.items)
         }
       splitter(this.props.gallery.items)
       return resultArr
  }

  render() {

      if(!this.props.isGalleryStep){
        if (this.props.themeContext.galleryEnabled !== 'true' || this.props.gallery.items.length === 0) {
          return null;
        }
      }

      if(this.props.isGalleryStep && this.props.gallery.items.length === 0){
        return (
          <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <h3 style={{color: '#fff', textShadow: '0px 0px 2px #000'}}>Add images for gallery</h3>
          </div>
          
          )
      }
      

    let newArr = this.arraySplitter();

    return (
      <div className="gallery-container">
        {!this.props.isGalleryStep ?
        <>
        <div className="default-title title-font gallery-title">
          {
            (this.props.themeContext.customGalleryTitle !== '') ?
              this.props.themeContext.customGalleryTitle : this.props.translate('gallery')
          }
        </div>
        <div className="gallery-items-container">
          <Scrollbar noScrollY>
              {
                this.props.gallery.items.map(item => (
                  <div className='item' style={{display: 'inline-block', position: 'relative'}}>
                    {this.props.themeContext.galleryEmodji === "true" ?
                      <div className='emodji' >
                        <LikeIcon count={item.likes} appname={this.props.generalContext.appName} resourceid={item.resourceId} projectId={item.projectId} hearts={-1}/>
                        <HeartIcon count={item.hearts} appname={this.props.generalContext.appName} resourceid={item.resourceId} projectId={item.projectId} likes={-1}/>
                      </div> : null
                    }
                    <GalleryItem item={item} key={item.imageUrl} />
                  </div>
                ))
              }
          </Scrollbar>
        </div>
        </> :
        <div className={'gallery-tip-wrapper'} style={{backdropFilter: 'none', overflow: 'hidden'}}>
                    <div
                    style={{ boxShadow: 'none' }}
                    className='gallery-tip-images-container'>
                    {
                        (newArr && newArr.length > this.state.sliderIndex) ?
                            <>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <CSSTransition
                                        in={this.state.animate}
                                        onEntered={this.finishedAnimation}
                                        timeout={300} classNames="quick-fade-in">
                                        <div className='tip-image-step' onClick={this.containerClick}>
                                            {
                                                newArr.map((item, index) => (
                                                  
                                                    <div key={index}
                                                        style={{
                                                            width: (index === this.state.sliderIndex) ? 'calc(100% - 100px)' : 0,
                                                        }}>
                                                          {item.map((itemImage) => (
                                                                <div className='tip-gallery-image'>
                                                                  {this.props.themeContext.galleryEmodji === "true" ?
                                                                    <div className='emodji'>
                                                                    <LikeIcon count={itemImage.likes} appname={this.props.generalContext.appName} resourceid={itemImage.resourceId} projectId={itemImage.projectId} hearts={-1}/>
                                                                    <HeartIcon count={itemImage.hearts} appname={this.props.generalContext.appName} resourceid={itemImage.resourceId} projectId={itemImage.projectId} likes={-1}/>
                                                                    </div> : null
                                                                  }
                                                                  <GalleryItem item={itemImage} key={itemImage.imageUrl} />
                                                                </div>
                                                                
                                                                )
                                                          )}
                                                    </div>
                                                ))
                                            }
                                            <>
                                                {
                                                    (this.state.sliderIndex > 0) ?
                                                        <div
                                                            style={{ opacity: '1 !important', left: '10px' }}
                                                            onClick={this.prev}
                                                            className='tip-gallery-image-arrow-wrapper tip-gallery-image-prev'>
                                                            <LeftArrowIcon opacity={1} color='white' />
                                                        </div> : null
                                                }
                                                {
                                                    (this.state.sliderIndex + 1 < newArr.length) ?
                                                        <div
                                                            style={{ opacity: '1 !important', right: '10px' }}
                                                            onClick={this.next}
                                                            className='tip-gallery-image-arrow-wrapper tip-gallery-image-next'>
                                                            <RightArrowIcon opacity={1} color='white' />
                                                        </div> : null
                                                }
                                            </>
                                        </div>
                                    </CSSTransition>
                                </div>
                            </> : null
                          }
                      </div>
                  </div>
              }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gallery: state.gallery,
    translate: getTranslate(state.locale)
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default
  connect(mapStateToProps, matchDispatchToProps)(
    withThemeContext(
      withGeneralContext(Gallery)));