import React, { Component } from 'react';
import Section from 'app/components/home/section/Section';

class SectionPage extends Component {

  render() {

    return (
        <Section sectionId={this.props.match.params.sectionId} />
    );
  }
}

export default SectionPage;
