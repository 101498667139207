import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/navigation.scss';
import { getTranslate } from 'react-localize-redux';
import { CSSTransition } from 'react-transition-group';

class GuidePart extends Component {

    state = {
        loaded: false
    }

    componentDidMount() {
        this.setState({ loaded: true });
    }

    render() {

        return (
            <CSSTransition in={this.state.loaded} timeout={300} classNames="quick-fade-in">
                <div className='multipart-navigation-item direction'
                    onClick={() => this.props.onClick(this.props.id)}>
                    <div
                        className='multipart-navigation-item-image'
                        style={{ backgroundImage: `url(${this.props.imageUrl})` }} />
                    <div className='multipart-navigation-item-content'>
                        <span className='multipart-navigation-item-content-part'>{this.props.translate('part')} {this.props.part}</span>
                        <span className='multipart-navigation-item-content-title title-font'>{this.props.title}</span>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}


function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

export default connect(mapStateToProps)(GuidePart);