import React, { Component } from 'react';
import { RightArrowIcon } from 'app/components/svg/RightArrowIcon';

class NextGuide extends Component {

    render() {

        return (
            <div
                className='guide-container-next-guide'
                onClick={this.props.click}
            >
                <RightArrowIcon color={'#000000'} />
            </div>
        );
    }
}

export default NextGuide;