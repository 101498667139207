import { POST_PREFIX, ACTION_INPROGRESS, ACTION_SUCCESS, ACTION_FAIL } from 'app/middleware/apiRequestMiddleware';
export { STEP_UPDATE_DATA } from 'app/components/main/instructions/actionTypes';

export const OPEN_CONTACT_FORM = 'OPEN_CONTACT_FORM';
export const CLOSE_CONTACT_FORM = 'CLOSE_CONTACT_FORM';
export const SEND_CONTACT_US = 'SEND_CONTACT_US';

export const POST_CONTACT_US = POST_PREFIX + 'CONTACT_US';

export const POST_CONTACT_US_INPROGRESS = POST_CONTACT_US + ACTION_INPROGRESS;
export const POST_CONTACT_US_SUCCESS = POST_CONTACT_US + ACTION_SUCCESS;
export const POST_CONTACT_US_FAIL = POST_CONTACT_US + ACTION_FAIL;
