import { OPEN_FINISH, CLOSE_FINISH } from './actionTypes';

export default function(state = { visible: false }, action) {

  switch(action.type)
  {
      case OPEN_FINISH:
        return { ...state, visible: true }
      case CLOSE_FINISH:
        return { ...state, visible: false }
      default:
        return { ...state }
  }
}
