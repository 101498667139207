import React, { Component } from 'react';
import { withThemeContext } from 'app/context/withThemeContext';
import ContentTextTip from 'app/components/instructions/step/components/overlays/contentTextTip/ContentTextTip';
import SoundIcon from '@material-ui/icons/VolumeUp';
import 'assets/styles/tip.scss';

class ContentTip extends Component {

  open = () => {
    if (this.props.tip.type === 1 || this.props.tip.type === 5) {
      this.props.openAction(this.props.tip, 0, 2);
    }
    else if (this.props.tip.type === 4) {
      this.props.openAction(this.props.tip, 0, 3);
    }
    else if (this.props.tip.type === 8) {
      this.props.openAction(this.props.tip, 0, 8);
    }
    else if (this.props.tip.type === 6) {
      this.props.openAction(this.props.tip, 0, 6);
    }
    else if (!this.props.tip.data) {
      return;
    }
    else if (this.props.tip.data.url) {
      window.open(this.props.tip.data.url, '_blank');
    }
    else if (this.props.tip.data && this.props.tip.data.text !== '') {
      this.props.openAction(this.props.tip, 0, 1);
    }
  }

  render() {

    if (this.props.tip.type === 0 && this.props.tip.data) {
      return (
        <ContentTextTip
          text={this.props.tip.data.text}
          color={this.props.color} />
      );
    }
    return (
      <button
        onClick={this.open}
        className={"content-tip-wrapper default-text"}
        style={{
          border: 'solid 1px',
          borderColor: (this.props.color) ? this.props.color : this.props.themeContext.buttonBackgroundColor,
          background: (this.props.backgroundColor) ? this.props.backgroundColor : this.props.themeContext.buttonTextColor,
          color: (this.props.color) ? this.props.color : this.props.themeContext.buttonBackgroundColor
        }}>
        <div className='content-tip-button-text'>
          {
            (this.props.tip.type === 6) ?
              <SoundIcon /> : this.props.tip.title
          }
        </div>
      </button>
    );
  }
}

export default withThemeContext(ContentTip);
