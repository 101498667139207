import React, { Component } from 'react';
import 'assets/styles/progressbar.scss';
import withProgressBar from './withProgressBar';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { ProgressBubble } from 'app/components/svg/ProgressBubble';
import { CSSTransition } from 'react-transition-group';

class ProgressBar extends Component {

    state = {
        animate: false,
        keepAnimation: false,
        bubbleValue: ''
    }

    componentDidUpdate(prevProps) {

        if (prevProps.progressValue > 0 && !this.props.inProgress && prevProps.progressValue !== this.props.progressValue) {
            if (this.state.animate) {
                this.setState({ keepAnimation: true });
            }
            this.setState({ animate: true });
        }

        if(this.props.tempStepId !== prevProps.tempStepId || 
           this.props.stepInfo.total !== prevProps.stepInfo.total ||
           this.props.stepInfo.stepIndex !== prevProps.stepInfo.stepIndex) {
            this.updateBubbleValue();
        }
    }

    updateBubbleValue = () => {
        var value = '';
        if(this.props.inProgress && this.props.tempStepId) {
            if(this.props.showPercentage) {
                value = Math.ceil(100 * this.props.tempStepId / this.props.stepInfo.total) + '%';
            } else {
                value = this.props.tempStepId + '/' + this.props.stepInfo.total;
            }
        } else if(this.props.showPercentage) {
            value = Math.ceil(100 * this.props.stepInfo.stepIndex / this.props.stepInfo.total) + '%';
        } else {
            value = this.props.stepInfo.stepIndex + '/' + this.props.stepInfo.total;
        }
        this.setState({ bubbleValue: value });
    }

    animationEnded = () => {
        if (this.state.keepAnimation) {
            this.setState({ keepAnimation: false });
            setTimeout(() => this.animationEnded(), 1000);
        } else {
            this.setState({ animate: false, keepAnimation: false });
        }
    }

    render() {

        return (
            (this.props.stepInfo.stepIndex > 0) ?
                <div className='progressbar-wrapper'>
                    <div className="progressbar">
                        <div className={(this.props.inProgress) ?
                            "progressbar-inner" :
                            "progressbar-inner move-animation-width"}
                            style={{
                                width: this.props.progressValue + '%',
                                backgroundColor: this.props.themeContext.progressBarColor
                            }}>
                        </div>
                        <CSSTransition
                            in={this.state.animate}
                            timeout={2000}
                            onEntered={this.animationEnded}
                            classNames="fade-in-out">
                            <div
                                style={((this.props.inProgress && this.props.tempStepId) || this.props.themeContext.alwaysShowCurrentStepNumber === "true") ?
                                    { opacity: 1 } :
                                         ((this.props.stepInfo.stepIndex === this.props.stepInfo.total) ? 
                                            {} : {})}
                                className='progressbar-bubble-wrapper'>
                                <div className='progressbar-bubble'>
                                    <ProgressBubble value={this.state.bubbleValue} />
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div> : null
        );
    }
}

function mapStateToProps(state) {
    return {
        stepInfo: state.step
    };
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default withProgressBar(withThemeContext(connect(mapStateToProps, matchDispatchToProps)(ProgressBar)));
