import { FETCH_SECTION_DATA, NAVIGATE_TO_GUIDE, NAVIGATE_TO_MENU } from './actionTypes';
import { GET_SECTION_DATA_URL } from './services';

export const getSectionDataAction = (url) => {
  return {
    type: FETCH_SECTION_DATA,
    payload: { url: url }
  }
}

export const navigateToGuideAction = (guideId, sectionId, sectionRef) => {
  return {
    type: NAVIGATE_TO_GUIDE,
    guideId: guideId,
    sectionRef: sectionRef,
    ref: sectionId,
    refType: 'section'
  }
}

export const navigateToReferalSectionAction = (sectionId) => {
  return {
    type: NAVIGATE_TO_MENU,
    sectionId: sectionId
  }
}

export function getSectionData(appName, language, sectionId) {
  var url = GET_SECTION_DATA_URL
    .replace('[appname]', appName)
    .replace('[language]', language)
    .replace('[section]', sectionId);
  return getSectionDataAction(url);
}

export function navigateToGuide(guideId, sectionId, sectionRef) {
  return navigateToGuideAction(guideId, sectionId, sectionRef);
}

export function navigateToReferalSection(sectionId) {
  return navigateToReferalSectionAction(sectionId);
}