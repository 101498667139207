import { FETCH_PREFIX, ACTION_SUCCESS, ACTION_FAIL, ACTION_INPROGRESS } from 'app/middleware/apiRequestMiddleware';

export const FETCH_APP_DATA = FETCH_PREFIX + 'APP_DATA';
export const FETCH_APP_DATA_IN_PROGRESS = FETCH_APP_DATA + ACTION_INPROGRESS;
export const FETCH_APP_DATA_SUCCESS = FETCH_APP_DATA + ACTION_SUCCESS;
export const FETCH_APP_DATA_FAIL = FETCH_APP_DATA + ACTION_FAIL;

export const FETCH_APP_DATA_GUIDE = FETCH_PREFIX + 'APP_DATA_GUIDE';
export const FETCH_APP_DATA_GUIDE_IN_PROGRESS = FETCH_APP_DATA_GUIDE + ACTION_INPROGRESS;
export const FETCH_APP_DATA_GUIDE_SUCCESS = FETCH_APP_DATA_GUIDE + ACTION_SUCCESS;
export const FETCH_APP_DATA_GUIDE_FAIL = FETCH_APP_DATA_GUIDE + ACTION_FAIL;

export { NAVIGATE_TO_GUIDE, NAVIGATE_TO_SECTION } from 'app/middleware/navigationMiddleware';

export const SHOW_SECTIONS = 'SHOW_SECTIONS';
export const GO_BACK = 'GO_BACK';
