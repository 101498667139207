import {SLIDES_INTERACTION_ENABLED, SLIDES_INTERACTION_DISABLED} from './actionTypes';
import {INIT, UPDATE_CURRENT_STEP, STEP_UPDATE_DATA, UPDATE_STEPS_RANGE, FETCH_PROJECT_DATA_SUCCESS, NAVIGATE_BACK} from './actionTypes';

export default function(state = 
  { 
    selectedItem: 0, 
    showFinish: false, 
    lastChangedStep: 0, 
    interactionEnabled: true, 
    currentStepId: 0,
    currentStepIndex: 0,
    hideStart: false,
    hideEnd: false,
    navigationStack: [],
    navigationBackStepId: -1
  }, 
  action) {

  switch(action.type)
  {
    case INIT:
      return { ...state, navigationBackStepId: -1 }
    case UPDATE_CURRENT_STEP:
      return { ...state, lastChangedStep: action.stepId }
    case STEP_UPDATE_DATA:
      return { ...state, 
        lastChangedStep: -1, 
        currentStepIndex: action.stepIndex, 
        currentStepId: action.stepId }
    case SLIDES_INTERACTION_ENABLED:
      return { ...state, interactionEnabled: true }
    case SLIDES_INTERACTION_DISABLED:
      return { ...state, interactionEnabled: false }
    case FETCH_PROJECT_DATA_SUCCESS:
      return { ...state, hideEnd: action.payload.hideEnd }
    case UPDATE_STEPS_RANGE:
    
      // Hide Start - There is an option to hide starting intro slides,
      // but there is an issue with the carousel having only one slide.
      // For now we will always leave the starting intro slides, but 
      // we will lock the interaction instead

      // Manage navigation stack for back purposes
      let navigationStack = state.navigationStack;        
      let navigationBackStepId = action.navigatedFromStepId;
      let hideStart = (navigationStack.length > 0);
      navigationStack.push({
        navigationBackStepId: action.navigatedFromStepId,
        hideStart: hideStart,
        hideEnd: state.hideEnd,
        additionalStepsNumber: state.currentStepIndex,
        isSingleStep: (action.steps.length === 1)
      });
      
      // Return state
      return { ...state, lastChangedStep: 0, 
                         navigationStack: navigationStack,
                         navigationBackStepId: navigationBackStepId, 
                         hideStart: false, 
                         hideEnd: action.hideEnd,
                         currentStepId: action.steps[0].stepId }
                         
    case NAVIGATE_BACK:

        // Manage navigation stack and remove the top back data item
        let backNavigationStack = state.navigationStack;
        if(backNavigationStack.length > 0) {
          let navigationData = backNavigationStack.pop();
          let newNavigationBackStepId = -1;
          if(backNavigationStack.length > 0) {
            newNavigationBackStepId = backNavigationStack[backNavigationStack.length - 1].navigationBackStepId;
          }
          return { ...state, 
            lastChangedStep: navigationData.additionalStepsNumber, 
            navigationStack: backNavigationStack,
            navigationBackStepId: newNavigationBackStepId, 
            currentStepId: navigationData.stepId,
            hideStart: false, 
            hideEnd: navigationData.hideEnd }
        } else {

          // Return default related values
          return { ...state, 
            lastChangedStep: 0, 
            navigationStack: backNavigationStack,
            navigationBackStepId: -1, 
            currentStepId: 0,
            hideStart: false, 
            hideEnd: false }
        }
    default:
        return { ...state };
  }
}
