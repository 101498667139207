// eventTracking is defined in Google Tag Manager and forwards
// category, action and label to google analytics.
// The reporting is done via dataLayer, based on the following guide:
// https://mixedanalytics.com/blog/ga-gtm-event-tracking-pass-through-datalayer

export function reportEvent(category, action, label) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'eventTracking',
        'category': category,
        'action': action,
        'label': label
    });
}
