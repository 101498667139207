import React, { Component } from 'react';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { Router, Switch, Route } from 'react-router-dom';
import * as h from 'history';
import 'assets/styles/app.scss';
import GuidePage from 'app/pages/GuidePage';
import SingleGuidePage from 'app/pages/SingleGuidePage';
import HomePage from 'app/pages/HomePage';
import GalleryPage from 'app/pages/GalleryPage';
import SectionPage from 'app/pages/SectionPage';
import Message from 'app/components/general/message/Message';
import { ThemeContextProvider } from 'app/context/ThemeContext';
import { DisplayModeContextProvider } from 'app/context/DisplayModeContext';
import { GeneralContextProvider } from 'app/context/GeneralContext';
import { appNameFromUrl, getQueryVariable, queryVariableExists } from 'app/utils/utils';
import { initialize } from 'react-localize-redux';
import { initLanguages, initTheme } from './actions';
import LegacyRoutesMiddleware from 'app/middleware/legacyRoutesMiddleware';
import Consent from 'app/components/general/consent/Consent';

export const history = h.createBrowserHistory();

class App extends Component {

  state = {
    appName: appNameFromUrl(),
    customLanguages: getQueryVariable('languages')
  }

  componentDidMount() {
    if(this.props.app.loadedFromStorage) {
      var languageParam = getQueryVariable('language');
      this.props.dispatch(initLanguages(this.props.app.languages, languageParam ? languageParam : this.props.app.language));
      this.props.dispatch(initTheme(this.props.app.theme));
    } else {
      this.props.dispatch(initialize(['eng'], { defaultLanguage: 'eng' }));
    }
  }

  render() {

    return (
      <>
        <GeneralContextProvider value={{
          isThemeLoaded: this.props.app.isThemeLoaded,
          language: this.props.app.language,
          languages: this.props.app.languages,
          customLanguages: this.state.customLanguages,
          appName: this.state.appName,
          guideId: this.props.app.guideId
        }}>
          <DisplayModeContextProvider value={this.props.displayMode}>
            <ThemeContextProvider value={this.props.app.theme}>
              <Router history={history}>
                <Switch>
                  <Route exact path="/:appid" component={HomePage}></Route>
                  <Route exact path="/:appid/gallery" component={GalleryPage}></Route>
                  <Route exact path="/:appid/guides" component={HomePage}></Route>
                  <Route exact path="/:appid/guides/section/:sectionId" component={SectionPage} />
                  <Route exact path="/:appid/guides/section/:sectionId/gallery" component={GalleryPage} />
                  <Route exact path="/:appid/guides/guide/:guideId/gallery" component={GalleryPage} />
                  <Route exact path="/:appid/guides/guide/:guideId/:stepId?" component={GuidePage} />
                  <Route exact path="/:appid/guide/:guideId/:stepId?" component={SingleGuidePage} />
                  <Route exact path="/direct/guide" component={LegacyRoutesMiddleware}/>                    
                </Switch>
              </Router>
              <Message />
              <Consent />
            </ThemeContextProvider>
          </DisplayModeContextProvider>
        </GeneralContextProvider>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

const mapSizesToProps = ({ width, height }) => ({
  displayMode: {
    portrait: (queryVariableExists('portrait_view') & width > 840) ? true : height > (width / 4 * 3 + 100),
    small: (width < 300 && height < 550) || height < 250,
    containGuide: height > 520 && width > 840
  }
})

export default withSizes(mapSizesToProps)(connect(mapStateToProps, matchDispatchToProps)(App));