import { NAVIGATE_TO_SECTION, NAVIGATE_TO_MENU, NAVIGATE_TO_GUIDE, NAVIGATE_TO_URL } from './actionTypes';

export const navigateToSectionAction = (sectionId, sectionRef) => {
  return {
    type: NAVIGATE_TO_SECTION,
    sectionId: sectionId,
    topSectionId: sectionRef
  }
}

export const navigateToMenuAction = (topSectionId) => {
  return {
    type: NAVIGATE_TO_MENU,
    sectionId: topSectionId
  }
}

export const navigateToUrlAction = (url) => {
  return {
    type: NAVIGATE_TO_URL,
    url: url
  }
}

export const navigateToGuideAction = (guideId, sectionId, sectionRef) => {
  return {
    type: NAVIGATE_TO_GUIDE,
    guideId: guideId,
    sectionRef: sectionRef,
    ref: sectionId,
    refType: 'section'
  }
}

export function navigateToSection(sectionId, sectionRef) {
  return navigateToSectionAction(sectionId, sectionRef);
}

export function navigateToMenu(topSectionId) {
  return navigateToMenuAction(topSectionId);
}

export function navigateToUrl(url) {
  return navigateToUrlAction(url);
}

export function navigateToGuide(guideId, sectionId, sectionRef) {
  return navigateToGuideAction(guideId, sectionId, sectionRef);
}