import React, { Component } from 'react';

class VideoTipLayer extends Component {

    componentWillUnmount() {
      this.video.src = "";
    }

    render() {

        return (
          <video
            ref={(video) => this.video = video}
            src={this.props.url}
            style={{width: '100%', height: '100%'}}
            autoPlay muted loop playsInline
          >
          </video>
      )
    }
}

export default VideoTipLayer;
