import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/splitStep.scss';
import {getUnitLabel} from "../../../../../utils/utils";

class MaterialsImageItem extends Component {

    render() {

        return (
            <div className='materials-image-item'>
                <div
                    className='materials-image-item-image'
                    style={{
                        backgroundImage: 'url(' + this.props.item.imageUrl + ')',
                        backgroundColor: this.props.backgroundColor,
                        color: this.props.textColor
                    }}
                />

                {
                    (this.props.item.quantity && this.props.item.quantity > 0) ?
                        <div className='materials-image-item-quantity'>{this.props.item.quantity}&nbsp;&nbsp;
                            {(getUnitLabel(this.props.item))}
                            &nbsp;&nbsp;x&nbsp;&nbsp;</div> : null
                }
                <div className='materials-image-item-text direction'>{this.props.item.name}</div>
            </div>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, matchDispatchToProps)(MaterialsImageItem);
