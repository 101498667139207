import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/materials.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import { getTranslate } from 'react-localize-redux';
import MaterialsImageItem from './MaterialsImageItem';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from 'react-scrollbars-custom';

class MaterialsImagesList extends Component {

    render() {

        return (
            (this.props.materials && this.props.materials.length > 0) ?
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <Scrollbar noScrollX>
                        <div className='materials-images-list-wrapper'>
                            <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-medium">
                                <div className='materials-images-header default-title title-font direction'
                                    style={{ color: this.props.themeContext.stepTextColor }}>
                                    {(this.props.materialsText && this.props.materialsText !== '') ?
                                        this.props.materialsText:  this.props.translate('materials')}
                                </div>
                            </CSSTransition>
                            <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-quick">
                                <div className="direction">
                                    {
                                        (this.props.materials && this.props.materials.length > 0) ?
                                            this.props.materials.map((item, index) => (
                                                <MaterialsImageItem
                                                    index={index}
                                                    item={item}
                                                    key={index}
                                                />
                                            )) : null
                                    }
                                </div>
                            </CSSTransition>
                        </div>
                    </Scrollbar>
                </div> : null
        );
    }
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

export default withThemeContext(connect(mapStateToProps, matchDispatchToProps)(MaterialsImagesList));
