import {
  OPEN_SEND_PICTURE_FORM, CLOSE_SEND_PICTURE_FORM,
  POST_SEND_PICTURE_INPROGRESS, POST_SEND_PICTURE_SUCCESS, 
  POST_SEND_PICTURE_FAIL, STEP_UPDATE_DATA} from './actionTypes';

export default function(state = { 
    visible: false, inprogress: false, error: false, success: false, stepId: -1 }, action) {

  switch(action.type)
  {
      case OPEN_SEND_PICTURE_FORM:
        return { ...state, visible: true, inprogress: false, error: false, success: false }
      case CLOSE_SEND_PICTURE_FORM:
        return { ...state, visible: false, inprogress: false, error: false, success: false }
      case POST_SEND_PICTURE_INPROGRESS:
        return { ...state, inprogress: true, error: false, success: false }
      case POST_SEND_PICTURE_SUCCESS:
          return { ...state, inprogress: false, visible: false, error: false, success: true }
      case POST_SEND_PICTURE_FAIL:
        return { ...state, inprogress: false, error: true, success: false }
      case STEP_UPDATE_DATA:
          return { ...state, stepId: action.stepId }
      default:
        return { ...state }
  }
}
