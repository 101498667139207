import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'assets/styles/finish.scss';
import { getTranslate } from 'react-localize-redux';
import { RightArrowIcon } from 'app/components/svg/RightArrowIcon';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';

class NextGuide extends Component {

    render() {

        return (
            <div onClick={this.props.openNextGuide}>
                <div
                    className='finish-next-guide'
                    style={{
                        backgroundImage: "url(" + this.props.nextGuideImageUrl + ")",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                    <div className='finish-next-guide-overlay'>
                        <div className='finish-next-guide-name'>{this.props.nextGuideName}</div>
                    </div>
                </div>
                <button
                    className="finish-next-guide-button"
                    style={{
                        backgroundColor: this.props.themeContext.buttonBackgroundColor,
                        color: this.props.themeContext.buttonTextColor
                    }}>
                    <span style={{ paddingRight: 7 }}>
                        {this.props.translate('startNow')}
                    </span>
                    <RightArrowIcon color={this.props.themeContext.buttonTextColor} />
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

export default withGeneralContext(connect(mapStateToProps)(withThemeContext(NextGuide)));
