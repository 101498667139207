/* global startTracking */
import ProjectAnalyticsHelper from 'app/helpers/projectAnalyticsHelper';
export const START_TRACKING = 'START_TRACKING';

export const trackingMiddleware = store => next => action => {

  switch (action.type) {

    case START_TRACKING:
      ProjectAnalyticsHelper.consentToTracking();
      startTracking();
      break;

    default:
      break;
  }
  return next(action);
}

