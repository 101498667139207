import { FETCH_GALLERY_DATA, POST_GALLERY_EMODJI_DATA } from "./actionTypes";
import {
  GET_GALLERY_DATA,
  GET_GALLERY_DATA_BY_SECTION,
  GET_GALLERY_DATA_BY_GUIDE,
  POST_EMODJI_DATA,
} from "./services";

export const getGalleryDataAction = (url) => {
  return {
    type: FETCH_GALLERY_DATA,
    payload: {
      url: url,
    },
  };
};

export function getGalleryData(appId, sectionId, guideId) {
  var url = GET_GALLERY_DATA + appId;
  if (sectionId) {
    url = GET_GALLERY_DATA_BY_SECTION + sectionId;
  }
  if (guideId) {
    url = GET_GALLERY_DATA_BY_GUIDE + guideId;
  }
  return getGalleryDataAction(url, appId);
}

export const postGalleryDataAction = (url) => {
  return {
    type: POST_GALLERY_EMODJI_DATA,
    payload: { url: url },
  };
};

export function postGalleryData(appId, resourceId, projectId, likes, hearts) {
  var url =
    POST_EMODJI_DATA +
    `?applicationId=${appId}&resourceId=${resourceId}&projectId=${projectId}&likes=${likes}&hearts=${hearts}`;
  return postGalleryDataAction(url);
}
