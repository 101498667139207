import React from 'react';
import ReactDOM from 'react-dom';
import config from "configuration";
import App from 'app/app/App';
import {Provider} from 'react-redux';
import {configureStore} from 'app/configureStore';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

// Configure store
const store = configureStore();

// Configure logger
var bugsnagClient = null;
var ErrorBoundary = null;
if(config.errorLoggingKey !== '') {
  bugsnagClient = bugsnag(config.errorLoggingKey);
  bugsnagClient.use(bugsnagReact, React)
  ErrorBoundary = bugsnagClient.getPlugin('react')
}

// Render
var rootRender =   
  <Provider store={store}>
    <App/>
  </Provider>

if(bugsnagClient) {
  ReactDOM.render(<ErrorBoundary>{ rootRender }</ ErrorBoundary>,   document.getElementById('root'));
} else {
  ReactDOM.render(rootRender, document.getElementById('root'));
}
