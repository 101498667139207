import React from 'react';
import { GeneralContextConsumer } from './GeneralContext';

export function withGeneralContext(Component) {
    return function WrapperComponent(props) {
        return (
            <GeneralContextConsumer>
                {state => <Component {...props} generalContext={state} />}
            </GeneralContextConsumer>
        );
    };
}