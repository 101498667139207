import { FETCH_APP_DATA, FETCH_APP_DATA_GUIDE, SHOW_SECTIONS, NAVIGATE_TO_SECTION, NAVIGATE_TO_GUIDE, GO_BACK } from './actionTypes';
import { GET_DATA_URL, GET_DATA_GUIDE_URL } from './services';

export const getDataAction = (url) => {
  return {
    type: FETCH_APP_DATA,
    payload: { url: url }
  }
}

export const getDataGuideAction = (url) => {
  return {
    type: FETCH_APP_DATA_GUIDE,
    payload: { url: url }
  }
}

export const showSectionsAction = (topSectionId) => {
  return {
    type: SHOW_SECTIONS,
    topSectionId: topSectionId
  }
}

export const navigateToSectionAction = (sectionId, topSectionId) => {
  return {
    type: NAVIGATE_TO_SECTION,
    sectionId: sectionId,
    topSectionId: topSectionId
  }
}

export const navigateToGuideAction = (guideId, topSectionId) => {
  return {
    type: NAVIGATE_TO_GUIDE,
    guideId: guideId,
    ref: topSectionId,
    refType: 'menu'
  }
}

export const goBackAction = () => {
  return {
    type: GO_BACK
  }
}

export function getDataGuide(name, language) {
  var url = GET_DATA_GUIDE_URL.replace('[name]', name).replace('[language]', language);
  return getDataGuideAction(url);
}

export function getData(appName, language) {
  var url = GET_DATA_URL.replace('[appname]', appName).replace('[language]', language);
  return getDataAction(url);
}

export function showSections(topSectionId) {
  return showSectionsAction(topSectionId);
}

export function navigateToSection(sectionId, topSectionId) {
  return navigateToSectionAction(sectionId, topSectionId);
}

export function navigateToGuide(guideId, topSectionId) {
  return navigateToGuideAction(guideId, topSectionId);
}

export function goBack() {
  return goBackAction();
}