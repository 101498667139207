import { INIT_LANGUAGES, INIT_THEME } from './actionTypes';

export const initLanguagesAction = (languages, currentLanguage) => {
  return {
    type: INIT_LANGUAGES,
    languages: languages,
    currentLanguage: currentLanguage
  }
}

export function initLanguages(languages, currentLanguage) {
  return initLanguagesAction(languages, currentLanguage);
}

export const initThemeAction = (themeSettings) => {
  return {
    type: INIT_THEME,
    theme: themeSettings
  }
}

export function initTheme(themeSettings) {
  return initThemeAction(themeSettings);
}