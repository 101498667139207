import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from './actions';
import { getQueryVariable } from 'app/utils/utils';
import 'assets/styles/splitStep.scss';

class SplitStepDecision extends Component {

  state = {
    rtl: (getQueryVariable('language') === 'heb') ? true : false
  }

  click = () => {
    console.log(this.props.question + " - " + this.props.answer.text);
    var dispatchAction = navigate(this.props.answer, this.props.fullMode);
    if(dispatchAction) {
      this.props.dispatch(dispatchAction);
    }
  }

  render() {

    return (
      <button
        className={`split-step-decision direction-only small-title title-font ${this.props.answer.text ? 
                                                                              (this.state.rtl ? this.props.answer.text.toLowerCase().replace(/\s+/g, "").replace(/[.,\/#?!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s{2,}/g,"") : this.props.answer.text.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").replace(/ /ig, '-'))
                                                                              : ''}`}
        onClick={this.click} 
        style={{
          backgroundColor: this.props.backgroundColor, 
          color: this.props.textColor              
        }}>
        {this.props.answer.text}
      </button>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, matchDispatchToProps)(SplitStepDecision);
