import {CLOSE_FORM_TIP} from './actionTypes'


export const closeTipAction = () => {
    return {
        type: CLOSE_FORM_TIP
    }
}

export function closeTip() {
    return closeTipAction();
}
