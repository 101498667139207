import React, { Component } from 'react';
import 'assets/styles/poweredBy.scss';

class PoweredBy extends Component {

    render() {
        return (
            (this.props.isVisible && this.props.isVisible === 'true') ?
                    <div className={this.props.placement + ' powered-by'}>
                        Powered by <a href="https://www.makeree.com" target="_blank"><span className={'powered-by-brand'}>MAKEREE</span></a>
                    </div> : null
        );
    }
}

export default PoweredBy;