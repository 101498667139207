import { OPEN_TEXT_TIP, CLOSE_TEXT_TIP, UPDATE_CURRENT_STEP, NAVIGATE_BACK, ONLY_CLOSE_TEXT_TIP } from './actionTypes';

  export default function(state = { isvisible: false }, action) {

    switch(action.type)
    {
        case OPEN_TEXT_TIP:
          return { ...state, isvisible: true, text: action.text, tipId: action.tipId }
          case UPDATE_CURRENT_STEP:
          case NAVIGATE_BACK:
          case CLOSE_TEXT_TIP:
          return { ...state, isvisible: false }
          case ONLY_CLOSE_TEXT_TIP:
          return { ...state, isvisible: false }
        default:
          return { ...state }
    }
  }
