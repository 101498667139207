import { OPEN_FORM_TIP, CLOSE_FORM_TIP, UPDATE_CURRENT_STEP, NAVIGATE_BACK } from './actionTypes';

  export default function(state = { isvisible: false }, action) {

    switch(action.type)
    {
        case OPEN_FORM_TIP:
          return { ...state, isvisible: true, item: action.item, tipId: action.tipId }
          case UPDATE_CURRENT_STEP:
          case NAVIGATE_BACK:
          case CLOSE_FORM_TIP:
          return { ...state, isvisible: false }
        default:
          return { ...state }
    }
  }
