import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { withThemeContext } from 'app/context/withThemeContext';
import LanguagesMenu from 'app/components/controls/languagesMenu/LanguagesMenu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import { getTranslate } from 'react-localize-redux';
import { getQueryVariable } from 'app/utils/utils';

class GuideMenu extends Component {

  state = {
    isLanguagesMenuOpened: false,
    isContactUsOpened: false
  }

  componentDidMount() {
    this.customLanguages = getQueryVariable('languages');
  }

  closeMenu = () => {
    this.props.openMenu(false);
  }

  openLanguagesMenu = (open) => {
    this.setState({ 
      isLanguagesMenuOpened: open
    });
  }

  openContactUs = (open) => {
    this.setState({ isContactUsOpened: open });
  }

  openTerms = () => {
    window.open(this.props.themeContext.termsUrl, '_blank');
  }

  changeLanguage = () => {
    this.closeMenu();
    this.openLanguagesMenu(true);
  }

  render() {   

    return (
      <>
        <LanguagesMenu
          isOpened={this.state.isLanguagesMenuOpened}
          openLanguagesMenu={this.openLanguagesMenu}
          anchor={this.props.anchor} />
        <Menu
          anchorEl={this.props.anchor}
          keepMounted
          PaperProps={{
            style: {
              backgroundColor: this.props.themeContext.menuBackgroundColor,
              color: this.props.themeContext.menuTextColor,
              boxShadow: "0 5px 40px 0 rgba(0, 0, 0, 0.2)"
            },
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.props.isOpened}
          onClose={this.closeMenu}>
          {
          (this.props.themeContext.hideLanguages === "false" || this.props.generalContext.customLanguages !== '') ?
            <MenuItem 
              button onClick={this.changeLanguage}
              style={{ padding: 0, paddingLeft: 14, paddingRight:14 }}>
              <LanguageIcon />
              <ListItemText style={{ paddingLeft: 9 }} primary={this.props.translate('changeLanguage')} />
            </MenuItem> : null
          }
          <MenuItem 
            button onClick={this.openTerms}
            style={{ padding: 0, paddingLeft: 14, paddingRight:14 }}>
            <InfoOutlinedIcon />
            <ListItemText style={{ paddingLeft: 9 }} primary={this.props.translate('termsAndConditions')} />
          </MenuItem>
        </Menu>
      </>
    );
  }
}


function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)
  };
}

export default connect(mapStateToProps)(withGeneralContext(withThemeContext(GuideMenu)));