import React, { Component } from "react";
import { connect } from "react-redux";
import "assets/styles/galleryGrid.scss";

class GalleryCardItem extends Component {
  videoClick = (event) => {
    let video = event.target.parentElement.children[0];
    let img = event.target.parentElement.children[1];
    let allImg = document.querySelectorAll(".playImg");
    let allVideo = document.querySelectorAll(".widget_video");
    allImg.forEach(function (image) {
      image.style.visibility = "visible";
    });

    if (video.paused === false) {
      video.pause();
      img.style.visibility = "visible";
    } else {
      img.style.visibility = "hidden";
      allVideo.forEach(function (videoGallery) {
        videoGallery.pause();
      });
      video.play();
    }
  };

  render() {
    return (
      <div className="widget-card">
        {
          this.props.item.type === "Video" ? (
            <a onClick={this.videoClick} style={{ height: "inherit"}}>
              <div className="gallery-widget_video">
                <video loop className="widget_video">
                  <source
                    src={`${this.props.item.imageUrl}#t=0.001`}
                    type="video/mp4"
                  />
                </video>
                <div className="playImg"></div>
              </div>
            </a>
          ) : (
            <img
              src={this.props.item.imageUrl}
              alt={this.props.item.projectName}
              className="image-widget"
            />
          )
        }
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, matchDispatchToProps)(GalleryCardItem);
