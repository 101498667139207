import { CLOSE_FINISH } from './actionTypes';

export const closeFinishAction = () => {
  return {
    type: CLOSE_FINISH
  }
}

export function closeFinish() {
  return closeFinishAction();
}