import { BEGINNING_SLIDE, END_SLIDE, SLIDE, FETCH_PROJECT_DATA, UPDATE_STEPS_RANGE, STEP_UPDATE_DATA } from './actionTypes';

export default function (state = { showLeftArrow: false, showRightArrow: true }, action) {

  switch (action.type) {
    case FETCH_PROJECT_DATA:
      return { ...state, showLeftArrow: false }
    case UPDATE_STEPS_RANGE:
      return { ...state, showLeftArrow: false, showRightArrow: false }
    case BEGINNING_SLIDE:
      return { ...state, showLeftArrow: false, showRightArrow: true }
    case END_SLIDE:
      return { ...state, showRightArrow: false, showLeftArrow: true }
    case SLIDE:
      return { ...state, showLeftArrow: true, showRightArrow: true }
    case STEP_UPDATE_DATA:
      return {
        ...state, showRightArrow: (
          action.stepIndex === -1 ||
          action.stepIndex < action.total - 1 || 
          (action.stepId === action.totalStepsInGuide && !action.hideEnd)
        )
      }
    default:
      return { ...state };
  }
}
