import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submitSendPicture, closeSendPicture } from './actions';
import { uploadSendPic, uploadPhotoVideo } from 'app/components/instructions/takephoto/actions';
import Camera from 'app/components/instructions/helpers/camera/Camera';
import { getTranslate } from 'react-localize-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { LargeLeftArrowIcon } from 'app/components/svg/LargeLeftArrowIcon';
import { SmallCloseIcon } from 'app/components/svg/SmallCloseIcon';
import { CloseIcon } from 'app/components/svg/CloseIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isValidEmail, isImageFile } from 'app/utils/utils';
import { Scrollbar } from 'react-scrollbars-custom';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import 'assets/styles/contactForm.scss';

class SendPicture extends Component {

  state = {
    email: '',
    terms: false,
    image: null,
    text: 'Send a picture',
    localImage: null,
    isVideoAttachment: false,
    photoProcessing: false,
    imageValid: true,
    emailValid: true,
    termsValid: true
  }

  componentDidUpdate(prevProps) {
    if (this.props.sendPicture.visible !== prevProps.sendPicture.visible) {
      this.setState({
        email: '', terms: false, image: null, text: 'Send a picture', localImage: null, photoProcessing: false,
        termsValid: true, emailValid: true, imageValid: true
      });
    }
  }

  close = () => {
    this.props.dispatch(closeSendPicture());
  }

  containerClick = (event) => {
    event.stopPropagation();
  }

  submit = (event) => {
    event.preventDefault();
    if (this.props.sendPicture.inprogress) {
      return;
    }
    var valid = true;
    if (!this.state.image || !this.state.localImage) {
      this.setState({ imageValid: false });
      valid = false;
    }
    if (this.state.email !== '' && !isValidEmail(this.state.email)) {
      this.setState({ emailValid: false });
      valid = false;
    }
    if (this.props.themeContext.termsEnabled === "true" && !this.state.terms) {
      this.setState({ termsValid: false });
      valid = false;
    }
    if (!valid) {
      return;
    }

    this.props.dispatch(submitSendPicture(
      this.props.generalContext.appName, this.props.generalContext.language, this.props.guideId,
      this.props.instructions.currentStepId, this.state.email ? this.state.email : 'no-email@info.com', this.state.text ? this.state.text : '---',
      this.state.image ? this.state.image : [], this.state.image ? this.state.image.name : ''
    ));  

    if(!this.state.isVideoAttachment){
      this.props.dispatch(uploadSendPic(this.props.generalContext.appName, this.props.generalContext.guideId, this.state.image, this.state.localImage));
    }else{
      this.props.dispatch(uploadPhotoVideo(this.props.generalContext.appName, this.props.generalContext.guideId, this.state.image, this.state.localImage));
    }

  }

  toggleTerms = () => {
    this.setState({ terms: !this.state.terms, termsValid: true });
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value, emailValid: true });
  }

  processCapture = () => {
    this.setState({ photoProcessing: true });
  }

  finishCapture = (image, localImage) => {
    this.setState({
      photoProcessing: false,
      image: image,
      localImage: localImage,
      isVideoAttachment: !isImageFile(image.name)
    });
  }

  addAttachment = () => {
    this.camera.capture('');
  }

  removePhoto = (event) => {
    this.setState({ image: null, localImage: null });
    event.stopPropagation();
  }

  render() {
    return (
      <div
        className={(this.props.sendPicture.visible) ? 'contact-wrapper popup-open' : 'contact-wrapper popup-close'}
        onClick={this.close}>
        <Scrollbar noScrollX>
          <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            <div className='contact-container' onClick={this.containerClick}>
              <div className="contact-title default-title title-font direction">
                <span className='contact-back' onClick={this.close}>
                  <LargeLeftArrowIcon color="black" style={{ paddingRight: 15, height: 25 }} />
                </span>
                <span className='contact-close' onClick={this.close}>
                  <CloseIcon />
                </span>
                {this.props.translate('sendPictureTitle')}
              </div>
              <form onSubmit={this.submit}>
                <span className='contact-field-title'>
                  {this.props.translate('addAttachment')} *
                </span>
                <div className={this.state.imageValid ? 'contact-add-photo-button send-pic' : 'contact-add-photo-button send-pic contact-field-invalid'} onClick={this.addAttachment}>
                  {
                    (this.state.localImage) ?
                      <>
                        {
                          (this.state.isVideoAttachment) ?
                            <div className='contact-video-attachment'>
                              <AttachFileIcon fontSize="large" />
                            </div> :
                            <div
                              className='contact-photo-preview'
                              style={{ backgroundImage: 'url(' + this.state.localImage + ')' }}>
                            </div>
                        }
                        <div className='remove-photo-button' onClick={this.removePhoto}>
                          <SmallCloseIcon />
                        </div>
                      </> :
                      <div className='camera-area-button'></div>
                  }
                </div>
                {
                  (!this.state.imageValid) ?
                    <span className='contact-field-error small-text title-font direction'>
                      {this.props.translate('addPictureValid')}
                    </span> : null
                }
                <span className='contact-field-title direction'>
                  {this.props.translate('enterYourEmail')}
                </span>
                <input
                  maxLength={150}
                  type="input"
                  className={this.state.emailValid ?
                    "contact-field default-text direction" : "contact-field default-text contact-field-invalid direction"}
                  onChange={this.handleEmailChange}
                  value={this.state.email} />
                {
                  (!this.state.emailValid) ?
                  <span className='contact-field-error small-text title-font direction'>
                    {this.props.translate('enterValidEmail')}
                  </span> : null
                }
  
                {
                  (this.props.themeContext.termsEnabled === "true") ?
                  <div
                    className={(this.state.termsValid) ? "contact-terms-wrapper direction" : "contact-terms-wrapper contact-field-invalid direction"}
                    onClick={this.toggleTerms}>
                    <div className='contact-terms-checkbox'>
                      <input type="checkbox" id="terms" name="terms" onChange={this.toggleTerms} checked={this.state.terms} />
                    </div>
                    <span>
                      {
                        (this.props.termsCustomText && this.props.termsCustomText !== '') ?
                          this.props.termsCustomText : 
                          (this.props.generalContext && this.props.generalContext.language && this.props.generalContext.language === "jpn" ? '' : this.props.translate('termsText'))
                      }
                      <a
                        href={this.props.themeContext.termsUrl} target="_blank" rel="noopener noreferrer"
                        style={{ paddingLeft: 5, paddingRight: 3, color: 'black' }}>{this.props.generalContext && this.props.generalContext.language && this.props.generalContext.language === "jpn" ? this.props.translate('termsTextTermsAndConditions') : this.props.translate('termsAndConditions')}
                      </a>
                    </span> *
                  </div> : null
                }
                <div className='send-button-wrapper'>
                  <button
                    type="submit"
                    className="send-button"
                    style={{
                      backgroundColor: this.props.themeContext.buttonBackgroundColor,
                      color: this.props.themeContext.buttonTextColor
                    }}>
                    <span style={{
                      textTransform: 'uppercase',
                      color: this.props.themeContext.buttonTextColor
                    }}>
                      {
                        (!this.props.sendPicture.inprogress) ?
                          this.props.translate('send') :
                          <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            color='inherit'
                            size={24}
                            thickness={4}
                          />
                      }
                    </span>
                  </button>
                </div>
              </form>
              <Camera
                ref={(camera) => { this.camera = camera }}
                finish={this.finishCapture}
                processing={this.processCapture} />
              <div className='contact-form-send-label'>
                {
                  (this.state.isVideoAttachment && this.props.sendPicture.inprogress) ?
                    'Video attachments may take a few minutes to upload' : null
                }
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sendPicture: state.sendPicture,
    instructions: state.instructions,
    translate: getTranslate(state.locale)
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default withGeneralContext(connect(mapStateToProps, matchDispatchToProps)(withThemeContext(SendPicture)));
