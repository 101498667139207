import React, { Component } from 'react';
import GuideContainer from 'app/components/home/guideContainer/GuideContainer';

class GuidePage extends Component {

  render() {

    return (
      <GuideContainer
        guideId={this.props.match.params.guideId}
        stepId={this.props.match.params.stepId}>
      </GuideContainer>
    );
  }
}

export default GuidePage;
