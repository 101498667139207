import React from 'react';

export const CheckIcon = (props) =>(
    <svg width="10px" height="7px" viewBox="0 0 10 7" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Options_More_Language" transform="translate(-785.000000, -498.000000)" stroke={props.color} strokeWidth="2">
                <g id="Language" transform="translate(659.000000, 477.000000)">
                    <polyline id="icn_selected" points="127 24.2727273 129.823529 27 135 22"></polyline>
                </g>
            </g>
        </g>
    </svg>
);