import React from 'react';

export const TimeIcon = (props) =>(
<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0H23V23H0z"/>
        <g stroke={props.color} strokeOpacity=".4" strokeWidth="2" transform="translate(4 2)">
            <circle cx="7" cy="12" r="7"/>
            <path strokeLinecap="round" d="M7 9L7 12.344 9 14"/>
            <path d="M3.5.5h7M7 3.606V0"/>
        </g>
    </g>
</svg>
);