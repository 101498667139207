import React from 'react';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import config from "configuration";

var bugsnagClient = null;
if(config.errorLoggingKey !== '') {
  bugsnagClient = bugsnag(config.errorLoggingKey);
  bugsnagClient.use(bugsnagReact, React)
}

export default function logerror(error) {
  if(bugsnagClient) {
    bugsnagClient.notify(new Error(error));
  }
}
