import React from 'react';
import { DisplayModeContextConsumer } from './DisplayModeContext';

export function withDisplayModeContext(Component) {
    return function WrapperComponent(props) {
        return (
            <DisplayModeContextConsumer>
                {state => <Component {...props} displayModeContext={state} />}
            </DisplayModeContextConsumer>
        );
    };
}