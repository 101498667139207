import {CLOSE_GALLERY_TIP, ONLY_CLOSE_GALLERY_TIP} from './actionTypes'


export const closeDialogAction = () => {
    return {
        type: CLOSE_GALLERY_TIP
    }
}
export const onlyCloseDialogAction = () => {
    return {
        type: ONLY_CLOSE_GALLERY_TIP
    }
}

export function closeDialog() {
    return closeDialogAction();
}

export function onlyCloseDialog() {
    return onlyCloseDialogAction();
}