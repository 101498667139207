import React, { Component } from 'react';
import 'assets/styles/step.scss';
import TipIcon from './tips/TipIcon';

class StepOverlays extends Component {

  render() {

    return (
        <>
        {
            (this.props.tips) ? 
              this.props.tips.map((item, index) => (
              <TipIcon
                  key={index}
                  stepId={this.props.stepId}
                  tipId={index}
                  item={item}
                  appName={this.props.appName}
                  animate={true}
                  hasCustomResources={this.props.hasCustomResources}
                  openAction={this.props.openAction} />
              )) : null
        }      
        </>
    );
  }
}

export default StepOverlays;