import React, { Component } from 'react';
import { LeftArrowIcon } from 'app/components/svg/LeftArrowIcon';

class PrevGuide extends Component {

    render() {

        return (
            <div
                className='guide-container-prev-guide'
                onClick={this.props.click}
            >
                <LeftArrowIcon color={'#000000'} opacity={1} />
            </div>
        );
    }
}

export default PrevGuide;