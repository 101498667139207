import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigateToUrl } from './actions';
import { getQueryVariable, queryVariableExists } from 'app/utils/utils';
import Guide from 'app/components/main/guide/Guide';

class SingleGuideContainer extends Component {

  state = {
    refUrl: '',
    backEnabled: false
  }

  componentDidMount() {
    this.setState({
      refUrl: getQueryVariable('refurl'),
      backEnabled: queryVariableExists('refurl')
    });
  }

  addPreloader = () => {
    var getRoot = document.getElementById('root');
    getRoot.innerHTML = '<div id="mpreload"><span></span><span></span><span></span><span></span></div>';
  }

  guideNavigateBack = () => {
    if (this.state.refUrl !== '') {
      this.addPreloader();
      this.props.dispatch(navigateToUrl(this.state.refUrl));
    }
  }

  render() {

    return (
          <Guide
            fullMode={true}
            stepid={this.props.stepId}
            goBack={(this.state.refUrl !== '') ? this.guideNavigateBack : null}
            projectid={this.props.guideId} />
    );
  }
}

function mapStateToProps(state) {
  return {}
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, matchDispatchToProps)(SingleGuideContainer);