import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { withThemeContext } from 'app/context/withThemeContext';
import { getTranslate } from 'react-localize-redux';
import { CloseIcon} from 'app/components/svg/CloseIcon'
import { closeFinishForm } from './actions';
import { closeTipAction } from 'app/components/instructions/popups/formtip/actions';
import NothingErrorBoundary from 'app/helpers/errorBoundaries/NothingErrorBoundary';
import { Scrollbar } from 'react-scrollbars-custom';
import 'assets/styles/finish.scss';

const Lottie = React.lazy(() => import('react-lottie'));

class SendFormMessage extends Component {

  componentWillUnmount = () => {
    if (this.lottie) {
      this.lottie.destroy();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.lottie && this.props.sendFormMessage.visible && !prevProps.sendFormMessage.visible) {
      this.lottie.stop();
      this.lottie.play();
    }
  }

  close = () => {
    this.props.dispatch(closeFinishForm());
    this.props.dispatch(closeTipAction());
  }

  render() {

    return (
      <div
        onClick={this.close}
        className={(this.props.sendFormMessage.visible) ? 'finish-wrapper success-submit-form popup-open' : 'finish-wrapper success-submit-form popup-close'}>
        <Scrollbar noScrollX>
          <div className="container-box" onClick={this.close}>
            <div className="container__item">
              <div className="finish-back small-title title-font" onClick={this.close}>
                <span className='gallery-tip-close'>
                  <CloseIcon color="white" opacity="1" />
                </span>
              </div>
              {
                <div className='finish-content'>
                  <div style={{ height: 130, width: 130, marginBottom: -30 }}>
                    {
                      <Suspense fallback={<div />}>
                        {
                          <NothingErrorBoundary>
                            <Lottie
                              ref={(lottie) => { this.lottie = lottie }}
                              options={{
                                loop: false,
                                autoplay: false,
                                path: '/r/geen-well-done.json',
                              }}
                              height={130}
                              width={130}
                            />
                          </NothingErrorBoundary>
                        }
                      </Suspense>
                    }
                  </div>
                  <div className='finish-text default-title title-font direction'>
                    {
                      (this.props.successTitle && this.props.successTitle !== "") ?
                        this.props.successTitle : this.props.translate('thankYouText')
                    }
                  </div>
                  <div className='finish-sub-text default-text'>
                    {
                      (this.props.successText && this.props.successText !== "") ?
                        this.props.successText :
                        <span>
                          {
                            this.props.translate('inTouchText')
                          }
                        </span>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </Scrollbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    sendFormMessage: state.sendFormMessage
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, matchDispatchToProps)(withThemeContext(withGeneralContext(SendFormMessage)));
