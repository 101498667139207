import { POST_STEP_FORM } from './actionTypes';
import { POST_STEP_FORM_URL } from './services';

export const submitFormAction = (url, data) => {
  return {
    type: POST_STEP_FORM,
    payload: { url: url, data: data }
  }
}

export function submitForm(appId, guideId, stepId, language, fields, fieldValues) {

  var values = Object.entries(fieldValues).map(([key, value]) => {
    return {
      type: fields.find(p => p.name === key).type,
      name: key,
      value: value
    };
  });

  var data = {
    applicationId: appId,
    guideId: guideId,
    stepId: stepId,
    language: language,
    fields: values
  };

  return submitFormAction(POST_STEP_FORM_URL, data);
}
