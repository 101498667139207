import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from './actions';
import { getQueryVariable } from 'app/utils/utils';
import 'assets/styles/splitStep.scss';

class SplitStepImageDecision extends Component {

  state = {
    rtl: (getQueryVariable('language') === 'heb') ? true : false
  }

  click = () => {
    console.log(this.props.question + " - " + this.props.answer.text);
    var dispatchAction = navigate(this.props.answer, this.props.fullMode);
    if (dispatchAction) {
      this.props.dispatch(dispatchAction);
    }
  }

  render() {

    return (
      <div className={`split-step-image-decision-wrapper`} >
        <div
          onClick={this.click}
          className={`split-step-image-decision-image ${this.props.answer.text ? 
                                                      (this.state.rtl ? this.props.answer.text.toLowerCase().replace(/\s+/g, "").replace(/[.,\/#?!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s{2,}/g,"") : this.props.answer.text.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").replace(/ /ig, '-'))
                                                      : ''}`}
          style={{
            backgroundImage: 'url(' + this.props.answer.imageUrl + ')',
            backgroundColor: this.props.backgroundColor,
            color: this.props.textColor
          }}
        />
        <div 
        onClick={this.click}
        className={`split-step-image-decision-title direction-only small-bold-title ${this.props.answer.text ? 
                                                      (this.state.rtl ? this.props.answer.text.toLowerCase().replace(/\s+/g, "").replace(/[.,\/#?!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s{2,}/g,"") : this.props.answer.text.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").replace(/ /ig, '-'))
                                                      : ''}`}>{this.props.answer.text}</div>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, matchDispatchToProps)(SplitStepImageDecision);
