export { INIT, UPDATE_STEPS_RANGE, FETCH_PROJECT_DATA_SUCCESS } from 'app/components/main/guide/actionTypes';
export { NAVIGATE_BACK } from 'app/middleware/guideNavigationMiddleware';
export { OPEN_FINISH } from 'app/components/instructions/finish/actionTypes';
export { CLOSE_FINISH_FORM } from 'app/components/general/sendFormMessage/actionTypes';

export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const STEP_UPDATE_DATA = 'STEP_UPDATE_DATA';
export const BEGINNING_SLIDE = 'BEGINNING_SLIDE';
export const END_SLIDE = 'END_SLIDE';
export const SLIDE = 'SLIDE';

export const SLIDES_INTERACTION_ENABLED = 'SLIDES_INTERACTION_ENABLED';
export const SLIDES_INTERACTION_DISABLED = 'SLIDES_INTERACTION_DISABLED';
