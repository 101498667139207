import {
  OPEN_CONTACT_FORM, CLOSE_CONTACT_FORM,
  POST_CONTACT_US_INPROGRESS, POST_CONTACT_US_SUCCESS, 
  POST_CONTACT_US_FAIL, STEP_UPDATE_DATA} from './actionTypes';

export default function(state = { 
    visible: false, inprogress: false, error: false, success: false, stepId: -1 }, action) {

  switch(action.type)
  {
      case OPEN_CONTACT_FORM:
        return { ...state, visible: true, inprogress: false, error: false, success: false }
      case CLOSE_CONTACT_FORM:
        return { ...state, visible: false, inprogress: false, error: false, success: false }
      case POST_CONTACT_US_INPROGRESS:
        return { ...state, inprogress: true, error: false, success: false }
      case POST_CONTACT_US_SUCCESS:
          return { ...state, inprogress: false, visible: false, error: false, success: true }
      case POST_CONTACT_US_FAIL:
        return { ...state, inprogress: false, error: true, success: false }
      case STEP_UPDATE_DATA:
          return { ...state, stepId: action.stepId }
      default:
        return { ...state }
  }
}
