import React, { Component } from 'react';
import SingleGuideContainer from 'app/components/home/guideContainer/SingleGuideContainer';

class SingleGuidePage extends Component {

  render() {

    return (
      <SingleGuideContainer
        guideId={this.props.match.params.guideId}
        stepId={this.props.match.params.stepId}>
      </SingleGuideContainer>
    );
  }
}

export default SingleGuidePage;
