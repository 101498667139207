import React, { Component } from "react";
import { connect } from "react-redux";
import { withThemeContext } from "app/context/withThemeContext";
import { withGeneralContext } from "app/context/withGeneralContext";
import { getGalleryData } from "./actions";
import { getTranslate } from "react-localize-redux";
import GalleryCardItem from "./GalleryCardItem";
import "assets/styles/galleryGrid.scss";
import { LeftArrowIcon } from "app/components/svg/LeftArrowIcon";
import { RightArrowIcon } from "app/components/svg/RightArrowIcon";
import LikeIcon from "app/components/svg/LikeIcon";
import HeartIcon from "app/components/svg/HeartIcon";
import { getGalleryItemsSizeQueryVariable } from "app/utils/utils";
import { EmptyData } from "app/components/svg/EmptyData";

class GalleryGrid extends Component {
  state = {
    sliderIndex: 0,
    animate: false,
    loading: true,
    itemSize: getGalleryItemsSizeQueryVariable(),
  };

  componentDidMount() {
    this.props.dispatch(
      getGalleryData(
        this.props.generalContext.appName,
        this.props.sectionId,
        this.props.guideId
      )
    );
    this.setState({
      loading: this.props.gallery.loading,
    });
  }

  finishedAnimation = () => {
    this.setState({ animate: false });
  };

  render() {
    if (!this.props.isGalleryStep) {
      if (
        this.props.themeContext.galleryEnabled !== "true" ||
        this.props.gallery.items.length === 0
      ) {
        return null;
      }
    }

    if (this.props.isGalleryStep && this.props.gallery.items.length === 0) {
      return (
        <div className="empty_data_container">
        <div className="empty_data_card">
          <EmptyData />
        </div>
      </div>
      );
    }

    return (
      <div className="gallery-widget">
        <>
          <div className="default-title title-font gallery-title">
            {this.props.themeContext.customGalleryTitle !== ""
              ? this.props.themeContext.customGalleryTitle
              : this.props.translate("gallery")}
          </div>
          <div className="grid-container" style={{ gridTemplateColumns: `repeat(auto-fill, minmax(${this.state.itemSize}px, 1fr))`}}>
            {this.props.gallery.items.map((item, index) => (
              <div key={item.imageUrl} className="grid-item" style={{width: `${this.state.itemSize}px`, height: `${this.state.itemSize}px`}}>
                {this.props.themeContext.galleryEmodji === "true" ? (
                  <div className="emodji-icon">
                    <LikeIcon
                      count={item.likes}
                      appname={this.props.generalContext.appName}
                      resourceid={item.resourceId}
                      projectId={item.projectId}
                      hearts={-1}
                    />
                    <HeartIcon
                      count={item.hearts}
                      appname={this.props.generalContext.appName}
                      resourceid={item.resourceId}
                      projectId={item.projectId}
                      likes={-1}
                    />
                  </div>
                ) : null}
                <GalleryCardItem item={item} />
              </div>
            ))}
          </div>
        </>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gallery: state.gallery,
    translate: getTranslate(state.locale),
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withThemeContext(withGeneralContext(GalleryGrid)));
