import {
  CLOSE_MESSAGE, POST_CONTACT_US_FAIL, FETCH_SECTION_DATA_FAIL,
  POST_CONTACT_US_SUCCESS, FETCH_PROJECT_DATA_FAIL, POST_SEND_PICTURE_FAIL, FETCH_APP_DATA_FAIL,
  POST_STEP_FORM_FAIL, POST_UPLOAD_PHOTO_SUCCESS, POST_UPLOAD_PHOTO_FAIL, CLOSE_GALLERY_TIP
} from './actionTypes';

export default function (state = { messageKeyword: '', actualMessage: '', isError: false, long: false }, action) {
  switch (action.type) {
    case CLOSE_MESSAGE:
      return { ...state, messageKeyword: '', actualMessage: '', isError: false, long: false };
    case POST_STEP_FORM_FAIL:
      return { ...state, messageKeyword: 'errorOccured', actualMessage: '', isError: true, long: false };
    case POST_CONTACT_US_FAIL:
      return { ...state, messageKeyword: 'errorOccured', actualMessage: '', isError: true, long: false };
    case POST_CONTACT_US_SUCCESS:
      return { ...state, messageKeyword: 'yourMessageWasSent', actualMessage: '', isError: false, long: false };
    case POST_SEND_PICTURE_FAIL:
      return { ...state, messageKeyword: 'errorOccured', actualMessage: '', isError: true, long: false };
    case CLOSE_GALLERY_TIP:
      return { ...state, messageKeyword: 'yourDetailsWereSent', actualMessage: '', isError: false, long: false };
    case FETCH_PROJECT_DATA_FAIL:
      return { ...state, messageKeyword: '', actualMessage: 'Guide failed to load', isError: true, long: true };
    case FETCH_APP_DATA_FAIL:
      return { ...state, messageKeyword: 'errorOccured', actualMessage: '', isError: true, long: true };
    case FETCH_SECTION_DATA_FAIL:
      return { ...state, messageKeyword: 'errorOccured', actualMessage: '', isError: true, long: true };
    case POST_UPLOAD_PHOTO_FAIL:
      return { ...state, messageKeyword: 'errorOccured', actualMessage: '', isError: true, long: false };
    case POST_UPLOAD_PHOTO_SUCCESS:
      return { ...state, messageKeyword: 'yourPhotoWasSent', actualMessage: '', isError: false, long: false };
    default:
      return state;
  }
}