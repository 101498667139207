import React from 'react';

export const LanguagesIcon = (props) =>(
<svg xmlns="http://www.w3.org/2000/svg" width="55" height="60" viewBox="0 0 55 60">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0H55V60H0z"/>
        <circle cx="28" cy="30" r="9" stroke={props.color} strokeWidth="2"/>
        <path stroke={props.color} strokeWidth="2" d="M27.55 39c2.734 0 4.95-4.03 4.95-9s-2.216-9-4.95-9"/>
        <path stroke={props.color} strokeWidth="2" d="M28.9 39c-2.734 0-4.95-4.03-4.95-9s2.216-9 4.95-9"/>
        <path stroke={props.color} strokeLinecap="square" strokeLinejoin="round" strokeWidth="2" d="M19.9 28.65c3.655-1.2 6.43-1.8 8.325-1.8 1.895 0 4.67.6 8.325 1.8M19.9 31.8c3.655 1.2 6.43 1.8 8.325 1.8 1.895 0 4.67-.6 8.325-1.8"/>
    </g>
</svg>
);