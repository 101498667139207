import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import 'assets/styles/home.scss';
import {queryVariableExists} from 'app/utils/utils';

class Footer extends Component {

    state = {
        currentYear: 2020
    }
    componentDidMount() {
        this.setState({ currentYear: new Date().getFullYear() });
    }

    render() {

        if(this.props.themeContext.hideFooter === "true" || queryVariableExists('portrait_view')) {
            return <div style={{height: 30}}></div>;
        }

        return (
            <div className='footer'>
                <div>
                    {
                        (this.props.companyName && this.props.companyName !== '') ?
                            this.state.currentYear + " " +
                            this.props.companyName + " " +
                            this.props.translate('allRightsReserved') + '.' : null
                    }
                </div>
                <div>
                    <a
                        href={this.props.themeContext.termsUrl} target="_blank" rel="noopener noreferrer"
                        style={{ paddingLeft: 5, color: 'black' }}>{this.props.translate('termsAndConditions')}
                    </a>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

export default withThemeContext(connect(mapStateToProps)(Footer));