import {NAVIGATE_TO_GUIDE_SECTION, NAVIGATE_TO_PROJECT, NAVIGATE_TO_WEBSITE} from './actionTypes';

export const navigateToProjectAction = (data, fullMode) => {
    return {
      type: NAVIGATE_TO_PROJECT,
      data: data,
      fullMode: fullMode
    }
}

export const navigateToSectionAction = (data) => {
    return {
        type: NAVIGATE_TO_GUIDE_SECTION,
        data: data
    }
}

export const navigateToWebsiteAction = (data) => {
    return {
      type: NAVIGATE_TO_WEBSITE,
      data: data
    }
}

export function navigate(answer, fullMode) {

    // In the future ther will be an option to navigate to a specific section inside a project
    // Right now (unfortunatly) we are using plain properties for 3 navgation options
    if(answer.projectId && answer.projectId !== '') {
        return navigateToProjectAction(answer.projectId, fullMode);
    }
    else if(answer.sectionId && answer.sectionId !== '' && answer.sectionId !== -1) {
        return navigateToSectionAction(answer.sectionId);
    }
    else if(answer.url && answer.url !== '') {
        return navigateToWebsiteAction(answer.url);
    }
    return null;
}