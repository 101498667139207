import { 
  FETCH_SECTION_DATA_SUCCESS, FETCH_SECTION_DATA_FAIL, 
  FETCH_SECTION_DATA_IN_PROGRESS, 
} from './actionTypes';

export default function (state = {
    app: {}, 
    guides: [], 
    error: false, 
    loading: true
  }, action) {

  switch (action.type) {
    
    case FETCH_SECTION_DATA_IN_PROGRESS:
      return { ...state, error: false, loading: true };

    case FETCH_SECTION_DATA_SUCCESS:
      return {
        ...state,
        app: action.payload.application,
        guides: action.payload.guides,
        sectionImageUrl: action.payload.sectionImageUrl,
        sectionName: action.payload.sectionName,
        isMultipart: action.payload.isMultipart,
        loading: false,
        error: false
      };

    case FETCH_SECTION_DATA_FAIL:
      return { ...state, error: true, loading: false };

    default:
      return state;
  }
}