import React, { Component } from 'react';
import NavigationItem from 'app/components/home/components/navigationItem/NavigationItem';
import 'assets/styles/navigation.scss';
import { motion, AnimatePresence } from "framer-motion"

class SectionsNavigation extends Component {

    render() {

        return (
            <div className='navigation'>
                <motion.div className='navigation-items'>
                    <AnimatePresence>
                        {
                            (this.props.items && this.props.items.length > 0) ?
                                this.props.items.map(item => (
                                    <NavigationItem
                                        id={item.id}
                                        title={item.title}
                                        imageUrl={item.imageUrl}
                                        itemsNumber={item.itemsNumber}
                                        type={item.type}
                                        data={item.data}
                                        key={item.id + item.imageUrl}
                                        onClick={this.props.onItemClick} />
                                )) : null
                        }
                    </AnimatePresence>
                </motion.div>
            </div>
        );
    }
}


export default SectionsNavigation;