import React from 'react';

export const MenuIcon = (props) =>(
<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <g fill="none" fillRule="evenodd">
        <path fill={props.color} fillOpacity=".4" d="M7 10v3H4v-3h3zm6 0v3h-3v-3h3zm6 0v3h-3v-3h3z"/>
        <path d="M0 0H23V23H0z"/>
    </g>
</svg>

);