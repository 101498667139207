import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withThemeContext } from 'app/context/withThemeContext';
import { closeTipAction } from './actions';
import ProjectAnalyticsHelper from 'app/helpers/projectAnalyticsHelper';
import 'assets/styles/tip.scss';
import Form from "../../step/components/form/Form";

class FormTip extends Component {

    state = {
        opened: false
    }

    componentDidUpdate(prevProps) {

        if (prevProps.formtip.isvisible === false &&
            this.props.formtip.isvisible === true) {
            ProjectAnalyticsHelper.startRecordingActivity(0, this.props.formtip.tipId, 0);
            this.setState({ opened: true });
        }
        if (prevProps.formtip.isvisible === true &&
            this.props.formtip.isvisible === false) {
            this.setState({ opened: false });
        }
    }

    close = () => {
        ProjectAnalyticsHelper.stopRecordingActivity();
        this.props.dispatch(closeTipAction());
    }

    containerClick = (event) => {
        event.stopPropagation();
    }

    render() {
        return (
            <div
                className={(this.state.opened) ? 'text-tip-container popup-open': 'text-tip-container popup-close'}
                onClick={this.close}
            >
                <div
                    style={{
                        backgroundColor: this.props.themeContext.popupBackgroundColor,
                        color: this.props.themeContext.popupTextColor
                    }}
                    onClick={this.containerClick}
                    className={'text-tip-content direction'}>
                    <Form
                        button={this.props.formtip.item && this.props.formtip.item.button}
                        addPadding={true}
                        title={this.props.formtip.item && this.props.formtip.item.title}
                        titleColor={this.props.themeContext.stepTextColor}
                        fields={this.props.formtip.item && this.props.formtip.item.fields} />
                </div>
            </div>
    )
    }
}

function mapStateToProps(state) {
    return {
        formtip: state.formtip
    };
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

export default withThemeContext(connect(mapStateToProps, matchDispatchToProps)(FormTip));
