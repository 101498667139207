import { FETCH_PROJECT_DATA_SUCCESS, UPDATE_STEPS_RANGE } from 'app/components/main/guide/actionTypes';
import { saveToStorage } from 'app/utils/storage';
import { getQuery } from './navigationMiddleware';

export const NAVIGATE_TO_WEBSITE = 'NAVIGATE_TO_WEBSITE';
export const NAVIGATE_TO_PROJECT = 'NAVIGATE_TO_PROJECT';
export const NAVIGATE_TO_NEXT_PROJECT = 'NAVIGATE_TO_NEXT_PROJECT';
export const NAVIGATE_TO_PREVIOUS_PROJECT = 'NAVIGATE_TO_PREVIOUS_PROJECT';
export const NAVIGATE_TO_GUIDE_SECTION = 'NAVIGATE_TO_GUIDE_SECTION';
export const NAVIGATE_BACK = 'NAVIGATE_BACK';

export const guideNavigationMiddleware = store => next => action => {

    let projectStore = store.getState().guide;

    switch(action.type) {

      case FETCH_PROJECT_DATA_SUCCESS:
        action.payload.allSteps = action.payload.lessonSteps;
        let stepIndex = action.payload.allSteps.findIndex(item => 
              item.extraData &&
              item.extraData.extraDataQuestion && 
              item.extraData.extraDataQuestion.isBlocking);
        if(stepIndex >= 0) {
          
          // Use only the steps until the blocking step only
          action.payload.lessonSteps = action.payload.lessonSteps.slice(0, stepIndex + 1);
          action.payload.hideEnd = true;
        }
        break;
      
      case NAVIGATE_TO_WEBSITE:  
        window.open(action.data, "_blank");
        break;
      
      case NAVIGATE_TO_NEXT_PROJECT:
        break;

      case NAVIGATE_TO_PREVIOUS_PROJECT:
        break;
        
      case NAVIGATE_TO_PROJECT:

        // Save state
        let state = store.getState();
        try {
          const serializedState = unescape(encodeURIComponent(JSON.stringify(state)));
          const encodedState = btoa(serializedState);
          saveToStorage('state-' + projectStore.data.projectId, encodedState);
        }
        catch(ex) { console.log(ex) };

        // Navigate to project
        var query = window.location.search.substring(1);
        var guidePath = (action.fullMode) ? '/guide/' : '/guides/guide/';
        let back = "/" + projectStore.applicationId + 
                    guidePath + projectStore.data.projectId + '?store=true&storeid=' + projectStore.data.projectId + '&' + query;
        var navigateUrl = "/" + projectStore.applicationId + guidePath + action.data + 
                                "?refurl=" + encodeURIComponent(back) + '&' + getQuery(query);
        window.location = navigateUrl;
        break;
      
      case NAVIGATE_BACK:
        if(action.splitStepId === -1 && projectStore.backUrl !== '') {
            var url = decodeURIComponent(projectStore.backUrl);
            window.location = url;
          }
        break;

      case NAVIGATE_TO_GUIDE_SECTION:
        let stepStore = store.getState().step;
        if(action.data && action.data !== '') {
          return next(navigateToSection(action.data, stepStore.stepId, projectStore));
        }
        break;

      default:
        break;
    }
    return next(action);
}

function navigateToSection(stepGroupId, navigatedFromStepId, projectStore) {

  // Go through the steps
  for (var index = 0; index < projectStore.data.allSteps.length; index++) { 
    let item = projectStore.data.allSteps[index];

    // Check if we reached the section destination
    if (item.stepGroupId === stepGroupId) {

      // Get new steps that start from the section
      let newSteps = projectStore.data.allSteps.slice(index);
      
      // If we have a blocking question in the section, adjust the end of the steps
      let lastIndex = newSteps.findIndex(item => 
          item.extraData && 
          item.extraData.extraDataQuestion && 
          item.extraData.extraDataQuestion.isBlocking);
      if(lastIndex >= 0) {

        // Use steps without the blocking step if the split step is empty and used for blocking only
        // Otherwise include the blocking step as well
        var blockingStep = newSteps[lastIndex];
        newSteps = newSteps.slice(
          0, lastIndex + ((
            blockingStep.extraData.extraDataQuestion.text === '' && 
            blockingStep.url === '' && lastIndex !== 0) ? 0 : 1));
      }
      
      // Return the action
      return {
        type: UPDATE_STEPS_RANGE,
        steps: newSteps,
        hiddenStepsNumber: index,
        hideEnd: (lastIndex >= 0),
        navigatedFromStepId: navigatedFromStepId
      };
    }
  }
  return;
}


