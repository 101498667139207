import React from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';

class LegacyRoutesMiddleware extends React.Component {

    
    render() {
        const search = this.props.location.search;
        const cid = new URLSearchParams(search).get("cid");
        const lid = new URLSearchParams(search).get("lid");

        return (
        <React.Fragment>
          {(cid === '261e0397-309b-4079-890b-d1b6194feda0') ? 
            <Redirect to={{ pathname:'/plusplus/guides/guide/' + lid }}/>
            : null
          }
        </React.Fragment>
        );
      }
}

export default withRouter(LegacyRoutesMiddleware);
